import {mix, Eventable, Timeable} from '@kids/lib/mixin';

const {Math} = globalThis;
const thunderRangeX = 450;

export default class Cloud extends mix().with(Eventable, Timeable)
{
    #movieClip = null;
    #thundering = false;
    #speed = 0;
    #difficult = false;

    constructor(movieClip, speed, difficult = false)
    {
        super();

        this.#movieClip = movieClip;
        this.#speed = speed;
        this.#difficult = difficult;

        this.setReady();
    }

    get removable()
    {
        return this.#movieClip.x > 700;
    }

    get movieClip()
    {
        return this.#movieClip;
    }

    get ready()
    {
        return !this.#thundering;
    }

    get thundering()
    {
        return this.#thundering;
    }

    get boltOver()
    {
        return !this.#thundering || this.#movieClip.currentLabel === 'grau';
    }

    get inThunderRange()
    {
        const {x} = this.#movieClip;
        return x > -thunderRangeX && x < thunderRangeX;
    }

    set speed(speed)
    {
        this.#speed = speed;
    }

    setReady()
    {
        this.off('bolt');
        this.cancelTimeout('cooldown');
        this.#movieClip.gotoAndStop('grau');
        this.#thundering = false;
    }

    thunder()
    {
        this.off('bolt');
        this.cancelTimeout('cooldown');
        this.#movieClip.gotoAndPlay(this.#difficult ? 'rot' : 'rot_easy');
        this.#thundering = true;
    }

    update()
    {
        this.#movieClip.x += this.#speed;
        if (this.#thundering && this.#movieClip.currentLabel === 'gelb')
        {
            this.fire('bolt');
            this.setTimeout('cooldown', () => this.setReady(), 1500);
        }
    }

    getBoltVolume()
    {
        return 0.6 - ((Math.abs(this.#movieClip.x) / thunderRangeX) * 0.5) + (Math.random() * 0.15);
    }
}
