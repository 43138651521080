import {getRandomInt} from '@kids/lib/task';
import {mix, Eventable} from '@kids/lib/mixin';
import createHitAreaPoints from '../createHitAreaPoints';
import createjs from '..';

const {document, Math} = globalThis;
const {Rectangle} = createjs;

const STATE_IDLE = 0;
const STATE_RUNNING_LEFT = 1;
const STATE_RUNNING_RIGHT = 2;
const STATE_HIT = 3;
const STATE_POSE = 4;

const runningSpeed = 10.5;
const xBoundary = 360;

export default class Luther extends mix().with(Eventable)
{
    #game = null;
    #movieClip = null;
    #state = STATE_IDLE;
    #leftPressed = false;
    #rightPressed = false;
    #mousePressed = false;
    #runningAudio = null;
    #lastMouseX = null;
    #blockStateChange = false;
    #boundDocHandlers = {};
    #hitAreaPoints = null;
    #poseNumber = null;

    constructor(game)
    {
        super();

        this.#game = game;
        this.#movieClip = game.getInstanceByClass('game1.luther');
        this.#movieClip.gotoAndStop('neutral');
        this.#hitAreaPoints = createHitAreaPoints(this.#movieClip.hitArea);

        this._addEventListener();
    }

    get x()
    {
        return this.#movieClip.x;
    }

    get idle()
    {
        return this.#state === STATE_IDLE;
    }

    get running()
    {
        return this.#state === STATE_RUNNING_LEFT || this.#state === STATE_RUNNING_RIGHT;
    }

    get hit()
    {
        return this.#state === STATE_HIT;
    }

    get globalHitArea()
    {
        const [point1, point2] = this.#hitAreaPoints;
        const globalPoint1 = this.#movieClip.localToGlobal(point1.x, point1.y);
        const globalPoint2 = this.#movieClip.localToGlobal(point2.x, point2.y);
        return new Rectangle(
            Math.min(globalPoint1.x, globalPoint2.x),
            globalPoint1.y,
            Math.abs(globalPoint1.x - globalPoint2.x),
            Math.abs(globalPoint1.y - globalPoint2.y)
        );
    }

    _addEventListener()
    {
        this.#boundDocHandlers.keydown = evt => this._keyDownHandler(evt);
        this.#boundDocHandlers.keyup = evt => this._keyUpHandler(evt);

        for (const [event, handler] of Object.entries(this.#boundDocHandlers))
        {
            document.addEventListener(event, handler);
        }

        this.#game.game.addEventListener('mousedown', evt => this._mouseDownHandler(evt));
        this.#game.game.addEventListener('pressmove', evt => this._pressMoveHandler(evt));
        this.#game.game.addEventListener('pressup', evt => this._pressUpHandler(evt));
    }

    _keyDownHandler(evt)
    {
        if ((this.#leftPressed && this.#rightPressed) || this.#mousePressed) { return; }
        if (evt.code === 'ArrowLeft' && !this.#leftPressed)
        {
            this._setState(STATE_RUNNING_LEFT);
            this.#leftPressed = true;
        }
        else if (evt.code === 'ArrowRight' && !this.#rightPressed)
        {
            this._setState(STATE_RUNNING_RIGHT);
            this.#rightPressed = true;
        }
    }

    _keyUpHandler(evt)
    {
        if (evt.code === 'ArrowLeft')
        {
            this.#leftPressed = false;
            if (this.#rightPressed)
            {
                this._setState(STATE_RUNNING_RIGHT);
                return;
            }
        }
        else if (evt.code === 'ArrowRight')
        {
            this.#rightPressed = false;
            if (this.#leftPressed)
            {
                this._setState(STATE_RUNNING_LEFT);
                return;
            }
        }

        if (!this.#leftPressed && !this.#rightPressed)
        {
            this._setState(STATE_IDLE);
        }
    }

    _mouseDownHandler(evt)
    {
        if (this.#leftPressed || this.#rightPressed) { return; }
        this.#mousePressed = true;
        this._evaluateMouseX(evt.localX);
    }

    _pressMoveHandler(evt)
    {
        if (!this.#mousePressed) { return; }
        this._evaluateMouseX(evt.localX);
    }

    _evaluateMouseX(x)
    {
        if (Math.abs(x) > xBoundary)
        {
            x -= x % xBoundary;
        }
        this.#lastMouseX = x;
        if (x === this.x) { return; }
        this._setState(x < this.x ? STATE_RUNNING_LEFT : STATE_RUNNING_RIGHT);
    }

    _pressUpHandler()
    {
        if (!this.#mousePressed) { return; }
        this.#lastMouseX = null;
        this.#mousePressed = false;
        this._setState(STATE_IDLE);
    }

    _setState(state)
    {
        if (this.#blockStateChange || this.#state === state) { return; }

        const movieClip = this.#movieClip;

        switch (state)
        {
            case STATE_RUNNING_LEFT:
            case STATE_RUNNING_RIGHT:
            {
                if (state === STATE_RUNNING_RIGHT && movieClip.scaleX > 0)
                {
                    movieClip.scaleX = -movieClip.scaleX;
                }
                else if (state === STATE_RUNNING_LEFT && movieClip.scaleX < 0)
                {
                    movieClip.scaleX = -movieClip.scaleX;
                }

                if (movieClip.currentLabel !== 'laufen' || movieClip.currentLabel !== 'lauf_loop')
                {
                    movieClip.gotoAndPlay('laufen');
                }

                if (!this.#runningAudio)
                {
                    this.#runningAudio = this.#game.audioController.play(
                        'steps', {loops: -1}
                    );
                }
                break;
            }
            case STATE_POSE:
            {
                this._stopRunningAudio();
                movieClip.gotoAndPlay(`neutral to pose${this.#poseNumber}`);
                break;
            }
            case STATE_HIT:
            {
                this._stopRunningAudio();
                this.#blockStateChange = true;
                movieClip.gotoAndPlay(`Blitz_pose${getRandomInt(1, 3)}`);
                this.#game.audioController.play('bolthit2');
                break;
            }
            case STATE_IDLE:
            default:
                this._stopRunningAudio();
                movieClip.gotoAndPlay('neutral');
                break;
        }

        this.#state = state;
        this.fire('stateChange', {state});
    }

    _stopRunningAudio()
    {
        if (this.#runningAudio)
        {
            this.#runningAudio.unload();
            this.#runningAudio = null;
        }
    }

    _run()
    {
        if (!this.running) { return; }

        const direction = this.#state === STATE_RUNNING_RIGHT ? 1 : -1;
        const newX = this.x + (runningSpeed * direction);

        if (
            Math.abs(newX) > xBoundary ||
            (this.#lastMouseX !== null && Math.abs(this.#lastMouseX - this.x) < runningSpeed)
        )
        {
            this._setState(STATE_IDLE);
        }
        else
        {
            this.#movieClip.x = newX;
        }
    }

    update()
    {
        const movieClip = this.#movieClip;

        if (this.#state === STATE_HIT)
        {
            if (movieClip.currentLabel === 'neutral')
            {
                this._setState(STATE_IDLE);
                this.#blockStateChange = false;
                this.#leftPressed = false;
                this.#rightPressed = false;
                if (this.#lastMouseX)
                {
                    this._evaluateMouseX(this.#lastMouseX);
                }
                this.fire('hitOver');
            }
            return;
        }
        if (this.#state === STATE_POSE)
        {
            if (movieClip.paused)
            {
                movieClip.gotoAndPlay(`pose${this.#poseNumber} to neutral`);
            }
            else if (movieClip.currentLabel === 'neutral')
            {
                this._setState(STATE_IDLE);
            }
            return;
        }
        this._run();
    }

    electrocute()
    {
        this._setState(STATE_HIT);
    }

    pose(number = null)
    {
        if (!number)
        {
            number = getRandomInt(1, 3);
        }
        this.#poseNumber = number;
        this._setState(STATE_POSE);
    }

    destroy(idle = false)
    {
        if (idle)
        {
            this._setState(STATE_IDLE);
        }
        this.removeAllListeners();
        for (const [event, handler] of Object.entries(this.#boundDocHandlers))
        {
            document.removeEventListener(event, handler);
        }
        this.#game.game.removeAllEventListeners();
    }
}
