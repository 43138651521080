import Vue from 'vue';
import AudioPlayer from './AudioPlayer';
import DefaultButton from './DefaultButton';
import ErrorModal from './ErrorModal';
import ExternalLink from './ExternalLink';
import ImageBox from './ImageBox';
import ProgressIcon from './ProgressIcon';
import Modal from './Modal';
import StageWrapper from './StageWrapper';

const components = {
    DefaultButton,
    AudioPlayer,
    ErrorModal,
    ExternalLink,
    ImageBox,
    ProgressIcon,
    Modal,
    StageWrapper
};

Object
    .entries(components)
    .forEach(
        ([name, component]) => Vue.component(name, component)
    );
