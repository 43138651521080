<template>
    <article class="bible-translation">
        <h2>Übersetzung der Bibel</h2>
        <image-box
            image="content/page/fw_info4_b1.png"
            title="Luthers Zimmer auf der Wartburg"
        />
        <p>
            Als Martin gelebt hat, wurde die Bibel im Gottesdienst nur auf Latein vorgelesen.
            Das konnte aber kaum jemand verstehen.
        </p>
        <p>
            Das wollte Martin ändern. Jeder sollte das Wort Gottes lesen und verstehen können.
            Deshalb nahm sich Martin vor, eine verständliche deutsche Übersetzung der Bibel zu
            schreiben. Das war gar nicht so einfach. Das Alte Testament wurde ursprünglich auf
            Hebräisch und das Neue Testament auf Griechisch geschrieben. Ungefähr 1000 Jahre vor
            Luther wurden diese alten Texte ins Lateinische übersetzt. Diese Übersetzung nennt
            man Vulgata. Vieles darin war leider sehr ungenau. Da Luther auch Hebräisch und
            Griechisch studiert hatte, nahm er diese Ur-Texte als Vorlagen für seine deutsche
            Fassung und verglich sie mit der Vulgata. So konnte er weniger Fehler machen.
        </p>
        <p>
            Bildrechte: HAUK MEDIEN ARCHIV / Alexander Hauk / www.bayernnachrichten.de / pixelio.de
        </p>
    </article>
</template>

<script>

export default {
    name: 'BibleTranslation'
};
</script>
