import audioManager from '@/service/audioManager';

let currentGame = null;

const publicGameApi =
{
    set game(game)
    {
        currentGame = game;
    },
    get game()
    {
        return currentGame;
    },
    get audioController()
    {
        return currentGame.audioController;
    },
    clearAudioChannel(channel)
    {
        audioManager.clearChannel(channel);
    },
    playLipSyncAudio(lipSyncerKey, audioId, options = {})
    {
        return currentGame.playLipSyncAudio(lipSyncerKey, audioId, options);
    },
    gotoTutorial()
    {
        currentGame.gotoTutorial();
    },
    gotoGame()
    {
        currentGame.gotoGame();
    },
    gotoOutro()
    {
        currentGame.gotoOutro();
    },
    close()
    {
        currentGame.close();
    },
    fireGameEvent(eventName, event)
    {
        currentGame.fire(eventName, event);
    }
};

globalThis.__gameApi = publicGameApi;

export default publicGameApi;
