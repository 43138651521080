<template>
    <div id="app-wrapper">
        <div
            id="app"
            :class="{active: !startScreenActive}"
            :style="'transform: scale(' + scaleWidth + ', ' + scaleHeight + ');' +
                'transform-origin: 50% 0;'"
        >
            <Parchment />
        </div>
        <transition name="fade">
            <StartScreen v-if="startScreenActive" />
        </transition>
        <a
            v-if="!cordova"
            title="Datenschutz"
            class="data-protection"
            target="_blank"
            href="https://www.kirche-entdecken.de/datenschutz/"
        >
            Datenschutz
        </a>
    </div>
</template>

<script>
import responder from '@kids/lib/service/responder';
import queryBag from '@kids/lib/service/queryBag';
import {createIntegerScale} from '@kids/lib/task';
import Parchment from './components/app/Parchment';
import StartScreen from './components/app/StartScreen';

const size = {width: 1024, height: 768};
const {body} = globalThis.document;

export default {
    name: 'App',
    components: {
        Parchment,
        StartScreen
    },
    data()
    {
        return {
            scaleWidth: 1,
            scaleHeight: 1,
            cordova: !!globalThis.cordova
        };
    },
    computed: {
        startScreenActive()
        {
            return this.$route.name === 'start';
        }
    },
    created()
    {
        responder.on('resize', this.resize);
    },
    destroyed()
    {
        responder.off('resize', this.resize);
    },
    methods: {
        resize()
        {
            if (process.env.NODE_ENV === 'development' &&
                queryBag.has('scale') && !queryBag.getBoolean('scale'))
            {
                return;
            }

            let scale = body.clientWidth / size.width;
            if ((scale * size.height) > body.clientHeight)
            {
                scale = body.clientHeight / size.height;
            }

            this.$root.scale = scale;

            this.scaleWidth = createIntegerScale(size.width, scale, 5);
            this.scaleHeight = createIntegerScale(size.height, scale, 5);
        }
    }
};
</script>

<style lang="scss">
    @import './styles/style.scss';
</style>
