import Kira from '../createjs/Kira';
import Game1 from '../createjs/Game1';
import Game2 from '../createjs/Game2';
import Game3 from '../createjs/Game3';
import Game4 from '../createjs/Game4';
import Game5 from '../createjs/Game5';

export default {
    Kira,
    Game1,
    Game2,
    Game3,
    Game4,
    Game5
};
