<template>
    <div class="imprint">
        <h2>Impressum</h2>
        <ImageBox image="content/page/imprint/logo_adult.png" title="Kirche-entdecken.de" />
        <h2>Herausgeber:</h2>
        <div class="author-tile">
            <ImageBox
                image="content/page/imprint/ELKB_zeichen-mit-schriftzug-rechts-HKS-37-K-4C.png"
                class="big rounded"
                title="Evangelisch-Lutherische Kirche in Bayern"
                no-subtitle
            />
            <p>
                <span>Evangelisch-Lutherische Kirche in Bayern</span>
                <br>
                <span>Miklós Geyer</span>
            </p>
        </div>
        <div class="author-tile">
            <ImageBox
                image="content/page/imprint/Hannover_evlka_WBM-V1_cmyk.png"
                class="big rounded"
                title="Evangelisch-lutherische Landeskirche Hannovers"
                no-subtitle
            />
            <p>
                <span>Evangelisch-lutherische Landeskirche Hannovers</span>
                <br>
                <span>Kay Oppermann</span>
            </p>
        </div>
        <div class="author-tile">
            <ImageBox
                image="content/page/imprint/logo_uni_greifswald.png"
                class="big rounded"
                title="Universität Greifswald"
                no-subtitle
            />
            <p>
                <span>Universität Greifswald</span>
                <br>
                <span>Prof. Dr. Roland Rosenstock</span>
            </p>
        </div>
        <h2>
            Dienstanbieter der Website sind gemäß § 5 TMG:
        </h2>
        <p>
            Der Landeskirchenrat der Evang.-Luth. Kirche in Bayern<br>
            Landeskirchenamt München<br>
            Katharina-von-Bora-Straße 7-13<br>
            80333 München<br>
            Vertreten durch Landesbischof Christian Kopp.<br>
            Die Evangelisch-Lutherische Kirche in Bayern ist eine Körperschaft öffentlichen Rechts.
        </p>
        <p>
            Kontakt:<br>
            Vernetzte Kirche & Internet<br>
            in der ELKB-IT<br>
            Tel.: +49 (0)89 / 5595-717<br>
            Mail: <ExternalLink link="mailto:vernetztekirche@elkb.de">
                vernetztekirche@elkb.de
            </ExternalLink>

            <br><br>und<br><br>

            Evangelische Medienarbeit | EMA<br>
            Pastor Joachim Lau<br>
            Evangelische Medienarbeit, Direktor<br>
            Archivstr. 3<br>
            30169 Hannover
        </p>
        <h2>Inhaltlich Verantwortliche im Sinne des § 18 MStV sind:</h2>
        <p>
            Vernetzte Kirche & Internet<br>
            in der ELKB-IT<br>
            Pfarrer Miklós Geyer<br>
            Katharina-von-Bora-Straße 7-13<br>
            80333 München<br><br>

            <b>Kontakt Redaktion</b><br>
            <ExternalLink link="mailto:nicola.roessert@elkb.de ">
                nicola.roessert@elkb.de
            </ExternalLink>

            <br><br>sowie<br><br>

            Evangelisch-lutherische Landeskirche Hannovers<br>
            Evangelische Medienarbeit (EMA)<br>
            Pastor Joachim Lau<br>
            Archivstraße 3<br>
            30169 Hannover<br>
            <ExternalLink link="leitung.ema@evlka.de">
                leitung.ema@evlka.de
            </ExternalLink><br>
            Tel.: +49 (0)511 1241-111

        </p>
        <h2>Idee, Konzept, Redaktion</h2>
        <p>
            Nicola Rössert, Miklós Geyer, Vernetzte Kirche sowie
            Johannes Keller, ehem. Vernetzte Kirche
        </p>
        <p>
            Uli Geißler - ehemals Amt für Jugendarbeit der Evangelisch-Lutherischen Kirche in Bayern
        </p>
        <p>
            Angelika Wiesel - ehemals Evangelisches MedienServiceZentrum (EMSZ),
            Landeskirche Hannovers
        </p>
        <h2>Konzeption, Gestaltung, Technische Realisierung:</h2>
        <ImageBox
            image="content/page/imprint/logo_KIDS-interactive.png"
            class="big rounded"
            title="KIDS interactive GmbH"
            no-subtitle
        />
        <p>
            KIDS interactive GmbH - Die Agentur für junge Medien<br>
            Kindermedienzentrum<br>
            Erich-Kästner-Str. 1 b<br>
            99094 Erfurt<br>
            Tel.: 0361 / 51 14 37 30<br>
            Internet:
            <ExternalLink target="_blank" link="http://www.kids-interactive.de/">
                www.kids-interactive.de
            </ExternalLink>
            <br>
            E-Mail:
            <ExternalLink link="mailto:info@kids-interactive.de">
                info@kids-interactive.de
            </ExternalLink>
        </p>
        <h2>Weitere Kooperationspartner:</h2>
        <p>
            <b>Prof. Dr. Roland Rosenstock</b><br>
            Ernst-Moritz-Arndt Universität Greifswald, Theologische Fakultät<br>
            Praktische Theologie / Religions- und Medienpädagogik<br>
            Am Rubenowplatz 2-3, 17487 Greifswald<br>
            Tel.: 03834 / 86 25 22 <br>
            Internet:
            <ExternalLink target="_blank" link="http://www.roland-rosenstock.de/">
                www.roland-rosenstock.de
            </ExternalLink><br>
            E-Mail:
            <ExternalLink link="mailto:roland.rosenstock@uni-greifswald.de">
                roland.rosenstock@uni-greifswald.de
            </ExternalLink>
        </p>
        <h2>Urheberrecht</h2>
        <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
            dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede
            Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
            Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser
            Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        </p>
        <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden
            die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
            werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
    </div>
</template>

<script>
export default {
    name: 'Imprint'
};
</script>

<style lang="scss" src="./Imprint.scss" />
