import createHitAreaPoints from '../createHitAreaPoints';
import createjs from '..';

const {Math} = globalThis;
const {Rectangle} = createjs;

export default class Enemy
{
    #movingFactor = 0;
    #speed = 0;
    #movieClip = null;
    #hitAreaPoints = [];
    #triggerDuck = false;
    #triggerDuckFrames = 0;
    #capturingLuther = false;

    constructor(movieClip, speed, startFrame)
    {
        this.#speed = speed;
        this.#movieClip = movieClip;
        this.#hitAreaPoints = createHitAreaPoints(movieClip.hitbox);
        movieClip.gotoAndPlay(startFrame);
    }

    get x()
    {
        return this.#movieClip.x;
    }

    get movieClip()
    {
        return this.#movieClip;
    }

    set movingFactor(factor)
    {
        this.#movingFactor = factor;
    }

    get globalHitArea()
    {
        const [point1, point2] = this.#hitAreaPoints;
        const globalPoint1 = this.#movieClip.localToGlobal(point1.x, point1.y);
        const globalPoint2 = this.#movieClip.localToGlobal(point2.x, point2.y);
        return new Rectangle(
            globalPoint1.x,
            globalPoint1.y,
            globalPoint2.x - globalPoint1.x,
            globalPoint2.y - globalPoint1.y
        );
    }

    update()
    {
        if (this.#movingFactor)
        {
            this.#movieClip.x += this.#movingFactor * this.#speed;
        }
        if (this.#triggerDuckFrames)
        {
            this.#triggerDuckFrames--;
            if (this.#triggerDuckFrames === 0)
            {
                this.#triggerDuck = false;
            }
        }
        if (this.#capturingLuther && this.#movieClip.currentLabel === 'laufen')
        {
            this.#movieClip.gotoAndPlay('Luther_stellen');
        }
    }

    processTriggers(duckArea, triggerArea)
    {
        const hitArea = this.globalHitArea;
        if (duckArea && !this.#triggerDuck && hitArea.intersects(duckArea))
        {
            this.#triggerDuck = true;

            const {parent} = this.#movieClip;
            const widthInParentSystem =
                parent.globalToLocal(duckArea.width + hitArea.width, 0).x -
                parent.globalToLocal(0, 0).x;

            this.#triggerDuckFrames = Math.ceil(widthInParentSystem / this.#speed);
        }

        if (this.#movieClip.currentLabel === 'laufen' && hitArea.intersects(triggerArea))
        {
            this._dodge();
        }
    }

    _dodge()
    {
        this.#movieClip.gotoAndPlay(this.#triggerDuck ? 'ducken' : 'springen');
    }

    captureLuther()
    {
        this.#capturingLuther = true;
    }
}
