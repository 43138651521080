<template>
    <div class="game-hud game2">
        <div class="count">
            {{ count.correct }}
        </div>
        <div class="count">
            {{ count.incorrect }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Game2Hud',
    computed: {
        count()
        {
            return this.$store.getters.getHud('game2');
        }
    }
};
</script>

<style lang="scss" src="./Hud.scss" />
