<template>
    <article class="live-in-monastery">
        <h2>Leben im Kloster</h2>
        <image-box
            image="content/page/fw_info2_b1.png"
            title="Martins Zimmer im Kloster"
        />
        <p>
            Weil Martin im Gewitter gerettet worden war, ging er wie versprochen ins Kloster.
            Dort wartete eine ganz neue Welt auf ihn.
        </p>
        <p>
            Von seinen Freunden begleitet klopfte Martin im Jahr 1505 an die Klosterpforte
            der Augustiner-Mönche in Erfurt. Klöster waren zu dieser Zeit wichtige
            Orte für den Glauben. Hier lebten die Menschen, die ihr Leben Gott gewidmet hatten.
            Die Klöster hatten damals auch große Macht. Nur wenige Menschen konnten lesen.
            Bücher mussten von Hand geschrieben werden und waren sehr teuer und wertvoll.
            Und nur im Kloster konnte man lesen und schreiben lernen.
            Dieses Wissen machte viele Klöster reich.
            Das Leben dort war sehr streng. Es gab getrennte Klöster für Männer und Frauen,
            Mönche und Nonnen. Die Menschen, die im Kloster lebten,
            durften nicht heiraten und auch keine Kinder haben. Außerdem war genau geregelt,
            was man essen und anziehen durfte. Und man musste sehr früh aufstehen.
            Gebet und Arbeit füllten den Tag. Es gab viele Klöster.
            Denn die Mönche und Nonnen wollten alles dafür tun, dass Gott sie liebt.
            Martin hat sich ein besonders strenges Kloster ausgesucht, weil er hoffte,
            Gott damit besonders gerecht zu werden.
        </p>
        <p>
            Bildrechte: Lutherzelle im Augustiner-Kloster in Erfurt. Foto: Martina Nolte.
            <br>
            Lizenz: Creative Commons CC-by-sa-3.0 de
        </p>
    </article>
</template>

<script>

export default {
    name: 'LiveInMonastery'
};
</script>
