const setProgress = (state, payload) =>
{
    const {gameId, progress} = payload;
    state.progress[gameId] = progress;
};

const setDifficult = (state, payload) =>
{
    const {gameId, difficult} = payload;
    state.difficult[gameId] = difficult;
};

const setEndScreen = (state, payload) =>
{
    const {endScreen} = state;
    const {gameId, correctAnswers, progress, initialVisit} = payload;
    if (gameId === null)
    {
        endScreen.gameId = null;
        endScreen.initialVisit = true;
        return;
    }

    endScreen.gameId = gameId || endScreen.gameId;
    endScreen.correctAnswers = correctAnswers || endScreen.correctAnswers;
    endScreen.progress = progress || endScreen.progress;
    if (initialVisit !== undefined)
    {
        endScreen.initialVisit = initialVisit;
    }
};

const checkLightningBoxes = (state, payload) =>
{
    state.huds.game1 = state.huds.game1.map((checked, index) => (index < payload));
};

const setPursue = (state, payload) =>
{
    state.huds.game3.pursued = payload.pursued;
    state.huds.game3.pursuer = payload.pursuer;
};

const setGame2Counts = (state, payload) =>
{
    if (payload.correct !== undefined)
    {
        state.huds.game2.correct = payload.correct;
    }

    if (payload.incorrect !== undefined)
    {
        state.huds.game2.incorrect = payload.incorrect;
    }
};

const setGame4Counts = (state, payload) =>
{
    const {correct, incorrect, time} = payload;
    if (correct !== undefined)
    {
        state.huds.game4.correct = correct;
    }

    if (incorrect !== undefined)
    {
        state.huds.game4.incorrect = incorrect;
    }

    if (time !== undefined)
    {
        state.huds.game4.time = time;
    }
};

const setGame5Counts = (state, payload) =>
{
    const {catched, toCatch} = payload;
    if (catched !== undefined)
    {
        state.huds.game5.catched = catched;
    }

    if (toCatch !== undefined)
    {
        state.huds.game5.toCatch = toCatch;
    }
};

export default {
    setProgress,
    setDifficult,
    setEndScreen,
    checkLightningBoxes,
    setGame2Counts,
    setPursue,
    setGame4Counts,
    setGame5Counts
};
