<template>
    <div class="page frame-size" @load.capture="() => scroller.refresh()">
        <default-button class="frame-button close-frame" @clicked="$router.push({name: 'world'})" />
        <audio-player v-if="audioSource" :audio="audioSource" />
        <component
            :is="page"
            :key="page"
            ref="page"
        />
        <button
            class="scroller up no-tap-highlight"
            @click="scroll(-1)"
        />
        <button
            class="scroller down no-tap-highlight"
            @click="scroll(1)"
        />
    </div>
</template>

<script>
import gsap from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import SimpleScroller from '@kids/lib/class/SimpleScroller';
import gameConfig from '@/static/gameConfig.json';
import {prependAudioUrl} from '@/service/urlUtility';
import components from './pages';

gsap.registerPlugin(ScrollToPlugin);

export default {
    name: 'Page',
    components,
    props: {
        page: {
            type: String,
            required: true
        }
    },
    data()
    {
        return {
            up: false,
            down: false,
            scroller: null
        };
    },
    computed: {
        audioSource()
        {
            if (!this.pageConfig) { return null; }

            const {audio} = this.pageConfig;

            return prependAudioUrl(`content-pages/${audio}.mp3`);
        },
        pageConfig()
        {
            const infoPages = [];
            Object.values(gameConfig).forEach((game) =>
            {
                infoPages.push(...game.infoPages);
            });

            return infoPages.find(page => page.link === this.page);
        }
    },
    watch:
    {
        '$root.scale': function(scale)
        {
            this.scroller.scale = scale;
        }
    },
    mounted()
    {
        this.createSimpleScroller();
    },
    destroyed()
    {
        // Wegen Transition etwas später zerstören
        globalThis.setTimeout(
            () =>
            {
                this.scroller.destroy();
                this.scroller = null;
            },
            500
        );
    },
    methods: {
        createSimpleScroller()
        {
            this.scroller = new SimpleScroller(
                this.$refs.page.$el,
                {fixedBarHeight: true}
            );
            this.scroller.scale = this.$root.scale;
        },
        scroll(direction)
        {
            gsap.to(
                this.$refs.page.$el, {
                    duration: 0.3,
                    scrollTo: {
                        y: (this.$refs.page.$el.scrollTop + (100 * direction))
                    }
                }
            );
        }
    }
};
</script>

<style lang="scss" src="./Page.scss" />
