import frameTimer from '@kids/lib/service/frameTimer';

const {Date, Math} = globalThis;

const frameInMs = Math.round(1000 / 25);
const renderers = [];

let paused = true;
let lastTime;
let timerId;

const start = () =>
{
    if (!paused) { return; }

    lastTime = Date.now();

    timerId = frameTimer.always((time) =>
    {
        const frames = Math.floor((time - lastTime) / frameInMs);
        if (frames > 0)
        {
            const delta = frameInMs * frames;
            lastTime += delta;
            const event = {delta};
            renderers.forEach(renderer => renderer.update(event));
        }
    });

    paused = false;
};

const pause = () =>
{
    if (paused) { return; }
    frameTimer.cancel(timerId);
    paused = true;
};

start();

export default
{
    addRenderer(renderer)
    {
        renderers.push(renderer);
    },
    removeRenderer(renderer)
    {
        const index = renderers.indexOf(renderer);
        if (index !== -1)
        {
            renderers.splice(index, 1);
        }
    },
    start()
    {
        start();
    },
    pause()
    {
        pause();
    }
};
