<template>
    <article class="martin-luther">
        <h2>Martin Luther</h2>
        <image-box
            image="content/page/fw_info1_b1.png"
            title="Martin Luther"
        />
        <p>
            Mit Martin Luther begann die Geschichte unserer evangelischen Kirche.
            Eigentlich wollte der Vater von Martin Luther, dass Martin studiert und Anwalt wird.
            Aber ein Gewitter bei der kleinen Ortschaft Stotternheim in Thüringen veränderte alles.
        </p>
        <p>
            Martin lebte in einer Zeit, die man Mittelalter nennt. Er wurde am 10. November 1483
            geboren und wuchs in einfachen Verhältnissen auf. Sein Vater arbeitete viel und
            sicherte der Familie ein gutes Leben. Martin ging zur Schule und sollte einmal
            studieren können. Das war etwas ganz Besonderes. Kinder mussten damals schon früh
            arbeiten, um ihre Familien zu unterstützen. Aber Martins Vater setzte sich
            sehr dafür ein, dass Martin etwas lernte.
        </p>
        <p>
            Eines Tages wurde Martin von einem Gewitter überrascht und betete zur Heiligen Anna,
            die ihn vor den Blitzen schützen sollte. Wie es dann für Martin weiterging,
            erfährst du, wenn du das Spiel weiterspielst.
        </p>
        <p>
            Bildrechte: Portrait von Lucas Cranach dem Älteren © Wikipedia, gemeinfrei
        </p>
    </article>
</template>

<script>

export default {
    name: 'MartinLuther'
};
</script>
