<template>
    <article class="letterpress-invention">
        <h2>Erfindung des Buchdrucks</h2>
        <image-box
            image="content/page/fw_info5_b2.png"
            title="Druckerpresse"
        />
        <p>
            Seit Johannes Gutenberg den Buchdruck erfunden hatte,
            mussten Bücher nicht mehr von Hand geschrieben werden.
        </p>
        <p>
            Bücher konnten nun viel schneller und billiger hergestellt werden.
            Um eine Bibel abzuschreiben und mit schönen Bildern zu versehen,
            brauchte ein Mönch vorher etwa drei Jahre. Bücher waren vor der genialen Erfindung
            Gutenbergs deshalb sehr teuer und nur wenige Menschen konnten sie sich leisten.
        </p>
        <p>
            Ungefähr ein halbes Jahrhundert bevor Martin seine 95 Thesen schrieb,
            erfand Johannes diesen Buchdruck. Der funktionierte ein bisschen so wie Kartoffeldruck:
            Johannes hat aus Metall einzelne Buchstaben in Spiegelschrift angefertigt und diese wie
            viele kleine Kartoffelstempel zu einem Text zusammengesetzt.
            Die Buchstabenoberfläche hat er mit Farbe bestrichen und dann mit einer
            großen Presse ganz fest auf Papier gedrückt.
        </p>
        <p>
            Zu Martins Zeit gab es schon viele Druckereien, die mit dieser neuen Technik arbeiteten.
            Das war für ihn sehr wichtig. Er musste nicht alles von Hand schreiben und viel mehr
            Menschen konnten nun seine Texte lesen. Martins deutsche Bibelübersetzung wurde daher
            ein riesiger Erfolg oder wie man heutzutage sagt: zum Bestseller.
            Die Bibel wurde zu einem Buch, das von ganz vielen Menschen gekauft und gelesen wurde.
        </p>
        <p>
            Bildrechte: © Wikipedia, gemeinfrei
        </p>
    </article>
</template>

<script>

export default {
    name: 'LetterpressInvention'
};
</script>
