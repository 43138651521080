import {mix, Eventable} from '@kids/lib/mixin';
import {getRandomInt} from '@kids/lib/task';
import createHitAreaPoints from '../createHitAreaPoints';
import createjs from '..';

const {document, Math} = globalThis;
const {Rectangle} = createjs;

const STATE_RUNNING = 1;
const STATE_JUMPING = 2;
const STATE_DUCKING = 3;
const STATE_FALL_BACK = 4;
const STATE_FALL_OVER = 5;

export default class Luther extends mix().with(Eventable)
{
    #moving = false;
    #speed = 0;
    #game = null;
    #movieClip = null;
    #state = 0;
    #spacePressed = false;
    #mousePressed = false;
    #runningAudio = null;
    #triggerDuck = false;
    #triggerDuckFrames = 0;
    #blockStateChange = false;
    #boundDocHandlers = {};
    #hitAreaPoints = null;
    #globalHitArea = null;
    #captured = false;

    constructor(game, speed)
    {
        super();

        this.#speed = speed;
        this.#game = game;
        this.#movieClip = game.getInstanceByClass('game3.Game_3_Luther');
        this.#hitAreaPoints = createHitAreaPoints(this.#movieClip.hitbox);
        this._setState(STATE_RUNNING);

        this._addEventListener();
    }

    get x()
    {
        return this.#movieClip.x;
    }

    get movieClip()
    {
        return this.#movieClip;
    }

    get running()
    {
        return this.#state === STATE_RUNNING;
    }

    get dodging()
    {
        return this.#state === STATE_JUMPING || this.#state === STATE_DUCKING;
    }

    get falling()
    {
        return this.#state === STATE_FALL_BACK || this.#state === STATE_FALL_OVER;
    }

    get triggerDuck()
    {
        return this.#triggerDuck;
    }

    get globalHitArea()
    {
        return this.#globalHitArea || this._createGlobalHitArea();
    }

    get moving()
    {
        return this.#moving;
    }

    set moving(moving)
    {
        this.#moving = moving;
    }

    _createGlobalHitArea()
    {
        const [point1, point2] = this.#hitAreaPoints;
        const globalPoint1 = this.#movieClip.localToGlobal(point1.x, point1.y);
        const globalPoint2 = this.#movieClip.localToGlobal(point2.x, point2.y);
        this.#globalHitArea = new Rectangle(
            globalPoint1.x,
            globalPoint1.y,
            globalPoint2.x - globalPoint1.x,
            globalPoint2.y - globalPoint1.y
        );
        return this.#globalHitArea;
    }

    _addEventListener()
    {
        this.#boundDocHandlers.keydown = evt => this._keyDownHandler(evt);
        this.#boundDocHandlers.keyup = evt => this._keyUpHandler(evt);

        for (const [event, handler] of Object.entries(this.#boundDocHandlers))
        {
            document.addEventListener(event, handler);
        }

        this.#game.game.addEventListener('mousedown', evt => this._mouseDownHandler(evt));
        this.#game.game.addEventListener('pressup', evt => this._pressUpHandler(evt));
    }

    _keyDownHandler(evt)
    {
        if (this.#moving || this.#spacePressed || this.#mousePressed) { return; }
        if (evt.code === 'Space')
        {
            this._dodge();
            this.#spacePressed = true;
        }
    }

    _keyUpHandler(evt)
    {
        if (evt.code === 'Space')
        {
            this.#spacePressed = false;
        }
    }

    _mouseDownHandler()
    {
        if (this.#moving || this.#spacePressed) { return; }
        this._dodge();
        this.#mousePressed = true;
    }

    _pressUpHandler()
    {
        if (!this.#mousePressed) { return; }
        this.#mousePressed = false;
    }

    _dodge()
    {
        this._setState(this.#triggerDuck ? STATE_DUCKING : STATE_JUMPING);
    }

    _setState(state)
    {
        if (this.#blockStateChange || this.#state === state) { return; }

        const movieClip = this.#movieClip;

        switch (state)
        {
            case STATE_JUMPING:
            {
                this._stopRunningAudio();
                movieClip.gotoAndPlay('springen');
                this.#game.audioController.play('springen');
                break;
            }
            case STATE_DUCKING:
            {
                movieClip.gotoAndPlay('ducken');
                break;
            }
            case STATE_FALL_OVER:
            {
                this.#blockStateChange = true;
                this._stopRunningAudio();
                movieClip.gotoAndPlay('springen_unfall');
                this.#game.audioController.play(`hinfallen${getRandomInt(1, 2)}`);
                break;
            }
            case STATE_FALL_BACK:
            {
                this.#blockStateChange = true;
                this._stopRunningAudio();
                movieClip.gotoAndPlay('ducken_unfall');
                this.#game.audioController.play(`hinfallen${getRandomInt(1, 2)}`);
                break;
            }
            case STATE_RUNNING:
            default:
                if (!this.#runningAudio)
                {
                    this.#runningAudio = this.#game.audioController.play(
                        'laufen', {loops: -1, volume: 0.2}
                    );
                }
                movieClip.gotoAndPlay('laufen');
                break;
        }

        this.#state = state;
    }

    _stopRunningAudio()
    {
        if (this.#runningAudio)
        {
            this.#runningAudio.unload();
            this.#runningAudio = null;
        }
    }

    update()
    {
        const movieClip = this.#movieClip;

        if (this.#moving)
        {
            movieClip.x += this.#speed;
            return;
        }

        if (this.#captured)
        {
            if (movieClip.currentLabel === 'laufen')
            {
                movieClip.gotoAndPlay('Luther_wird_gestellt');
                this._stopRunningAudio();
                this.#blockStateChange = true;
                this.fire('speakCaptureAudio');
            }
            return;
        }

        if (this.#state !== STATE_RUNNING)
        {
            if (movieClip.currentLabel === 'laufen')
            {
                const event = {afterFall: this.falling};
                this.#blockStateChange = false;
                this._setState(STATE_RUNNING);
                this.fire('runningAgain', event);
            }
        }

        if (this.#triggerDuckFrames)
        {
            this.#triggerDuckFrames--;
            if (this.#triggerDuckFrames === 0)
            {
                this.#triggerDuck = false;
            }
        }
    }

    collide()
    {
        this._setState(this.#triggerDuck ? STATE_FALL_BACK : STATE_FALL_OVER);
    }

    processDuckTrigger(area)
    {
        if (!area || this.#triggerDuck) { return; }

        if (this.globalHitArea.intersects(area))
        {
            this.#triggerDuck = true;

            const {parent} = this.#movieClip;
            const widthInParentSystem =
                parent.globalToLocal(area.width + this.globalHitArea.width, 0).x -
                parent.globalToLocal(0, 0).x;

            this.#triggerDuckFrames = Math.ceil(widthInParentSystem / this.#speed);
        }
    }

    setDefaultPosition()
    {
        this.#movieClip.x = -50;
    }

    capture()
    {
        this.#captured = true;
        this._removeControlListeners();
    }

    escape()
    {
        this.#movieClip.gotoAndPlay('Luther_flieht_neu');
        this._removeControlListeners();
        this._stopRunningAudio();
    }

    _removeControlListeners()
    {
        for (const [event, handler] of Object.entries(this.#boundDocHandlers))
        {
            document.removeEventListener(event, handler);
        }
        this.#game.game.removeAllEventListeners();
    }

    destroy()
    {
        this.removeAllListeners();
        this._removeControlListeners();
        this._stopRunningAudio();
    }
}
