<template>
    <div id="start-screen">
        <router-link
            ref="clickArea"
            :to="{name: 'world'}"
            class="click-area no-tap-highlight"
            :class="{visible}"
            title="Spiel starten"
        >
            <img
                ref="startScreen"
                class="background"
                src="@/assets/app/start_screen.jpg"
            >
            <img
                ref="startButton"
                class="banner"
                src="@/assets/app/start_button.png"
            >
        </router-link>
        <div
            ref="appBlocked"
            class="app-blocked"
            :style="cordova ? 'display: none;' : ''"
        >
            <DefaultButton class="rotation parchment-button">
                Bitte drehe dein Gerät!
            </DefaultButton>
            <DefaultButton class="no-support parchment-button">
                Dein Gerät wird leider nicht unterstützt.<br>
                Spiele Martin Luthers Abenteuer auf einem Computer oder Tablet.
                Oder mit der kostenlosen App auf deinem Smartphone.
            </DefaultButton>
            <div class="store-buttons">
                <a
                    href="https://play.google.com/store/apps/details?id=de.kids_interactive.lutherspielFramework&hl=de"
                    target="_blank"
                >
                    <img class="store-image" src="@/assets/app/button-playstore.svg">
                </a>
                <a
                    href="https://apps.apple.com/de/app/martin-luthers-abenteuer/id731430011"
                    target="_blank"
                >
                    <img class="store-image" src="@/assets/app/button-appstore.svg">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import AudioController from '@kids/lib/class/AudioController';
import ImageLoader from '@kids/lib/class/ImageLoader';
import gsap from 'gsap';

const {setTimeout, clearTimeout, getComputedStyle} = globalThis;

export default {
    name: 'StartScreen',
    data: () => ({
        visible: false,
        cordova: !!globalThis.cordova
    }),
    mounted()
    {
        if (this.cordova && AudioController.isInitialized())
        {
            this.$router.push({name: 'world'});
            return;
        }
        this._imageLoader = new ImageLoader([this.$refs.startScreen, this.$refs.startButton]);
        this._imageLoader.loaded.then(this.start);
        gsap.set(this.$refs.startButton, {y: '100%', scaleX: 0.6, scaleY: 0.8});
    },
    destroyed()
    {
        setTimeout(() => gsap.killTweensOf(this.$refs.startButton), 1000);
        if (this._redirectTimeout)
        {
            clearTimeout(this._redirectTimeout);
        }
        this.$audioController.removeListenersByChannel('redirect');
    },
    methods: {
        start()
        {
            this.visible = true;
            gsap.to(
                this.$refs.startButton,
                {
                    y: '-16%',
                    scaleX: 1,
                    scaleY: 1,
                    duration: 1.2,
                    ease: 'elastic.out(1, 0.55)',
                    delay: 0.5,
                    onComplete: this.evaluateClickInit
                }
            );
        },
        evaluateClickInit()
        {
            if (!this.$refs.appBlocked) { return; }
            const blocked = getComputedStyle(this.$refs.appBlocked).display !== 'none';
            if (AudioController.isInitialized() && !blocked)
            {
                if (this.$audioController.isLoaded())
                {
                    this._redirectTimeout = setTimeout(
                        () => this.$router.push({name: 'world'}),
                        1000
                    );
                }
                else
                {
                    this.$audioController.on(
                        'loaded',
                        () => this.$router.push({name: 'world'}),
                        {channel: 'redirect'}
                    );
                }
                return;
            }
            gsap.to(this.$refs.startButton, {
                scale: 0.85,
                duration: 1.2,
                yoyo: true,
                ease: 'sine.inOut',
                repeat: -1
            });
        }
    }
};
</script>

<style lang="scss" src="./StartScreen.scss" />
