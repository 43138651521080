const getProgress = state => gameId => state.progress[gameId];
const getDifficult = state => gameId => state.difficult[gameId];
const getHud = state => gameId => state.huds[gameId];
const endScreen = state => state.endScreen;

export default {
    getProgress,
    getDifficult,
    getHud,
    endScreen
};
