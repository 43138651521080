<template>
    <transition name="modal">
        <div v-if="value" class="modal frame-size">
            <default-button
                v-if="hasCloseButton"
                class="frame-button close-frame"
                @clicked="$emit('input', false)"
            />
            <div class="modal-content">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        value: {
            type: Boolean,
            required: true
        },
        hasCloseButton: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss" src="./Modal.scss" />
