import gsap from 'gsap';
import createjs from '..';
import createHitAreaPoints from '../createHitAreaPoints';
import MagicParticle from './MagicParticle';

const {Shape, Rectangle} = createjs;
const defaultColor = '#394963';
const highlightColor = '#CA5BFF';

let Glow = null;

export default class Letter
{
    static setGlowClass(GlowClass)
    {
        Glow = GlowClass;
    }

    #movieClip = null;
    #audioController = null;
    #char = '';
    #uppercase = false;
    #alternate = false;
    #initialPosition = {x: 0, y: 0};
    #startDragPosition = null;
    #dragOffset = {x: 0, y: 0};
    #draggable = false;
    #globalHitArea = null;
    #glowObject = null;
    #uncachedTween = null;

    constructor(movieClip, className, audioController)
    {
        this.#movieClip = movieClip;
        this.#audioController = audioController;

        movieClip.mouseChildren = false;
        movieClip.mouseEnabled = false;

        const matches = className.match(/^Letter_([A-ZÄ])(\d)?/);
        [, this.#char] = matches;
        if (!matches[2])
        {
            this.#uppercase = true;
        }
        else if (matches[2] === '3')
        {
            this.#alternate = true;
        }
        this.cache();

        const {nominalBounds} = movieClip;

        this.#initialPosition.x = movieClip.x;
        this.#initialPosition.y = movieClip.y;
        this.#dragOffset.x = (nominalBounds.width * 0.9) + nominalBounds.x;
        this.#dragOffset.y = (nominalBounds.height * 0.9) + nominalBounds.y;
    }

    get movieClip()
    {
        return this.#movieClip;
    }

    get shape()
    {
        return this.#movieClip.children.find(child => child instanceof Shape);
    }

    get uppercase()
    {
        return this.#uppercase;
    }

    get char()
    {
        return this.#char;
    }

    get alternate()
    {
        return this.#alternate;
    }

    get globalHitArea()
    {
        if (!this.#globalHitArea)
        {
            this.updateGlobalHitArea();
        }
        return this.#globalHitArea;
    }

    get glowObject()
    {
        if (!this.#glowObject)
        {
            const glowObject = new Glow();
            const {movieClip} = this;

            glowObject.alpha = 0;
            glowObject.x = movieClip.x;
            glowObject.y = movieClip.y;

            movieClip.parent.addChildAt(glowObject, 0);
            this.#glowObject = glowObject;
        }
        return this.#glowObject;
    }

    updateGlobalHitArea()
    {
        const hitAreaPoints = createHitAreaPoints(this.#movieClip.hitArea);
        const [point1, point2] = hitAreaPoints;
        const globalPoint1 = this.#movieClip.localToGlobal(point1.x, point1.y);
        const globalPoint2 = this.#movieClip.localToGlobal(point2.x, point2.y);

        this.#globalHitArea = new Rectangle(
            globalPoint1.x,
            globalPoint1.y,
            Math.abs(globalPoint1.x - globalPoint2.x),
            Math.abs(globalPoint1.y - globalPoint2.y)
        );
    }

    cache()
    {
        const bounds = this.#movieClip.nominalBounds;
        this.#movieClip.cache(bounds.x, bounds.y, bounds.width, bounds.height, 1.4);
    }

    fadeIn(duration = 0.5)
    {
        return gsap.to(this.#movieClip, {alpha: 1, duration});
    }

    fadeOut(duration = 0.5)
    {
        return gsap.to(this.#movieClip, {alpha: 0, duration});
    }

    unhighlight(duration = 0.6)
    {
        this._stopCacheOnTweenComplete();
        this.#movieClip.uncache();
        this.#uncachedTween = gsap.to(
            this.shape.graphics._fill,
            {style: defaultColor, duration, onComplete: () => this.cache()}
        );
        return this.#uncachedTween;
    }

    highlight(duration = 0.6)
    {
        this._stopCacheOnTweenComplete();
        this.#movieClip.uncache();
        this.#uncachedTween = gsap.to(
            this.shape.graphics._fill,
            {style: highlightColor, duration, onComplete: () => this.cache(), overwrite: true}
        );
        return this.#uncachedTween;
    }

    sparkle()
    {
        return gsap.to(
            [{}, {}, {}, {}],
            {
                onStart: () => this.#audioController.play('positive', {volume: 0.4}),
                stagger: {
                    each: 0.1,
                    onStart: () => this._emitMagicParticles()
                }
            }
        );
    }

    glow(duration = 1)
    {
        this._stopCacheOnTweenComplete();
        const highlightTween = this.highlight(0.8).eventCallback('onComplete', null);
        const unhighlightTween = this.unhighlight(0.8).eventCallback('onComplete', null);
        const {glowObject} = this;

        this.#uncachedTween = gsap.timeline({onComplete: () => this.cache()})
            .add(highlightTween)
            .to(glowObject, {alpha: 1, duration: 0.6}, 0)
            .add('unglow', `+=${duration}`)
            .add(unhighlightTween, 'unglow')
            .to(glowObject, {alpha: 0, duration: 0.6}, 'unglow');
    }

    _stopCacheOnTweenComplete()
    {
        if (this.#uncachedTween && this.#uncachedTween.isActive())
        {
            this.#uncachedTween.eventCallback('onComplete', null);
        }
    }

    _emitMagicParticles()
    {
        new MagicParticle('type1', this.#movieClip).animate();
        new MagicParticle('type2', this.#movieClip).animate();
    }

    showLatinChar()
    {
        this.#movieClip.gotoAndStop('latein');
        this.#movieClip.updateCache();
    }

    showNormalLatinChar()
    {
        this.#movieClip.gotoAndStop('latein_normal');
        this.#movieClip.updateCache();
    }

    makeDraggable()
    {
        this.#movieClip.mouseEnabled = true;
        this.#draggable = true;
    }

    startDragging(evtX, evtY)
    {
        if (this.#startDragPosition) { return; }
        const movieClip = this.#movieClip;
        const localStartPoint = movieClip.globalToLocal(evtX, evtY);
        const dragOffset = this.#dragOffset;
        const offsetX = localStartPoint.x - dragOffset.x;
        const offsetY = localStartPoint.y - dragOffset.y;

        movieClip.x += offsetX;
        movieClip.y += offsetY;
        this.#startDragPosition = localStartPoint;
    }

    drag(evtX, evtY)
    {
        const startDragPosition = this.#startDragPosition;
        if (!startDragPosition) { return; }

        const movieClip = this.#movieClip;
        const localEvtPoint = movieClip.parent.globalToLocal(evtX, evtY);
        const dragOffset = this.#dragOffset;
        const deltaX = localEvtPoint.x - startDragPosition.x;
        const deltaY = localEvtPoint.y - startDragPosition.y;

        movieClip.x = startDragPosition.x + deltaX - dragOffset.x;
        movieClip.y = startDragPosition.y + deltaY - dragOffset.y;
    }

    endDrag()
    {
        this.#startDragPosition = null;
        return this;
    }

    snapBack(duration = 0.7)
    {
        const movieClip = this.#movieClip;
        movieClip.mouseEnabled = false;
        const onComplete = this.#draggable ? () => this.makeDraggable() : undefined;

        return gsap.to(
            movieClip,
            {...this.#initialPosition, duration, ease: 'sine.out', onComplete}
        );
    }

    equals(letter)
    {
        return this.#char === letter.char && this.#uppercase === letter.uppercase &&
            this.#alternate === this.alternate;
    }
}
