<template>
    <article class="martins-world">
        <h2>Martins Welt</h2>
        <image-box
            image="content/page/fw_info1_b2.png"
            title="Menschen im Mittelalter"
        />
        <p>
            Die Welt Luthers vor 500 Jahren war eine ganz andere als unsere Welt heute.
        </p>
        <p>
            Es gab weder elektrischen Strom noch Telefon oder Autos. Die meisten Menschen konnten
            nicht lesen. Bücher konnten sich nur reiche Leute leisten. Glaube und Kirche spielten
            eine sehr wichtige Rolle. Statt gewählten Politikern gab es Adlige. Kaiser und Papst
            herrschten über Europa. Die meisten Menschen mussten sehr hart arbeiten
            und starben jung.
        </p>
        <p>
            Andere erbten Macht und viel Geld. Trotzdem unterdrückten sie
            die Armen so sehr, dass selbst Kinder schwer arbeiten mussten.
            Ohne Schule und Bildung gab es keinen Ausweg aus der Armut.
        </p>
        <p>
            Bildrechte: Mittelalterliches Städtebild aus dem 15. Jahrhundert © Wikipedia, gemeinfrei
        </p>
    </article>
</template>

<script>

export default {
    name: 'MartinsWorld'
};
</script>
