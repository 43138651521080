import createjs from '..';
import Letter from './Letter';

const {MovieClip} = createjs;

export default class LetterManager
{
    #letterClasses = [];
    #letters = [];
    #audioController = null;

    constructor(game)
    {
        const library = game.getLibrary('game4');
        Letter.setGlowClass(library.LetterHighlightGlow);
        for (const className in library)
        {
            if (className.indexOf('Letter_') === 0)
            {
                this.#letterClasses.push({
                    name: className,
                    class: library[className]
                });
            }
        }
        this.#audioController = game.audioController;
    }

    getClassNameOfLetterMC(displayObject)
    {
        const classes = this.#letterClasses;
        let i = classes.length;
        while (i--)
        {
            if (displayObject instanceof classes[i].class)
            {
                return classes[i].name;
            }
        }
        return null;
    }

    createInstances(displayObject, instances = [])
    {
        const className = this.getClassNameOfLetterMC(displayObject);
        if (className)
        {
            const letter = new Letter(displayObject, className, this.#audioController);
            instances.push(letter);
            this.#letters.push(letter);
        }
        else if (displayObject instanceof MovieClip)
        {
            displayObject.children.forEach(
                child => this.createInstances(child, instances)
            );
        }

        return instances;
    }

    highlight(...movieClips)
    {
        this.getLettersByMovieClips(...movieClips).forEach(letter => letter.highlight());
    }

    sparkle(...movieClips)
    {
        this.getLettersByMovieClips(...movieClips).forEach(letter => letter.sparkle());
    }

    unhighlight(...movieClips)
    {
        this.getLettersByMovieClips(...movieClips).forEach(letter => letter.unhighlight());
    }

    getLetterByMovieClip(movieClip)
    {
        return this.#letters.find(letter => letter.movieClip === movieClip);
    }

    getLettersByMovieClips(...movieClips)
    {
        const letters = [];
        movieClips.forEach((movieClip) =>
        {
            const letter = this.getLetterByMovieClip(movieClip);
            if (letter)
            {
                letters.push(letter);
            }
        });
        return letters;
    }

    reset(removeMovieClips = false)
    {
        if (removeMovieClips)
        {
            this.#letters.forEach(letter => letter.movieClip.remove());
        }
        this.#letters.length = 0;
        return this;
    }

    cloneLetter(letter)
    {
        const {movieClip} = letter;
        const MovieClipClass = this.#letterClasses.find(
            letterClass => movieClip instanceof letterClass.class
        ).class;

        const clonedMovieClip = new MovieClipClass();
        clonedMovieClip.alpha = 0;
        clonedMovieClip.x = movieClip.x;
        clonedMovieClip.y = movieClip.y;

        movieClip.parent.addChildAt(clonedMovieClip, movieClip.parent.getChildIndex(movieClip));
        return this.createInstances(clonedMovieClip)[0];
    }
}
