<template>
    <div class="audio-player">
        <audio
            ref="audio"
            :src="audio"
            preload="metadata"
            @ended="active = false"
        />
        <default-button
            class="frame-button audio-button"
            :class="{active}"
            @clicked="toggle"
        />
    </div>
</template>

<script>
import eventBus from '@/service/eventBus';

export default {
    name: 'AudioPlayer',
    props: {
        audio: {
            required: true,
            type: String
        }
    },
    data()
    {
        return {
            active: false
        };
    },
    created()
    {
        eventBus.on('pause', this.pause);
        eventBus.on('resume', this.play);
    },
    beforeDestroy()
    {
        this.$refs.audio.src = '';
        eventBus.off('pause', this.pause);
        eventBus.off('resume', this.play);
    },
    methods: {
        toggle()
        {
            if (this.active)
            {
                this.stop();
            }
            else
            {
                this.play();
            }
            this.active = !this.active;
        },
        stop()
        {
            const {audio} = this.$refs;
            audio.pause();
            audio.currentTime = 0;
        },
        pause()
        {
            this.$refs.audio.pause();
        },
        play()
        {
            this.$refs.audio.play();
        }
    }
};
</script>
