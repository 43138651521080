<template>
    <div id="parchment-footer" :class="{'no-ornament': hudVisible}">
        <component
            :is="gameHud"
            v-show="hudVisible"
            ref="hud"
        />
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data()
    {
        return {
            gameHud: null,
            hudVisible: false
        };
    }
};
</script>
