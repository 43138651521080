import createHitAreaPoints from '../createHitAreaPoints';
import createjs from '..';

const {Rectangle} = createjs;

export default class Barricade
{
    #movieClip = null;
    #duckTriggerBoxPoints = null;
    #triggerBoxPoints = null;
    #hitAreaPoints = null;
    #upperType = false;

    dodged = false;
    behindLuther = false;

    constructor(movieClip)
    {
        this.#movieClip = movieClip;
        this.#hitAreaPoints = createHitAreaPoints(movieClip.hitbox);
        this.#triggerBoxPoints = createHitAreaPoints(movieClip.triggerbox);
        this.#upperType = movieClip.y < 274;
        if (this.#upperType)
        {
            this.#duckTriggerBoxPoints = createHitAreaPoints(movieClip.duckTriggerbox);
        }
    }

    _createGlobalRectangle(points)
    {
        const [point1, point2] = points;
        const globalPoint1 = this.#movieClip.localToGlobal(point1.x, point1.y);
        const globalPoint2 = this.#movieClip.localToGlobal(point2.x, point2.y);
        return new Rectangle(
            globalPoint1.x,
            globalPoint1.y,
            globalPoint2.x - globalPoint1.x,
            globalPoint2.y - globalPoint1.y
        );
    }

    get relevant()
    {
        const gameX = this.#movieClip.x + this.#movieClip.parent.x;
        return gameX < 200 && gameX > -500;
    }

    get globalDuckTriggerArea()
    {
        if (!this.#upperType) { return null; }
        return this._createGlobalRectangle(this.#duckTriggerBoxPoints);
    }

    get globalTriggerArea()
    {
        return this._createGlobalRectangle(this.#triggerBoxPoints);
    }

    get upperType()
    {
        return this.#upperType;
    }

    hits(globalRectangle)
    {
        return globalRectangle.intersects(
            this._createGlobalRectangle(this.#hitAreaPoints)
        );
    }
}
