<template>
    <article class="spreading-reformation">
        <h2>Ausbreitung der Reformation</h2>
        <image-box
            image="content/page/fw_info5_b4.png"
            title="Die Menschen hören Martin zu"
        />
        <p>
            Martins neue Sicht von einem gnädigen Gott begeisterte immer mehr Menschen.
            Sie verbreiteten die Nachricht vom gnädigen Gott in alle Lande.
        </p>
        <p>
            Viele Entwicklungen zu Martins Zeit waren dafür eine gute Voraussetzung:
            Bevor Martin mit seiner Bibelübersetzung anfing, hatte ein kluger Mann namens
            Erasmus zum ersten Mal alle griechischen Originaltexte der Bibel richtig geordnet
            und zusammengestellt. Johannes Gutenberg hatte die Buchdruckmaschine erfunden.
            Martin sorgte mit der Übersetzung der Bibel ins Deutsche für die massenhafte
            Verbreitung seiner Erkenntnis. Das war ein Anstoß für die Kirche, nachzudenken
            und viele Dinge neu zu regeln. Aber erst durch die vielen Menschen, die diese
            Nachricht vom gnädigen Gott weitergetragen haben, wurde eine große Bewegung daraus:
            Die Reformation. Das lateinische Wort heißt übersetzt so viel wie Erneuerung
            oder Umgestaltung. Diese Ereignisse sind viele hundert Jahre her, hatten aber eine
            große Wirkung. Am 31. Oktober feiern wir zur Erinnerung an diese Zeit
            den Reformationstag. Heute gibt es auf der Welt ungefähr 70 Millionen
            Menschen mit evangelisch-lutherischem Glauben.
        </p>
        <p>
            Bildrechte: Lutherdenkmal in Worms, Foto: Dieter Schütz / pixelio.de
        </p>
    </article>
</template>

<script>

export default {
    name: 'SpreadingReformation'
};
</script>
