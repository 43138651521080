import CompositionRenderer from './CompositionRenderer';

export default class Kira extends CompositionRenderer
{
    constructor()
    {
        super(['kira']);
    }

    _init()
    {
        this._stage.addChild(this._kira);
        this.update();
        super._init();
    }
}
