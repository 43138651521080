<template>
    <article class="the-lutherrose">
        <h2>Die Lutherrose</h2>
        <image-box
            image="content/page/fw_info5_b5.png"
            title="Lutherrose"
        />
        <p>
            Die Lutherrose ist das Erkennungszeichen von Martin Luther.
        </p>
        <p>
            Martin setzte unter seine Briefe ein bestimmtes Zeichen: die Lutherrose.
            Das war sozusagen sein „Logo“, wie man so ein Zeichen heute nennt. So wusste jeder,
            dass der Brief wirklich von Martin war. Weil er sehr viel schrieb,
            kannten bald viele Menschen die Lutherrose als sein Zeichen.
        </p>
        <p>
            Martin hat einmal gesagt, die Lutherrose erinnere ihn an seinen Glauben.
            Das Kreuz in der Mitte steht für Jesus Christus.
            Gottes Wort kann man nur mit dem Herzen verstehen, daran erinnert das Herz.
            Die weiße Rose zeigt den Frieden, den Gott den Menschen gibt.
            Der goldene Ring ganz außen hat keinen Anfang und kein Ende. Er sagt uns,
            dass Gottes Wort auch kein Ende hat und ewig gilt.
            Entdeckt hat Martin das Symbol auf einem bunten Fenster im Augustiner-Kloster in Erfurt,
            das er dort bei den täglichen Stundengebeten vor Augen hatte.
        </p>
        <p>
            Heute ist die Lutherrose auch das Symbol der evangelisch-lutherischen Kirchen.
            Und wenn du auf einem Buch oder an einem Haus eine Lutherrose siehst, weißt du,
            dass Martin etwas damit zu tun hat.
            Bei unserem Spiel kannst du übrigens Lutherrosen sammeln.
        </p>
        <p>
            Bildrechte: © Wikipedia, gemeinfrei
        </p>
    </article>
</template>

<script>

export default {
    name: 'TheLutherrose'
};
</script>
