<template>
    <article class="martin-on-the-wartburg">
        <h2>Martin auf der Wartburg</h2>
        <image-box
            image="content/page/fw_info4_b2.png"
            title="Wartburg"
        />
        <p>
            Für den Mönch Martin war das Leben auf der Burg eine große Umstellung.
        </p>
        <p>
            Im Kloster gab es einen sehr geregelten Tagesablauf. Das Essen war sehr einfach.
            Fleisch gab es nur selten. Martin trug im Kloster eine raue Mönchskutte.
            Wie viele Mönche hatte Martin keinen Bart. Seine Haare waren so geschnitten,
            dass ein Haarkranz entstand – die sogenannte Tonsur.
            <br>
            Auf der Burg war vieles anders. Das Essen war fett und reichlich.
            Viel Fleisch sorgte dafür, dass Martin anfangs oft Bauchweh hatte.
            Um nicht mehr als Mönch erkannt zu werden, ließ er sich wieder die Haare und
            einen Bart wachsen. Seine Kutte tauschte er gegen ein Rittergewand ein.
            Aus dem Mönch Martin wurde für die Zeit auf der Burg der Junker Jörg.
        </p>
        <p>
            Bildrechte: Wartburg, Foto: Domino / pixelio.de
        </p>
    </article>
</template>

<script>

export default {
    name: 'MartinOnTheWartburg'
};
</script>
