<template>
    <modal v-model="visible" class="end-screen">
        <div class="end-screen-element end-screen-result">
            <h3 class="header">
                Herzlichen Glückwunsch!
            </h3>
            <div
                v-if="gameId !== 'game1'"
                class="kira-super"
            />
            <StageWrapper
                v-else
                ref="kira"
                class="kira-game1"
                :render-class="'Kira'"
                @rendered="playGame1Feedback"
            />
            <div class="end-screen-text">
                <span
                    v-for="(text, index) in config.endScreenText"
                    :key="index"
                >
                    {{ insertAnswers(text) }}
                    <br>
                </span>
            </div>
            <progress-icon
                v-for="index in endScreen.progress"
                :key="index"
                :index="index"
                big
            />
            <div class="game-control-buttons">
                <default-button
                    class="parchment-button map"
                    @clicked="leaveEndScreen('world')"
                >
                    <span class="icon world" /> Zur Karte
                </default-button>
                <default-button
                    class="parchment-button repeat"
                    @clicked="leaveEndScreen(gameId)"
                >
                    <span class="icon repeat" /> Wiederholen
                </default-button>
                <default-button
                    v-if="gameId != 'game5'"
                    class="parchment-button next-game"
                    @clicked="leaveEndScreen(nextGameId)"
                >
                    <span class="icon next" /> Nächstes Spiel
                </default-button>
            </div>
        </div>
        <div class="end-screen-element end-screen-information">
            <h4 class="header">
                Möchtest du mehr über Martin Luther wissen?
            </h4>
            <div class="game-information-buttons">
                <default-button
                    v-for="(infoPage, index) in config.infoPages"
                    :key="index"
                    class="parchment-button small"
                    @clicked="showInfoPage(infoPage.link)"
                >
                    {{ infoPage.name }}
                </default-button>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex';
import {getInteger} from '@kids/lib/transform/string';
import gameConfig from '@/static/gameConfig.json';

export default {
    name: 'EndScreen',
    data()
    {
        return {
            visible: true,
            gameConfig
        };
    },
    computed: {
        ...mapGetters([
            'endScreen'
        ]),
        config()
        {
            return gameConfig[this.gameId];
        },
        nextGameId()
        {
            const currentGameId = getInteger(this.gameId);
            return `game${currentGameId + 1}`;
        },
        gameId()
        {
            return this.endScreen.gameId;
        }
    },
    watch:
    {
        visible()
        {
            if (this.visible) { return; }
            this.leaveEndScreen('world');
        }
    },
    created()
    {
        this._answers = [];
    },
    methods:
    {
        insertAnswers(text)
        {
            if (!this._answers.length)
            {
                this._answers.push(...this.endScreen.correctAnswers);
            }

            return text.replace(/_/g, () => this._answers.shift());
        },
        leaveEndScreen(route)
        {
            this.$store.commit('setEndScreen', {gameId: null});
            this.$router.push({name: route});
        },
        showInfoPage(page)
        {
            this.$store.commit('setEndScreen', {initialVisit: false});
            this.$router.push({name: 'info', params: {page}});
        },
        playGame1Feedback()
        {
            if (this.endScreen.initialVisit)
            {
                this.$refs.kira.getRenderer().playLipSyncAudio('kira', 'global.game1_end');
            }
        }
    }
};
</script>

<style lang="scss" src="./EndScreen.scss" />
