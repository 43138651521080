<template>
    <div class="game-hud game3">
        <div class="distance-wrapper">
            <div class="distance">
                <img
                    :style="`left: ${pursue.pursued}%;`"
                    class="pursued"
                    src="@/assets/game-huds/pursued.png"
                >
                <img
                    :style="`left: ${pursue.pursuer}%;`"
                    class="pursuer"
                    src="@/assets/game-huds/pursuer.png"
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Game3Hud',
    computed: {
        pursue()
        {
            return this.$store.getters.getHud('game3');
        }
    }
};
</script>

<style lang="scss" src="./Hud.scss" />
