import gsap from 'gsap';
import {mix, Eventable} from '@kids/lib/mixin';

const {Math} = globalThis;

export default class ThrowingArrow extends mix().with(Eventable)
{
    #gameMovieClip = null;
    #enabled = false;
    #stage = null;
    #movieClip = null;
    #velocityArrow = null;
    #moveArea = null;
    #globalPoint = null;
    #lastMouseX = 0;
    #lastMouseY = 0;
    #rotationRad = 0;
    #rotationBorderTop = -80;
    #rotationBorderBottom = -20;
    #arrowWidthFullVelocity = 300;
    #velocityMax = 1000;
    #velocityMin = 500;

    constructor(game)
    {
        super();

        this.#movieClip = game.createInstance('game5.ThrowingArrow');
        this.#stage = game.stage;
        this.#gameMovieClip = game.game;

        const luther = this.#gameMovieClip.luther_mc;

        luther.gotoAndStop('idle');
        const bible = luther.bible_anim_mc.bible_gfx_mc;
        const position = bible.parent.localToLocal(bible.x, bible.y, this.#gameMovieClip);

        this.#gameMovieClip.addChild(this.#movieClip);
        this.#movieClip.x = position.x;
        this.#movieClip.y = position.y;
        this.#movieClip.alpha = 0;
        this.#globalPoint = this.#gameMovieClip.localToGlobal(position.x, position.y);
        this.#moveArea = this.#movieClip.moveArea_mc;
        this.#velocityArrow = this.#movieClip.velocity_mc;

        this.#gameMovieClip.mouseChildren = false;
        this.#gameMovieClip.addEventListener('mousedown', evt => this._downHandler(evt));
        this.#gameMovieClip.addEventListener('pressup', evt => this._upHandler(evt));

        this.rotation = this.#rotationBorderBottom;
    }

    get enabled()
    {
        return this.#enabled;
    }

    set enabled(enabled)
    {
        this.#enabled = enabled;
    }

    get rotation()
    {
        return this.#movieClip.rotation;
    }

    set rotation(rotation)
    {
        this.#movieClip.rotation = rotation;
        this.#moveArea.rotation = -rotation;
    }

    show()
    {
        gsap.to(this.#movieClip, {duration: 0.4, alpha: 1});
    }

    hide()
    {
        gsap.to(this.#movieClip, {duration: 0.4, alpha: 0});
    }

    update()
    {
        const {mouseX, mouseY} = this.#stage;
        if (mouseX === this.#lastMouseX && mouseY === this.#lastMouseY) { return; }
        this.#lastMouseX = mouseX;
        this.#lastMouseY = mouseY;
        this.rotateArrowByGlobalPoint(mouseX, mouseY);
    }

    _downHandler(evt)
    {
        this.rotateArrowByGlobalPoint(evt.stageX, evt.stageY);
    }

    _upHandler()
    {
        if (!this.#enabled) { return; }

        const velocity = (
            this.#velocityArrow.scaleX * (this.#velocityMax - this.#velocityMin)
        ) + this.#velocityMin;

        this.fire('throw', {velocity, rotationRad: this.#rotationRad});
    }

    rotateArrowByGlobalPoint(x, y)
    {
        if (!this.#enabled) { return; }

        const globalPoint = this.#globalPoint;
        this.#rotationRad = Math.atan2(y - globalPoint.y, x - globalPoint.x);
        let rotation = ((180 / Math.PI) * this.#rotationRad);

        if (rotation < this.#rotationBorderTop)
        {
            rotation = this.#rotationBorderTop;
        }
        else if (rotation > this.#rotationBorderBottom)
        {
            rotation = this.#rotationBorderBottom;
        }

        this.rotation = rotation;
        this.#rotationRad = (Math.PI / 180) * rotation;

        let distanceToArrowRoot = Math.sqrt(
            ((x - globalPoint.x) ** 2) + ((y - globalPoint.y) ** 2)
        );

        if (distanceToArrowRoot > this.#arrowWidthFullVelocity)
        {
            distanceToArrowRoot = this.#arrowWidthFullVelocity;
        }

        this.#velocityArrow.scaleX = distanceToArrowRoot / this.#arrowWidthFullVelocity;
    }

    centerObject(object)
    {
        object.x = this.#movieClip.x;
        object.y = this.#movieClip.y;
        return object;
    }

    destroy()
    {
        this.#gameMovieClip.removeAllEventListeners('mousedown');
        this.#gameMovieClip.removeAllEventListeners('pressup');
    }
}
