<template>
    <div class="stage-wrapper" :class="{visible}" />
</template>

<script>
import renderers from '../../../static/compositionRenderers';

const {setTimeout} = globalThis;

export default {
    name: 'StageWrapper',
    props: {
        renderClass: {
            type: String,
            required: true
        },
        hidden: {
            type: Boolean,
            default: false
        }
    },
    data()
    {
        return {
            rendered: false
        };
    },
    computed:
    {
        visible()
        {
            return this.rendered && !this.hidden;
        }
    },
    created()
    {
        const RendererClass = renderers[this.renderClass];

        this._renderer = new RendererClass();
        this._renderer.vueParent = this.$parent;
        this._renderer.once('initialized', () =>
        {
            this._renderer.canvas.classList.add('render-canvas');
            this.$el.appendChild(this._renderer.canvas);
            this.rendered = true;
        });
    },
    mounted()
    {
        if (this.rendered)
        {
            this.$emit('rendered');
        }
        this._renderer.once('initialized', () => this.$emit('rendered'));
    },
    destroyed()
    {
        // Wegen der Papertransition
        setTimeout(() => this._renderer.destroy(), 500);
    },
    methods: {
        getRenderer()
        {
            return this._renderer;
        }
    }
};
</script>

<style lang="scss" src="./StageWrapper.scss" />
