import gameConfig from '@/static/gameConfig.json';

import Imprint from './Imprint.vue';
import MartinLuther from './Game1/MartinLuther.vue';
import MartinsWorld from './Game1/MartinsWorld.vue';
import FearOfGod from './Game1/FearOfGod.vue';
import LiveInMonastery from './Game2/LiveInMonastery.vue';
import BehaveWithGod from './Game2/BehaveWithGod.vue';
import Theses from './Game3/Theses.vue';
import FriedrichTheWiseOne from './Game3/FriedrichTheWiseOne.vue';
import MartinsConviction from './Game3/MartinsConviction.vue';
import MartinOnTheWartburg from './Game4/MartinOnTheWartburg.vue';
import BibleTranslation from './Game4/BibleTranslation.vue';
import GermanLanguage from './Game4/GermanLanguage.vue';
import MartinsFriendPhilipp from './Game4/MartinsFriendPhilipp.vue';
import LetterpressInvention from './Game5/LetterpressInvention.vue';
import SpreadingReformation from './Game5/SpreadingReformation.vue';
import TheLutherrose from './Game5/TheLutherrose.vue';

const components = {
    MartinLuther,
    MartinsWorld,
    FearOfGod,
    LiveInMonastery,
    BehaveWithGod,
    Theses,
    FriedrichTheWiseOne,
    MartinsConviction,
    MartinOnTheWartburg,
    BibleTranslation,
    GermanLanguage,
    MartinsFriendPhilipp,
    LetterpressInvention,
    SpreadingReformation,
    TheLutherrose
};

const config = Object.values(gameConfig);
const pages = {};

config.forEach((configEntry) =>
{
    configEntry.infoPages.forEach((configPage) =>
    {
        const {component, link} = configPage;
        pages[link] = components[component];
    });
});

const staticPages = {
    impressum: Imprint
};

Object.assign(pages, staticPages);
export default pages;
