export default (displayObject) =>
{
    const {nominalBounds, scaleX, scaleY} = displayObject;

    const xPosition = displayObject.x - displayObject.regX;
    const yPosition = displayObject.y - displayObject.regY;

    const point1 = {
        x: (nominalBounds.x * scaleX) + xPosition,
        y: (nominalBounds.y * scaleY) + yPosition
    };
    const point2 = {
        x: ((nominalBounds.x + nominalBounds.width) * scaleX) + xPosition,
        y: ((nominalBounds.y + nominalBounds.height) * scaleY) + yPosition
    };

    return [point1, point2];
};
