<template>
    <button class="button no-tap-highlight" @click="click">
        <span class="label">&nbsp;<slot /></span>
    </button>
</template>

<script>
import audioManager from '../../../service/audioManager';

export default {
    name: 'DefaultButton',
    methods: {
        click()
        {
            audioManager.getController('global').play('button');
            this.$emit('clicked');
        }
    }
};
</script>

<style lang="scss" src="./DefaultButton.scss" />
