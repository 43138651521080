import Vue from 'vue';
import gsap from 'gsap';
import responder from '@kids/lib/service/responder';
import App from './App.vue';
import router from './router';
import './components/global';
import audioManager from './service/audioManager';
import ticker from './createjs/ticker';
import publicGameApi from './service/publicGameApi';
import eventBus from './service/eventBus';

Vue.config.productionTip = false;

Vue.prototype.$audioController = audioManager.loadNamespace('global');

const init = () =>
{
    if (globalThis.StatusBar)
    {
        globalThis.StatusBar.hide();
    }

    const store = require('./store').default;

    new Vue({
        el: '#app-wrapper',
        router,
        store,
        data()
        {
            return {
                scale: 1,
                introPlayed: false
            };
        },
        render: h => h(App)
    });

    if (globalThis.cordova)
    {
        const {body} = globalThis.document;
        body.classList.add('application');
        body.parentElement.classList.add('application');
        Vue.nextTick(() => responder.triggerResize());
    }
};

const pause = () =>
{
    ticker.pause();
    audioManager.pauseAll();
    gsap.globalTimeline.pause();
    const {game} = publicGameApi;
    if (game)
    {
        game.pauseGame();
    }
    eventBus.fire('pause');
};

const resume = () =>
{
    ticker.start();
    audioManager.resumeAll();
    gsap.globalTimeline.play();
    const {game} = publicGameApi;
    if (game)
    {
        game.resumeGame();
    }
    eventBus.fire('resume');
};

if (globalThis.cordova)
{
    document.addEventListener('deviceready', init, false);
    document.addEventListener('pause', pause, false);
    document.addEventListener('resume', resume, false);
}
else
{
    require('./service/tracker');
    init();
}
