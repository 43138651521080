<template>
    <article class="friedrich-the-wise-one">
        <h2>Friedrich der Weise</h2>
        <image-box
            image="content/page/fw_info3_b2.png"
            title="Friedrich der Weise"
        />
        <p>
            Als Martin sich nicht von seiner Überzeugung abbringen ließ, wollte man ihn bestrafen.
        </p>
        <p>
            Gut, dass er auch Freunde und Verbündete hatte. Ein solcher Freund war sein
            Fürst Friedrich der Weise. Friedrich faszinierte, was er von Martin über Gott erfuhr.
            Ein gnädiger Gott, dem wir im Glauben ganz nah sind, war in den Bibelworten plötzlich
            an vielen Stellen sichtbar. Man musste diese neue Idee nur verstehen und die Welt war
            eine andere, eine schönere. Doch die Kirche war noch nicht bereit für Martins Ideen.
            Andere vor ihm hatten sich ähnliche Gedanken gemacht. Und auch sie waren
            verfolgt worden. Martin aber war nicht alleine. Im Gegenteil: Immer mehr Menschen
            erkannten, dass er vollkommen Recht hatte und unterstützten ihn. Immer mehr Menschen
            wollten eine Veränderung. Als es für Martin wieder einmal richtig gefährlich wurde,
            ließ Friedrich der Weise ihn heimlich entführen und versteckte
            ihn vor seinen Verfolgern auf der Wartburg.
        </p>
        <p>
            Bildrechte: Portrait von Lucas Cranach dem Älteren © Wikipedia, gemeinfrei
        </p>
    </article>
</template>

<script>

export default {
    name: 'FriedrichTheWiseOne'
};
</script>
