import eventBus from './eventBus';
import createjs from '../createjs';

const {document, clearTimeout, setTimeout} = globalThis;
const initialTouchSupport = createjs.Touch.isSupported();

let mouseOnTouch = false;
let pressed = false;
let timeout = null;

const hasTouchSupport = () => (initialTouchSupport && !mouseOnTouch);

if (initialTouchSupport)
{
    document.body.addEventListener('touchstart', () =>
    {
        pressed = true;
        if (timeout !== null)
        {
            clearTimeout(timeout);
        }
    });
    document.body.addEventListener('mousemove', () =>
    {
        if (!pressed && !mouseOnTouch)
        {
            mouseOnTouch = true;
            eventBus.fire('changeTouchSupport', {support: hasTouchSupport()});
        }
    });
    document.body.addEventListener('touchend', () =>
    {
        timeout = setTimeout(() =>
        {
            pressed = false;
            timeout = null;
            if (mouseOnTouch)
            {
                mouseOnTouch = false;
                eventBus.fire('changeTouchSupport', {support: hasTouchSupport()});
            }
        }, 400);
    });
}

export default hasTouchSupport;
