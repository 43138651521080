import queryBag from '@kids/lib/service/queryBag';
import {getRandomInt} from '@kids/lib/task';
import {upperCaseFirst} from '@kids/lib/transform/string';
import CompositionRenderer from './CompositionRenderer';
import audioManager from '../service/audioManager';
import publicGameApi from '../service/publicGameApi';

export default class Game extends CompositionRenderer
{
    #disposableTweens = [];
    #lastPlayedAudios = {};

    _stageScale = 2;
    _id = '';
    _intro = null;
    _tutorial = null;
    _game = null;
    _outro = null;
    _started = false;
    _ended = false;
    _paused = false;

    constructor(gameId)
    {
        super([gameId, 'kira'], gameId);
        this._id = gameId;
    }

    set _skipVisible(visible)
    {
        this._vueParent.skipVisible = visible;
    }

    get game()
    {
        return this._game;
    }

    get difficult()
    {
        return this.store.getters.getDifficult(this._id);
    }

    get store()
    {
        return this._vueParent.$store;
    }

    _init()
    {
        publicGameApi.game = this;
        if (process.env.NODE_ENV === 'development' && queryBag.has('skipTo'))
        {
            const skipTo = queryBag.get('skipTo');
            if (skipTo === 'tutorial')
            {
                this.gotoTutorial();
            }
            else if (skipTo === 'game')
            {
                this.gotoGame();
            }
            else if (skipTo === 'outro')
            {
                this.gotoOutro();
            }
        }
        else
        {
            this._createIntro();
            this._intro.gotoAndPlay(1);
        }

        this.canvas.focus();
        super._init();
    }

    _addDisposableTween(...tweens)
    {
        this.#disposableTweens.push(...tweens);
    }

    _disposeTweens()
    {
        this.#disposableTweens.forEach(tween => tween.kill());
        this.#disposableTweens.length = 0;
    }

    _createIntro()
    {
        this._intro = this.center(this.scale(this.createInstance(`${this._id}.intro`)));
        this._stage.addChild(this._intro);
        this._skipVisible = true;
    }

    gotoTutorial()
    {
        this._skipVisible = false;

        this.showRoseTransition({
            onCovered: () => this._createTutorial(),
            beforeFadeAway: () => this._playTutorial()
        });
    }

    _createTutorial()
    {
        this._stopSection('intro');
        this._tutorial = this.center(this.scale(this.createInstance(`${this._id}.tutorial`)));
        this._stage.addChild(this._tutorial);
        this._vueParent.showHud();
    }

    _playTutorial()
    {
        this._tutorial.play();
        this._skipVisible = true;
    }

    gotoGame()
    {
        this._skipVisible = false;

        this.showRoseTransition({
            onCovered: () => this._createGame(),
            onComplete: () => this._startGame()
        });
    }

    _createGame()
    {
        this._stopSection('tutorial');
        this._game = this.center(this.scale(this.createInstance(`${this._id}.game`)));
        this._stage.addChild(this._game);
        this._vueParent.showHud();
    }

    _startGame()
    {
        this._started = true;
    }

    restartGame()
    {
        this._ended = false;
        this.#lastPlayedAudios = {};
        this.showRoseTransition({
            onCovered: () =>
            {
                this._stopSection('game');
                this._createGame();
            },
            onComplete: () => this._startGame()
        });
    }

    pauseGame()
    {
        if (!this._started || this._ended || this._paused) { return false; }
        this._paused = true;
        this.pauseTimers();
        return true;
    }

    resumeGame()
    {
        if (!this._started || this._ended || !this._paused) { return false; }
        this._paused = false;
        this.resumeTimers();
        return true;
    }

    _getRandomAudioKey(keyPrefix, lastIndex, firstIndex = 1)
    {
        const lastPlayedAudios = this.#lastPlayedAudios;
        if (!lastPlayedAudios.hasOwnProperty(keyPrefix))
        {
            lastPlayedAudios[keyPrefix] = firstIndex - 1;
        }

        let randomIndex;
        do
        {
            randomIndex = getRandomInt(firstIndex, lastIndex);
        }
        while (lastPlayedAudios[keyPrefix] === randomIndex);

        lastPlayedAudios[keyPrefix] = randomIndex;

        return `${keyPrefix}${randomIndex}`;
    }

    _mutateStore(mutation, payload)
    {
        this.store.commit(mutation, payload);
    }

    gotoOutro()
    {
        this.showRoseTransition({
            onCovered: () => this._createOutro(),
            beforeFadeAway: () => this._playOutro()
        });
    }

    _createOutro()
    {
        this._stopSection('game');
        this._outro = this.center(this.scale(this.createInstance(`${this._id}.outro`)));
        this._stage.addChild(this._outro);
    }

    _playOutro()
    {
        this._outro.gotoAndPlay(1);
        this._skipVisible = true;
    }

    _stopSection(name)
    {
        const attribute = `_${name}`;
        if (this[attribute])
        {
            this._kira.remove();
            this._disposeTweens();
            if (name === 'game')
            {
                this._started = false;
            }
            const stopMethod = `_stop${upperCaseFirst(name)}`;
            if (this[stopMethod])
            {
                this[stopMethod]();
            }
            this[attribute].remove();
            this[attribute] = null;
            audioManager.clearChannel('kira');
            audioManager.clearChannel('luther');
        }
    }

    showHud()
    {
        this._vueParent.showHud();
    }

    showRoseTransition(evt)
    {
        this._vueParent.showRoseTransition(evt);
    }

    close()
    {
        this._vueParent.close();
    }

    destroy()
    {
        this._stopSection('intro');
        this._stopSection('tutorial');
        this._stopSection('game');
        this._stopSection('outro');

        this.cancelTimers();
        this._disposeTweens();

        this._loaderCollection.getLoaders()
            .filter(loader => loader.id !== 'kira')
            .forEach((loader) =>
            {
                if (loader.constructor.name === 'AudioController')
                {
                    audioManager.unloadNamespace(this._id);
                    return;
                }
                loader.unload();
            });

        super.destroy();
    }

    skip()
    {
        if (this._intro)
        {
            this.gotoTutorial();
        }
        else if (this._tutorial)
        {
            this.gotoGame();
        }
        else if (this._outro)
        {
            this.close();
        }
        else if (this._game)
        {
            this.gotoOutro();
        }
    }

    setProgress(progress)
    {
        if (progress > this.store.getters.getProgress(this._id))
        {
            this.store.commit('setProgress', {gameId: this._id, progress});
        }
        this.store.commit('setEndScreen', {progress});
    }

    setEndScreen(correctAnswers)
    {
        const payload = {gameId: null};
        if (correctAnswers !== null)
        {
            payload.correctAnswers = correctAnswers;
            payload.gameId = this._id;
        }
        this.store.commit('setEndScreen', payload);
    }
}
