import PersistableKeyValuePair from './PersistableKeyValuePair';

const {resolveLocalFileSystemURL, cordova} = globalThis;

const logError = error => console.log(error);

export default class FileStorage
{
    #namespace = '';
    #directory = null;
    #values = {};

    constructor(namespace = 'default')
    {
        this.#namespace = namespace;
        this._mountDirectory();
    }

    _mountDirectory()
    {
        resolveLocalFileSystemURL(cordova.file.dataDirectory, (root) =>
        {
            root.getDirectory('file-storage', {create: true}, (storageRoot) =>
            {
                storageRoot.getDirectory(
                    this.#namespace,
                    {create: true},
                    directory => this._prepareDirectory(directory),
                    logError
                );
            }, logError);
        }, logError);
    }

    _prepareDirectory(directory)
    {
        this.#directory = directory;
        for (const key in this.#values)
        {
            this.#values[key].setDirectory(directory);
        }
    }

    _getOrCreateKeyValuePair(key)
    {
        if (!this.#values.hasOwnProperty(key))
        {
            const keyValuePair = new PersistableKeyValuePair(key);
            if (this.#directory)
            {
                keyValuePair.setDirectory(this.#directory);
            }
            this.#values[key] = keyValuePair;
        }
        return this.#values[key];
    }

    get(key)
    {
        return this._getOrCreateKeyValuePair(key).get();
    }

    set(key, value)
    {
        this._getOrCreateKeyValuePair(key).set(value);
        return this;
    }
}
