import {Eventable, mix} from '@kids/lib/mixin';
import gsap from 'gsap';

const highlightTypes = {
    default: 0,
    correct: 1,
    incorrect: 2
};

export default class Item extends mix().with(Eventable)
{
    #movieClip = null;
    #frame = 0;
    #highlighter = null;
    #highlighTween = null;

    constructor(movieClip, frame)
    {
        super();

        this.#frame = frame;
        this.#movieClip = movieClip;

        movieClip.alpha = 0;
        movieClip.gotoAndStop(frame);

        movieClip.mouseChildren = false;
        [,, movieClip.hitArea] = movieClip.children;
        movieClip.removeChild(movieClip.hitArea);
        movieClip.addEventListener('click', () => this.fire('click', {item: this}));

        this.#highlighter = movieClip.highlighter;
        this.#highlighter.set({alpha: 0, scaleX: 0.5, scaleY: 0.5});

        this.enabled = false;
    }

    set enabled(enabled)
    {
        this.#movieClip.mouseEnabled = enabled;
    }

    get audioKey()
    {
        return `item_${this.#frame + 1}`;
    }

    fadeIn(duration = 0.5)
    {
        return gsap.to(this.#movieClip, {duration, alpha: 1});
    }

    fadeOut(duration = 0.5)
    {
        return gsap.to(this.#movieClip, {duration, alpha: 0});
    }

    highlight(type = 'default', onComplete)
    {
        if (this.#highlighTween && this.#highlighTween.isActive())
        {
            this.#highlighTween.progress(1);
        }

        const highlighter = this.#highlighter;
        highlighter.gotoAndStop(highlightTypes[type]);

        const [flash] = highlighter.children;
        flash.gotoAndPlay(0);

        this.#highlighTween = gsap.timeline()
            .to(highlighter, {
                alpha: 1,
                scaleX: 1,
                scaleY: 1,
                duration: 0.3
            })
            .to(highlighter, {
                alpha: 0,
                scaleX: 0.5,
                scaleY: 0.5,
                duration: 0.3
            }, 0.5);

        if (typeof onComplete === 'function')
        {
            this.#highlighTween.add(onComplete, 1);
        }
    }

    destroy()
    {
        if (this.#highlighTween)
        {
            this.#highlighTween.kill();
        }
    }
}
