import createHitAreaPoints from '../createHitAreaPoints';
import createjs from '..';

const {Rectangle} = createjs;

export default class Bolt
{
    #movieClip = null;
    #removed = false;
    #globalHitArea = false;
    #volume = 1;

    constructor(movieClip, volume)
    {
        this.#movieClip = movieClip;
        this.#volume = volume;

        const hitAreaPoints = createHitAreaPoints(this.#movieClip.hitArea);
        const [point1, point2] = hitAreaPoints;
        const globalPoint1 = this.#movieClip.localToGlobal(point1.x, point1.y);
        const globalPoint2 = this.#movieClip.localToGlobal(point2.x, point2.y);

        this.#globalHitArea = new Rectangle(
            globalPoint1.x,
            globalPoint1.y,
            Math.abs(globalPoint1.x - globalPoint2.x),
            Math.abs(globalPoint1.y - globalPoint2.y)
        );
    }

    get removed()
    {
        return this.#removed;
    }

    get volume()
    {
        return this.#volume;
    }

    update()
    {
        if (!this.#removed && this.#movieClip.totalFrames - 1 === this.#movieClip.currentFrame)
        {
            this.remove();
        }
    }

    hits(globalRectangle)
    {
        return this.#globalHitArea.intersects(globalRectangle);
    }

    remove()
    {
        this.#movieClip.remove();
        this.#removed = true;
    }
}
