<template>
    <a
        v-if="!cordova"
        :href="link"
        :target="target"
    >
        <slot />
    </a>
    <span v-else>
        <slot />
    </span>
</template>

<script>

export default {
    name: 'ExternalLink',
    props: {
        link: {
            type: String,
            required: true
        },
        target: {
            type: String,
            default: '_self'
        }
    },
    data()
    {
        return {
            cordova: !!globalThis.cordova
        };
    }
};
</script>
