import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import getters from './getters';
import state from './state';
import storageManager from './storageManager';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    state,
    mutations,
    getters,
    plugins: [globalThis.cordova ? require('./fileStorageManager').default : storageManager]
});
