<template>
    <div
        class="progress-icon"
        :class="[big ? 'big' : 'small', getClassName, active ? 'active': '']"
    />
</template>

<script>
export default {
    name: 'ProgressIcon',
    props: {
        index: {
            type: Number,
            required: true
        },
        big: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        getClassName()
        {
            return `progress-${this.index}`;
        }
    }
};
</script>

<style lang="scss" src="./ProgressIcon.scss" />
