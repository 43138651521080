<template>
    <modal
        v-model="vModel"
        :has-close-button="false"
        class="error-modal"
    >
        <h3 class="header">
            Das kannst du besser!
        </h3>
        <div class="kira" />
        <div class="error-text">
            <slot />
        </div>
        <div class="error-content-buttons">
            <default-button
                class="parchment-button content-link"
                @clicked="$router.push({name: 'world'})"
            >
                <span class="icon world" /> zurück zur Karte
            </default-button>
            <default-button
                class="parchment-button content-link"
                @clicked="$emit('tryAgain')"
            >
                <span class="icon repeat" /> Nochmal versuchen
            </default-button>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'ErrorModal',
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        vModel: {
            get()
            {
                return this.value;
            },
            set(newValue)
            {
                this.$emit('input', newValue);
            }
        }
    }
};
</script>

<style lang="scss" src="./ErrorModal.scss" />
