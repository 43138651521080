import {getRandomInt} from '@kids/lib/task';
import audioCore from '@kids/lib/service/audioCore';
import Game from '../Game';
import Luther from './Luther';
import Enemy from './Enemy';
import createjs from '..';
import Barricade from './Barricade';

const {Math} = globalThis;
const speed = 10;

export default class Game3 extends Game
{
    #enemies = [];
    #luther = null;
    #background = null;
    #foreground = null;
    #wagon = null;
    #currentSpeed = 0;
    #barricades = [];
    #collisions = 0;
    #distanceToRun = 0;
    #distanceFromEnemies = 0;
    #successState = 0;
    #nextEnemyToComment = 1;

    constructor()
    {
        super('game3');
    }

    _init()
    {
        super._init();
        this._mutateStore('setPursue', {pursued: 0, pursuer: -10});
    }

    _createIntro()
    {
        super._createIntro();
        this._intro.addChild(
            this._kira.set({scaleX: 0.4, scaleY: 0.4, x: 115, y: 30})
        );
        this.playLipSyncAudio('kira', 'intro', {delay: 0.5})
            .on(
                'end',
                () => this.setTimeout('tutorial', () => this.gotoTutorial(), 600)
            );
    }

    _createTutorial()
    {
        super._createTutorial();
        if (this.touchSupport)
        {
            this._tutorial.desktopInput.visible = false;
        }
        else
        {
            this._tutorial.mobileInput.visible = false;
        }
        this._tutorial.gotoAndPlay('start');
    }

    _playTutorial()
    {
        this.audioController.play(
            `tutorial_${this.touchSupport ? 'mobile' : 'desktop'}`,
            {channel: 'kira'}
        );
        this._skipVisible = true;
    }

    _createGame()
    {
        super._createGame();
        const game = this._game;
        this.audioController.playAtmo('music', {volume: 0.3});
        this.#luther = new Luther(this, speed);
        this.#luther.moving = true;
        this.#enemies.push(
            new Enemy(game.enemy_1, speed, 5),
            new Enemy(game.enemy_2, speed, 10)
        );
        this._setEnemiesMovingFactor(1);

        if (this.difficult)
        {
            game.barricades_easy.visible = false;
            this.#background = game.barricades_hard;
        }
        else
        {
            game.barricades_hard.visible = false;
            this.#background = game.barricades_easy;
        }

        this.#foreground = game.foreground;
        this.#wagon = game.wagen;
        this.#barricades.push(
            ...this.#background.children
                .filter(mc => mc instanceof createjs.MovieClip)
                .map(mc => new Barricade(mc))
        );

        this.#distanceToRun = this.#wagon.x - this.#luther.x;
        this.#distanceFromEnemies = this.#luther.x - this.#enemies[1].x;
    }

    _startGame()
    {
        super._startGame();
        this._playComment(false);
        this.setInterval('enemyComment', () => this._playEnemyComment(), 3000);
    }

    _update()
    {
        if (this._game)
        {
            if (this.#luther.moving && this.#luther.x >= -50)
            {
                this.#luther.moving = false;
                this.#luther.setDefaultPosition();
                this.#currentSpeed = speed;
            }

            this.#luther.update();
            this.#enemies.forEach(enemy => enemy.update());

            if (this.#currentSpeed)
            {
                const background = this.#background;
                const foreground = this.#foreground;

                background.x -= this.#currentSpeed;
                this.#wagon.x -= this.#currentSpeed;

                foreground.x -= 17;
                if (foreground.x < -(foreground.nominalBounds.width + 390))
                {
                    foreground.x = 390;
                }
                this._setEnemiesMovingFactor(
                    (this.#luther.running && this.#enemies[1].x > -500) ? -0.1 : 0
                );
            }
            else if (this.#successState > 0)
            {
                this._animateSuccess();
                return;
            }
            this._processBarricades();
            this._updateHud();
            this._testForEnd();
        }
    }

    _setEnemiesMovingFactor(factor)
    {
        this.#enemies.forEach((enemy) =>
        {
            enemy.movingFactor = factor;
        });
    }

    _playComment(afterFall = true)
    {
        if (audioCore.isChannelPlaying('luther')) { return; }

        if (afterFall && (this.#luther.x - this.#enemies[1].x <= 250 || Math.random() < 0.1))
        {
            this.audioController.play('luther_fall_3', {channel: 'luther'});
            return;
        }

        this.audioController.play(
            this._getRandomAudioKey('luther_fb_neg_', 2),
            {channel: 'luther'}
        );
    }

    _playEnemyComment()
    {
        if (Math.random() < 0.1 || audioCore.isChannelPlaying('enemy')) { return; }

        this.audioController.play(
            `${this._getRandomAudioKey('verfolger_', 3, 0)}_${this.#nextEnemyToComment}`,
            {channel: 'enemy'}
        );

        this.#nextEnemyToComment = Math.random() < 0.4 && this.#nextEnemyToComment === 2 ? 1 : 2;
    }

    _playPositiveFeedback(upperBarricade)
    {
        if (audioCore.isChannelPlaying('luther')) { return; }

        this.audioController.play(
            this._getRandomAudioKey('luther_fb_pos_', 3, upperBarricade ? 2 : 1),
            {channel: 'luther'}
        );
    }

    _playNegativeFeedback()
    {
        if (audioCore.isChannelPlaying('luther')) { return; }

        this.audioController.play(
            this._getRandomAudioKey('luther_fall_', 2),
            {channel: 'luther'}
        );
    }

    _processBarricades()
    {
        if (this._ended) { return; }
        let i = this.#barricades.length;
        while (i--)
        {
            this._processBarricade(this.#barricades[i], i);
        }
    }

    _processBarricade(barricade, i)
    {
        const {relevant, dodged, behindLuther} = barricade;

        if (!relevant && behindLuther)
        {
            this.#barricades.splice(i, 1);
            return;
        }

        if (!relevant) { return; }

        const duckTriggerArea = barricade.globalDuckTriggerArea;
        const triggerArea = barricade.globalTriggerArea;

        if (!behindLuther)
        {
            const luther = this.#luther;
            luther.processDuckTrigger(duckTriggerArea);
            if (barricade.hits(luther.globalHitArea))
            {
                barricade.dodged = true;

                if (!luther.running) { return; }

                barricade.dodged = false;
                barricade.behindLuther = true;

                this.#collisions++;
                this._callAfter(
                    () =>
                    {
                        this.#currentSpeed = 0;
                        this._setEnemiesMovingFactor(1);
                    },
                    luther.triggerDuck ? 13 : 25
                );
                this._setEnemiesMovingFactor(0);
                luther.collide();
                luther.once('runningAgain', (evt) =>
                {
                    this.#currentSpeed = speed;
                    if (evt.afterFall)
                    {
                        this._playComment();
                    }
                });
                this._playNegativeFeedback();
            }
            else if (dodged)
            {
                barricade.behindLuther = true;
                if (Math.random() < 0.4)
                {
                    this._playPositiveFeedback(barricade.upperType);
                }
            }
            return;
        }

        this.#enemies.forEach(enemy => enemy.processTriggers(duckTriggerArea, triggerArea));
    }

    _updateHud()
    {
        const pursued = (100 / this.#distanceToRun) *
            (this.#distanceToRun - (this.#wagon.x - this.#luther.x));
        const enemiesDistancePercentage = (100 / this.#distanceFromEnemies) *
            (this.#distanceFromEnemies - (this.#luther.x - this.#enemies[1].x));
        const pursuer = pursued - (-enemiesDistancePercentage / 8) - 12;

        this._mutateStore('setPursue', {pursued, pursuer});
    }

    _testForEnd()
    {
        if (this._ended) { return; }
        if (this.#wagon.x - this.#luther.x <= 310)
        {
            this.cancelInterval('enemyComment');
            this._ended = true;
            this.#successState = 1;
            this.#luther.escape();
            this.#currentSpeed = 0;
            this._setEnemiesMovingFactor(1);
            this.audioController.getAtmo('music').fade(0.3, 0, 7);

            if (this.#collisions > 2)
            {
                this.setProgress(1);
            }
            else if (this.#collisions > 0)
            {
                this.setProgress(2);
            }
            else
            {
                this.setProgress(3);
            }

            this.setEndScreen([this.#collisions]);
        }
        else if (this.#enemies[1].x >= -200)
        {
            this.cancelInterval('enemyComment');
            this._ended = true;
            this.#enemies.forEach(enemy => enemy.captureLuther());
            this.#luther.capture();
            this._setEnemiesMovingFactor(0);
            this.#luther.once('speakCaptureAudio', () => this._speakCaptureAudio());
            this.audioController.play('axtziehen');
            if (this.#luther.running)
            {
                this.#currentSpeed = 0;
            }
        }
    }

    _speakCaptureAudio()
    {
        this.audioController.play(
            `luther_gefangen${getRandomInt(1, 2)}`,
            {eventable: true, channel: 'luther'}
        )
            .once('end', () =>
            {
                this.audioController.stopAtmo('music');
                this._vueParent.errorModal = true;
            });
    }

    _animateSuccess()
    {
        const [, enemy2] = this.#enemies;
        const lutherLabel = this.#luther.movieClip.currentLabel;
        const enemy2Label = enemy2.movieClip.currentLabel;
        const wagonLabel = this.#wagon.currentLabel;
        const {audioController} = this;

        if (enemy2.x >= -200 && enemy2Label !== 'Luther_stellen' &&
            lutherLabel !== 'Luther_im_Wagen')
        {
            this._setEnemiesMovingFactor(0);
            this.#enemies.forEach(enemy => enemy.movieClip.gotoAndPlay('Luther_stellen'));
            audioController.play('verfolger_1_1');
            audioController.play('axtziehen').on(
                'end',
                () => { this.#successState = 2; }
            );
        }
        else if (lutherLabel === 'Luther_ergibt_sich' && this.#successState === 2)
        {
            this.#luther.movieClip.play();
            this.audioController.play('hinfallen2');
        }

        if (enemy2Label !== 'hinter_Kutsche_herschimpfen' && wagonLabel === 'stand' &&
            lutherLabel === 'Luther_im_Wagen')
        {
            audioController.play('luther_end_1', {channel: 'luther'});

            this.#wagon.gotoAndPlay('start');
            this.#enemies.forEach(
                enemy => enemy.movieClip.gotoAndPlay('hinter_Kutsche_herschimpfen')
            );

            audioController.play('pferde', {loops: -1}).fade(0, 20);
        }

        if (enemy2.x >= -200 && enemy2.x < -160 &&
            enemy2Label === 'laufen' && lutherLabel === 'Luther_im_Wagen')
        {
            this._setEnemiesMovingFactor(1);
        }

        if (lutherLabel === 'Luther_im_Wagen' && wagonLabel === 'wagen_weg' && enemy2.x > 100)
        {
            this.gotoOutro();
        }
    }

    restartGame()
    {
        this._mutateStore('setPursue', {pursued: 0, pursuer: -10});
        this.#luther = null;
        this.#background = null;
        this.#foreground = null;
        this.#wagon = null;
        this.#currentSpeed = 0;
        this.#collisions = 0;
        this.#successState = 0;
        this.#nextEnemyToComment = 1;
        super.restartGame();
    }

    _stopGame()
    {
        if (this.#luther)
        {
            this.#luther.destroy();
            this.#luther = null;
        }
        this.#enemies.length = 0;
        this.#barricades.length = 0;
        audioCore.clearChannel('enemy');
    }

    _createOutro()
    {
        super._createOutro();
        this.audioController.stopAtmo('music');
        this.audioController.playAtmo('atmo');
        this._outro.addChild(
            this._kira.set({scaleX: -0.36, scaleY: 0.36, x: -258, y: -5})
        );
    }
}
