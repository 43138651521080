import Vue from 'vue';
import VueRouter from 'vue-router';
import World from './views/World.vue';
import Content from './views/Content.vue';
import Game1 from './views/Game1.vue';
import Game2 from './views/Game2.vue';
import Game3 from './views/Game3.vue';
import Game4 from './views/Game4.vue';
import Game5 from './views/Game5.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'start',
        component: World
    },
    {
        path: '/welt',
        name: 'world',
        component: World
    },
    {
        path: '/auswertung',
        name: 'endscreen',
        component: World
    },
    {
        path: '/spiel-1',
        name: 'game1',
        component: Game1
    },
    {
        path: '/spiel-2',
        name: 'game2',
        component: Game2
    },
    {
        path: '/spiel-3',
        name: 'game3',
        component: Game3
    },
    {
        path: '/spiel-4',
        name: 'game4',
        component: Game4
    },
    {
        path: '/spiel-5',
        name: 'game5',
        component: Game5
    },
    {
        path: '/impressum',
        name: 'imprint',
        component: Content,
        props: {
            page: 'impressum'
        }
    },
    {
        path: '/info/:page',
        name: 'info',
        props: true,
        component: Content
    }
];

const router = new VueRouter({routes});

export default router;
