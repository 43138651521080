<template>
    <div class="game-hud game4">
        <div class="count correct">
            {{ count.correct }}
        </div>
        <div class="count incorrect">
            {{ count.incorrect }}
        </div>
        <div class="count minutes">
            {{ time.minutes }}
        </div>
        <div class="time separator">
            :
        </div>
        <div class="count seconds">
            {{ time.seconds | padSeconds }}
        </div>
    </div>
</template>

<script>
import {toTimeUnits} from '@kids/lib/transform/number';

export default {
    name: 'Game4Hud',
    filters: {
        padSeconds(value)
        {
            return (value < 10 ? '0' : '') + value;
        }
    },
    computed: {
        count()
        {
            return this.$store.getters.getHud('game4');
        },
        time()
        {
            return toTimeUnits(this.count.time);
        }
    }
};
</script>

<style lang="scss" src="./Hud.scss" />
