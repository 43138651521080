<template>
    <article class="martins-friend-philipp">
        <h2>Martins Freund Philipp</h2>
        <image-box
            image="content/page/fw_info4_b7.png"
            title="Philipp Melanchthon"
        />
        <p>
            Luther hatte viele Weggefährten. Einer davon war Philipp Melanchthon.
        </p>
        <p>
            Philipp und Martin waren Kollegen an der Universität Wittenberg und wurden bald
            gute Freunde. Philipp unterrichtete Griechisch und konnte Martin viel
            darüber beibringen. Dafür lernte Philipp von Martin etwas über den Glauben.
            Philipp unterstützte Martin bei der Bibelübersetzung.
            Wenn dieser bei seiner schweren Aufgabe nicht mehr weiter wusste, half Philipp ihm.
            So wie es sich für einen guten Freund gehört.
            Philipp fasste als erster die Ideen seines Freundes Martin
            zusammen und schrieb einen sehr wichtigen Text der Reformation:
            die Confessio Augustana, das sogenannte Augsburger Bekenntnis.
            Wenn evangelische Pfarrer mit diesem in ihr Amt eingeführt werden,
            versprechen sie auch heutzutage noch, dass sie sich an dieses Bekenntnis halten werden.
        </p>
        <p>
            Bildrechte: Portrait von Lucas Cranach dem Älteren © Wikipedia, gemeinfrei
        </p>
    </article>
</template>

<script>

export default {
    name: 'MartinsFriendPhilipp'
};
</script>
