
import {createArrayFromRange} from '@kids/lib/task';
import {shuffle, unique} from '@kids/lib/transform/array';
import audioManager from '@/service/audioManager';
import gsap from 'gsap';
import Game from '../Game';
import LetterManager from './LetterManager';
import MagicParticle from './MagicParticle';

const {Math} = globalThis;
const sentenceIndexes = createArrayFromRange(1, 18);
const alphabet = [
    'A', 'B', 'D', 'E', 'F', 'G', 'I', 'K', 'L', 'M',
    'N', 'O', 'P', 'R', 'S', 'T', 'V', 'Y', 'Z', 'Ä'
];

export default class Game4 extends Game
{
    #letterManager = null;
    #time = 0;
    #msTime = 0;
    #unusedSentences = [];
    #sentenceCount = 0;
    #sentenceIndex = null;
    #sentenceLetters = [];
    #latinLetters = [];
    #eventsBlocked = true;
    #draggedLetter = null;
    #selectedLetter = null;
    #lastQuestion = 0;
    #correctCount = 0;
    #incorrectCount = 0;

    constructor()
    {
        super('game4');
    }

    _init()
    {
        super._init();

        this._mutateStore('setGame4Counts', {correct: 0, incorrect: 0, time: 0});

        const gameLib = this.getLibrary('game4');

        this.#letterManager = new LetterManager(this);

        MagicParticle.setTypeClass('type1', gameLib.MagicHighlightGFX2);
        MagicParticle.setTypeClass('type2', gameLib.MagicHighlightGFX3);

        this.on('highlightLetters', evt => this.#letterManager.highlight(...evt.objects));
        this.on('unhighlightLetters', evt => this.#letterManager.unhighlight(...evt.objects));
        this.on('sparkleLetters', evt => this.#letterManager.sparkle(...evt.objects));
    }

    _createIntro()
    {
        super._createIntro();
        this._intro.addChild(
            this._kira.set({scaleX: 0.6, scaleY: 0.6, x: 190, y: -50})
        );
        this.audioController.playAtmo('scribble', {volume: 0.4});
        this.playLipSyncAudio('kira', 'intro', {delay: 0.8})
            .once(
                'end',
                () => this.setTimeout('tutorial', () => this.gotoTutorial(), 600)
            );
    }

    _stopIntro()
    {
        this.audioController.stopAtmo('scribble');
    }

    _createTutorial()
    {
        super._createTutorial();
        this._tutorial.gotoAndStop('start');
        if (this.touchSupport)
        {
            this._tutorial.desktopInput.visible = false;
        }
        else
        {
            this._tutorial.mobileInput.visible = false;
        }
        this.#letterManager.createInstances(this._tutorial);
        this.audioController.playAtmo('music', {volume: 0.2});
    }

    _playTutorial()
    {
        super._playTutorial();
        this.audioController.play(
            `tutorial_${this.touchSupport ? 'mobile' : 'pc'}`,
            {channel: 'kira'}
        ).on('end', () => this.gotoGame());
    }

    _createGame()
    {
        super._createGame();

        this.#letterManager.reset();
        this.#unusedSentences.push(...sentenceIndexes);
        this._addEventHandler();
        this._createSentence();

        if (!this.audioController.getAtmo('music'))
        {
            this.audioController.playAtmo('music', {volume: 0.2});
        }
    }

    _startGame()
    {
        super._startGame();
        this._playSentenceIntro();
    }

    _playSentenceIntro()
    {
        this._playQuestion();
        this._glowSentenceLetters();
        gsap.delayedCall(2.3, () =>
        {
            this.#latinLetters.forEach(letter => letter.glow());
            this.#eventsBlocked = false;
            this._setHelpInterval();
        });
    }

    _glowSentenceLetters()
    {
        this.#sentenceLetters.forEach(letter => letter.glow(1.2));
    }

    _highlightSentenceLetters()
    {
        this.#sentenceLetters.forEach(letter => letter.highlight());
    }

    _unhighlightSentenceLetters(duration)
    {
        this.#sentenceLetters.forEach(letter => letter.unhighlight(duration));
    }

    _addEventHandler()
    {
        this._game.addEventListener('mousedown', evt => this._downHandler(evt));
        this._game.addEventListener('pressmove', evt => this._moveHandler(evt));
        this._game.addEventListener('pressup', evt => this._upHandler(evt));
    }

    _downHandler(evt)
    {
        if (this.#eventsBlocked || this.#draggedLetter) { return; }
        const letter = this.#letterManager.getLetterByMovieClip(evt.target);
        if (letter)
        {
            letter.startDragging(evt.stageX, evt.stageY);
            this.#draggedLetter = letter;
            this.cancelInterval('help');
            if (!this.difficult)
            {
                this._highlightSentenceLetters();
            }
        }
    }

    _moveHandler(evt)
    {
        if (this.#eventsBlocked || !this.#draggedLetter) { return; }
        this.#draggedLetter.drag(evt.stageX, evt.stageY);
    }

    _upHandler()
    {
        if (this.#eventsBlocked || !this.#draggedLetter) { return; }

        const draggedLetter = this.#draggedLetter;
        const selectedLetter = this.#selectedLetter;

        draggedLetter.endDrag();

        this.#draggedLetter = null;
        this.#selectedLetter = null;

        if (selectedLetter && selectedLetter.equals(draggedLetter))
        {
            this.#correctCount++;
            this._playPositiveFeedback();
            draggedLetter.movieClip.mouseEnabled = false;
            this._mutateStore('setGame4Counts', {correct: this.#correctCount});
            this._unhighlightSentenceLetters(1.2);
            this._resolveCorrectLetter(selectedLetter, draggedLetter);
            return;
        }

        if (selectedLetter)
        {
            this.#incorrectCount++;
            if (!this.difficult)
            {
                draggedLetter.unhighlight();
            }
            this.audioController.play('negative');
            this._playNegativeFeedback();
            this._mutateStore('setGame4Counts', {incorrect: this.#incorrectCount});
        }

        draggedLetter.snapBack();
        this._setHelpInterval();
        if (!this.difficult)
        {
            this._unhighlightSentenceLetters();
        }
    }

    _setHelpInterval()
    {
        this.setInterval('help', () => this._glowSentenceLetters(), 8000);
    }

    _resolveCorrectLetter(correctLetter, draggedLetter)
    {
        const sentenceLetters = this.#sentenceLetters;
        const resolvedLetters = [correctLetter];

        sentenceLetters.forEach((letter) =>
        {
            if (!resolvedLetters.includes(letter) && letter.equals(correctLetter))
            {
                resolvedLetters.push(letter);
            }
        });

        let resolveOffset = 0;
        const timeline = gsap.timeline();

        timeline.add(draggedLetter.fadeOut());
        timeline.add(draggedLetter.snapBack(0));
        timeline.add(draggedLetter.unhighlight(0));
        timeline.add(draggedLetter.fadeIn());

        resolvedLetters.forEach((letter) =>
        {
            this._removeSentenceLetter(letter);
            const clone = this.#letterManager.cloneLetter(letter);
            clone.showNormalLatinChar();
            timeline.add(letter.sparkle(), resolveOffset);
            timeline.add(letter.fadeOut(), resolveOffset);
            timeline.add(clone.fadeIn(), resolveOffset);
            resolveOffset += 0.5;
        });

        if (!this.#sentenceLetters.length)
        {
            this.#eventsBlocked = true;
            timeline.add(() => this._resolveSentence(), '+=0.5');
        }
        else
        {
            timeline.call(() => this._setHelpInterval());
        }
    }

    _resolveSentence()
    {
        const {audioController} = this;
        const timeline = gsap.timeline();
        const correctAudio = audioController.getAudio('correct_sentence');
        const sentenceAudio = audioController.getAudio(`sentence_${this.#sentenceIndex}`);
        const {sentence, letterPool} = this._game;
        const swapDuration = 0.8;
        timeline
            .add(() => correctAudio.play())
            .add(() => sentenceAudio.play(), correctAudio.duration)
            .add('audioComplete', correctAudio.duration + sentenceAudio.duration)
            .add(() => audioController.play('paper'), 'audioComplete')
            .to(sentence, {duration: swapDuration, alpha: 0}, 'audioComplete')
            .to(letterPool, {duration: swapDuration, x: 500}, 'audioComplete')
            .call(() => this._removeSentence());

        if (this.#sentenceCount < 3)
        {
            timeline
                .call(() => this._createSentence())
                .add('newSentence', '+=0.1')
                .add(() => audioController.play('new_sentence'), 'newSentence')
                .to(sentence, {duration: swapDuration, alpha: 1}, 'newSentence')
                .to(letterPool, {duration: swapDuration, x: -297}, 'newSentence')
                .add(() => this._playSentenceIntro(), '+=0.2');
        }
        else
        {
            timeline
                .call(() => this._evaluateProgress())
                .call(() => this.gotoOutro());
        }
    }

    _removeSentenceLetter(letter)
    {
        const index = this.#sentenceLetters.indexOf(letter);
        if (index !== -1)
        {
            this.#sentenceLetters.splice(index, 1);
        }
    }

    _removeSentence()
    {
        this._game.sentence.children[0].remove();
        this.#letterManager.reset(true);
        this.#latinLetters.length = 0;
    }

    _createSentence()
    {
        this.#sentenceCount++;
        shuffle(this.#unusedSentences);
        const index = this.#unusedSentences.pop();
        const MovieClipClass = this.getMovieClipClass(`game4.Game_4_Sentences_${index}`);
        const sentence = new MovieClipClass('single', 0);
        this._game.sentence.addChild(sentence);
        sentence.children.forEach((child) =>
        {
            if (child.mask)
            {
                const {nominalBounds} = sentence;
                child.mask.cache(
                    nominalBounds.x,
                    nominalBounds.y,
                    nominalBounds.width,
                    nominalBounds.height
                );
            }
        });
        this.#sentenceLetters = this.#letterManager.createInstances(sentence);
        this._createLetterPool();
        this.#sentenceIndex = index;
    }

    _createLetterPool()
    {
        const alternateLowercaseS = !!this.#sentenceLetters.find(
            letter => letter.char === 'S' && !letter.uppercase && letter.alternate
        );

        // unique erhält Reihenfolge
        const chars = unique([
            ...this.#sentenceLetters.map(letter => letter.char),
            ...alphabet
        ]);
        let poolCount = 0;
        const poolKeys = [];

        chars.forEach((char) =>
        {
            if (poolCount === 16) { return; }

            const charTypes = [];
            poolKeys.push(charTypes);

            charTypes.push(char);

            poolCount++;
            if (poolCount === 16) { return; }

            charTypes.push(`${char}2`);
            poolCount++;

            if (char === 'S' && alternateLowercaseS)
            {
                charTypes.push(`${char}3`);
                poolCount++;
            }
        });
        shuffle(poolKeys);
        poolKeys.flat().forEach((key, i) => this._spawnLetter(key, i));
    }

    _spawnLetter(key, i)
    {
        const parent = this._game.letterPool[`letterSpawn${i + 1}`];
        const greekLetterObject = this.createInstance(`game4.Letter_${key}`);
        greekLetterObject.x = 10;
        this.#letterManager.createInstances(greekLetterObject);

        const latinLetterObject = this.createInstance(`game4.Letter_${key}`);
        latinLetterObject.x = 73;
        const [latinLetter] = this.#letterManager.createInstances(latinLetterObject);

        latinLetter.showLatinChar();
        latinLetter.makeDraggable();

        this.#latinLetters.push(latinLetter);
        parent.addChild(greekLetterObject, latinLetterObject);
    }

    _selectLetter()
    {
        if (!this.#draggedLetter) { return; }
        const draggedLetter = this.#draggedLetter;

        draggedLetter.updateGlobalHitArea();

        const selectedLetter = this.#sentenceLetters.find((letter) =>
        {
            const intersection = letter.globalHitArea.intersection(draggedLetter.globalHitArea);
            if (intersection === null) { return false; }
            if ((intersection.width * intersection.height) > 2000)
            {
                return true;
            }
            return false;
        }) || null;

        if (!this.difficult)
        {
            if (!this.#selectedLetter && selectedLetter)
            {
                this.#draggedLetter.highlight();
            }

            if (this.#selectedLetter && !selectedLetter)
            {
                this.#draggedLetter.unhighlight();
            }
        }

        this.#selectedLetter = selectedLetter;
    }

    _update(evt)
    {
        if (this._game && this._started)
        {
            this._timer(evt);
            this._selectLetter();
        }
    }

    _timer(evt)
    {
        this.#msTime += evt.delta;
        const time = Math.floor(this.#msTime / 1000);
        if (time !== this.#time)
        {
            this._mutateStore('setGame4Counts', {time});
            this.#time = time;
        }
    }

    _playQuestion()
    {
        const index = this.#lastQuestion !== 1 ? 1 : 2;
        this.audioController.play(`question_${index}`, {channel: 'luther'});
        this.#lastQuestion = index;
    }

    _playPositiveFeedback()
    {
        this.audioController.play(
            this._getRandomAudioKey('luther_fb_pos_', 4),
            {channel: 'luther'}
        );
    }

    _playNegativeFeedback()
    {
        this.audioController.play(
            this._getRandomAudioKey('luther_fb_neg_', 4),
            {channel: 'luther'}
        );
    }

    _evaluateProgress()
    {
        const incorrectCount = this.#incorrectCount;
        let progress = 3;

        if (incorrectCount > 5)
        {
            progress = 1;
        }
        else if (incorrectCount > 3)
        {
            progress = 2;
        }

        this.setProgress(progress);
        this.setEndScreen([incorrectCount, this.#correctCount]);
    }

    _stopGame()
    {
        this.#letterManager.reset();
        this.#sentenceLetters.length = 0;
        this.#latinLetters.length = 0;
        this._game.removeAllEventListeners();
        audioManager.clearChannel('luther');
    }

    _createOutro()
    {
        super._createOutro();
        this.audioController.stopAtmo('music');
        this._outro.addChild(
            this._kira.set({scaleX: 0.55, scaleY: 0.55, x: 130, y: -65})
        );
    }

    _playOutro()
    {
        this._skipVisible = true;
        this.setTimeout(
            'outro',
            () => this.playLipSyncAudio('kira', 'outro')
                .once('end', () => this.close()),
            1000
        );
    }
}
