<template>
    <div
        class="game-icon frame-size"
        :class="{'on-top': onTop}"
    >
        <div
            class="game-icon-symbol no-tap-highlight"
            :class="gameId"
            @click="$emit('clicked', gameId)"
        >
            <progress-icon
                v-for="(active, index) in getProgressIcons(gameId)"
                :key="index"
                :index="index + 1"
                :active="active"
            />

            <InformationButton
                v-model="contentLinksActive"
            />
        </div>

        <transition
            name="content"
            @after-leave="onTop = false"
            @enter="onTop = true"
        >
            <div v-if="contentLinksActive" class="content-link-wrapper frame-size">
                <default-button
                    v-for="(page, index) in config.infoPages"
                    :key="index"
                    :class="page.link"
                    class="parchment-button small content-link"
                    :style="page.style"
                    @clicked="openContentPage(page.link)"
                >
                    {{ page.name }}
                </default-button>
            </div>
        </transition>
    </div>
</template>

<script>
import InformationButton from './InformationButton.vue';

export default {
    name: 'GameIcon',
    components: {
        InformationButton
    },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    data()
    {
        return {
            contentLinksActive: false,
            onTop: false
        };
    },
    computed: {
        gameId()
        {
            return this.$vnode.key;
        }
    },
    watch: {
        contentLinksActive(value)
        {
            if (!value) { return; }

            this.$emit('openInfoBox', this.gameId);
        }
    },
    methods: {
        openContentPage(page)
        {
            this.$router.push({
                name: 'info',
                params: {page}
            });
        },
        getProgressIcons(gameId)
        {
            const progress = this.$store.getters.getProgress(gameId);
            const icons = [];
            for (let i = 1; i <= 3; i++)
            {
                icons.push(i <= progress);
            }
            return icons;
        }
    }
};
</script>

<style lang="scss" src="./GameIcon.scss" />

