import gsap from 'gsap';

export default class Arm
{
    #movieClip = null;
    #y = 0;
    #yHidden = 0;
    #height = 0;
    #readyToCatch = false;
    #isHiding = false;
    #xCatchMin = 0;
    #xCatchMax = 0;
    #bible = null;

    constructor(movieClip)
    {
        this.#movieClip = movieClip;
        this.#y = movieClip.y;
        this.#height = movieClip.nominalBounds.height;
        this.#yHidden = this.#y + this.#height + 30;
        const {nominalBounds} = movieClip;
        this.#xCatchMin = movieClip.x + nominalBounds.x;
        this.#xCatchMax = this.#xCatchMin + nominalBounds.width;
    }

    get readyToCatch()
    {
        return this.#readyToCatch;
    }

    get isHiding()
    {
        return this.#isHiding;
    }

    reset()
    {
        this.#movieClip.y = this.#yHidden;
        this.#readyToCatch = false;
        this.#isHiding = false;
        this.#movieClip.visible = false;
        if (this.#bible)
        {
            this.#bible.remove();
            this.#bible = null;
        }
    }

    show()
    {
        this.#readyToCatch = true;
        this.#movieClip.visible = true;
        gsap.to(this.#movieClip, {y: this.#y, duration: 0.7});
    }

    hide()
    {
        this.#isHiding = true;
        gsap.to(
            this.#movieClip,
            {y: this.#yHidden, duration: 1, onComplete: () => this.reset()}
        );
    }

    canCatch(x, globalPoint)
    {
        if (x < this.#xCatchMin || x > this.#xCatchMax) { return false; }
        const trigger = this.#movieClip.trigger_mc;
        trigger.alpha = 0.1;
        const point = trigger.globalToLocal(globalPoint.x, globalPoint.y);
        const hit = trigger.hitTest(point.x, point.y);
        trigger.alpha = 0;
        return hit;
    }

    addBible(bible, globalPoint)
    {
        this.#readyToCatch = false;
        const movieClip = this.#movieClip;
        const bibleArmPosition = movieClip.globalToLocal(globalPoint.x, globalPoint.y);
        movieClip.addChild(bible.set(bibleArmPosition));
        this.#bible = bible;
    }
}
