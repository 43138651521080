import ImageLoader from '@kids/lib/class/ImageLoader';
import compositions from '../static/compositions.json';
import {prependCompositionUrl} from '../service/urlUtility';
import createjs from '.';

const {document, cordova} = globalThis;
const cache = {};
const minString = !cordova && process.env.NODE_ENV !== 'development' ? '.min' : '';

export default class CompositionLoader extends ImageLoader
{
    static createCachedLoader(id)
    {
        const loader = cache[id] || new CompositionLoader(id);
        loader.addLock();
        cache[id] = loader;
        return loader;
    }

    constructor(id)
    {
        super();
        this._locks = 0;
        this._id = id;
        this._compositionKey = compositions[id];
        this._composition = null;
        this._script = document.createElement('script');
        this._script.addEventListener('load', () => this._scriptLoaded(), {once: true});
        this._script.src = prependCompositionUrl(`${this._id}/${this._id}${minString}.js`);

        document.head.appendChild(this._script);

        this.once('loaded', () => this._prepareComposition());
    }

    get composition()
    {
        return this._composition;
    }

    get id()
    {
        return this._id;
    }

    addLock()
    {
        this._locks++;
    }

    _scriptLoaded()
    {
        this._composition = globalThis.AdobeAn.getComposition(this._compositionKey);
        const {manifest} = this._composition.getLibrary().properties;

        manifest.forEach((entry) =>
        {
            this.add({id: entry.id, src: prependCompositionUrl(`${this._id}/${entry.src}`)});
        });

        this.start();
    }

    _prepareComposition()
    {
        const composition = this._composition;
        const images = composition.getImages();
        for (const imageId in this.getAll())
        {
            images[imageId] = this.get(imageId);
        }
        const ss = composition.getSpriteSheet();
        const {ssMetadata} = composition.getLibrary();

        for (let i = 0; i < ssMetadata.length; i++)
        {
            ss[ssMetadata[i].name] = new createjs.SpriteSheet({
                images: [images[ssMetadata[i].name]],
                frames: ssMetadata[i].frames
            });
        }
    }

    unload()
    {
        this._locks--;
        if (this._locks) { return; }
        this._script.remove();
        this._images = null;
        this._imagesToLoad = null;
        this.removeAllListeners();

        const {AdobeAn} = globalThis;
        if (AdobeAn && AdobeAn.compositions[this._compositionKey])
        {
            delete AdobeAn.compositions[this._compositionKey];
        }

        if (cache[this._id])
        {
            delete cache[this._id];
        }
    }
}
