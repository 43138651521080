<template>
    <article class="german-language">
        <h2>Die deutsche Sprache</h2>
        <image-box
            image="content/page/fw_info4_b5.png"
            title="Die Lutherbibel"
        />
        <p>
            Bei seiner Übersetzung hat Martin ganz schön nach Worten suchen müssen.
        </p>
        <p>
            Er musste nicht nur herausfinden, was die griechischen, hebräischen und lateinischen
            Wörter bedeuteten. Martin musste auch genau überlegen, welches deutsche Wort vom Sinn
            her am besten passte. Außerdem suchte er nach Worten, die auch Menschen verstehen
            konnten, die keine Universität besucht hatten. Eine einheitliche deutsche Sprache
            gab es damals auch noch nicht. Es wurden viele verschiedene Dialekte gesprochen.
            Luther hat den Leuten gut zugehört und Wörter für seine Übersetzung gesammelt.
            Er nannte das „den Leuten aufs Maul schauen“. Immer mehr Menschen begannen
            in Luthers Sprache zu schreiben und zu reden. So hat sich aus der Sprache
            der Lutherbibel allmählich unser Hochdeutsch entwickelt.
        </p>
        <p>
            Bildrechte: © Wikipedia, gemeinfrei
        </p>
    </article>
</template>

<script>

export default {
    name: 'GermanLanguage'
};
</script>
