<template>
    <div class="game-hud game5">
        <div class="count catched">
            {{ count.catched }}
        </div>
        <div class="count to-catch">
            {{ count.toCatch }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Game5Hud',
    computed: {
        count()
        {
            return this.$store.getters.getHud('game5');
        }
    }
};
</script>

<style lang="scss" src="./Hud.scss" />
