<template>
    <article class="martins-conviction">
        <h2>Martins Verurteilung</h2>
        <image-box
            image="content/page/fw_info3_b3.png"
            title="Reichstag zu Worms"
        />
        <p>
            Als Martin lebte, war Deutschland ein großes Reich,
            das aus vielen kleinen Ländern und ein paar großen Städten bestand.
        </p>
        <p>
            Alle diese Länder und Städte gehörten zum Reich des Kaisers. Noch über dem Kaiser
            stand der Papst, denn der bestimmte, wie man leben sollte, um Gott zu gefallen.
            Da weder Kaiser noch Papst sich um alle Angelegenheiten selbst kümmern konnten,
            gab es Fürsten, Herzöge, Bischöfe und viele Adelige: Manche regierten nur
            über ein paar Dörfer. Andere hatten ein großes Herrschaftsgebiet.
            Alle Adeligen mussten sich an die Anordnungen von Kaiser und Papst halten.
            Da diese aber oft nicht in der Nähe waren, konnten die Adligen viel frei
            entscheiden. Wenn ganz wichtige Dinge zu regeln waren, wurde ein Reichstag einberufen,
            zu dem dann auch der Kaiser kam. Bei einem solchen Reichstag wurde Martins Verurteilung
            beschlossen: Er wurde aus der Kirche ausgeschlossen und niemand durfte
            ihn mehr beschützen: Martin war vogelfrei.
        </p>
        <p>
            Bildrechte: Lutherdenkmal in Worms, Foto: Dieter Schütz / pixelio.de
        </p>
    </article>
</template>

<script>

export default {
    name: 'MartinsConviction'
};
</script>
