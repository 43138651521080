export default {
    progress: {
        game1: 0,
        game2: 0,
        game3: 0,
        game4: 0,
        game5: 0
    },
    difficult: {
        game1: false,
        game2: false,
        game3: false,
        game4: false,
        game5: false
    },
    huds: {
        game1: [
            false, false, false, false, false
        ],
        game2: {
            correct: 0,
            incorrect: 0
        },
        game3: {
            pursuer: 0,
            pursued: 0
        },
        game4: {
            correct: 0,
            incorrect: 0,
            time: 0
        },
        game5: {
            catched: 0,
            toCatch: 20
        }
    },
    endScreen: {
        gameId: null,
        correctAnswers: [],
        progress: 0,
        initialVisit: true
    }
};
