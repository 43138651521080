import LoaderCollection from '@kids/lib/class/LoaderCollection';
import LipSyncer from '@kids/lib/class/LipSyncer';
import {mix, Eventable, Timeable} from '@kids/lib/mixin';
import CompositionLoader from './CompositionLoader';
import audioManager from '../service/audioManager';
import eventBus from '../service/eventBus';
import hasTouchSupport from '../service/hasTouchSupport';
import createjs from '.';
import ticker from './ticker';
import audios from '../static/audios.json';

const {document} = globalThis;

const lipDataHandler = function(evt)
{
    const {lipData} = audios[evt.namespace];
    this.setData(lipData[evt.id]);
};

export default class CompositionRenderer extends mix().with(Eventable, Timeable)
{
    #compositionIds = [];
    #compositions = {};
    #canvas = document.createElement('canvas');
    #lipSyncers = {};
    #touchSupport = false;
    #audioController = null;
    #frameCallbacks = [];
    #boundHandlers = {};

    _kira = null;
    _loaderCollection = new LoaderCollection();
    _stageScale = 1;
    _vueParent = null;
    _stage = null;

    constructor(compositionIds, audioNamespace = 'global')
    {
        super();

        this.#compositionIds = compositionIds;
        this.#audioController = audioManager.loadNamespace(audioNamespace);
        this._loaderCollection.once('loaded', () => this._loadedHandler());

        compositionIds.forEach((id) =>
        {
            this._loaderCollection.add(
                CompositionLoader.createCachedLoader(id)
            );
        });
        this._loaderCollection.add(this.#audioController);
    }

    get canvas()
    {
        return this.#canvas;
    }

    get stage()
    {
        return this._stage;
    }

    get audioController()
    {
        return this.#audioController;
    }

    set vueParent(parent)
    {
        this._vueParent = parent;
    }

    get centerPoint()
    {
        const {width, height} = this.#canvas;
        return {x: width / 2, y: height / 2};
    }

    set touchSupport(active)
    {
        if (active && !this.#touchSupport)
        {
            this._stage.enableDOMEvents(false);
            createjs.Touch.enable(this._stage, true, false);
            this.#touchSupport = true;
        }
        else if (!active && this.#touchSupport)
        {
            this._stage.enableDOMEvents(true);
            createjs.Touch.disable(this._stage);
            this.#touchSupport = false;
        }
    }

    get touchSupport()
    {
        return this.#touchSupport;
    }

    _init()
    {
        ticker.addRenderer(this);
        this.fire('initialized');
    }

    _loadedHandler()
    {
        this._loaderCollection.getLoaders().forEach((loader) =>
        {
            if (loader instanceof CompositionLoader)
            {
                this.#compositions[loader.id] = loader.composition;
            }
        });

        const {properties} = this.getLibrary(this.#compositionIds[0]);

        this.#canvas.width = properties.width * this._stageScale;
        this.#canvas.height = properties.height * this._stageScale;

        this._stage = new createjs.Stage(this.#canvas);
        this._prepareTouchSupport();
        this._prepareKira();
        this._init();
    }

    _prepareTouchSupport()
    {
        this.touchSupport = hasTouchSupport();
        this.#boundHandlers.changeTouchSupport = (evt) =>
        {
            this.touchSupport = evt.support;
        };
        eventBus.on('changeTouchSupport', this.#boundHandlers.changeTouchSupport);
    }

    _prepareKira()
    {
        if (!this.#compositionIds.includes('kira')) { return; }

        this._kira = this.createInstance('kira.kira');
        this.createLipSyncer(
            'kira',
            this._kira.getMovieClipsByClass(this.getMovieClipClass('kira.schnabel_anim'))[0]
        );
    }

    center(instance)
    {
        return instance.set(this.centerPoint);
    }

    scale(instance)
    {
        return instance.set({scaleX: this._stageScale, scaleY: this._stageScale});
    }

    getLibrary(id)
    {
        return this.getComposition(id).getLibrary();
    }

    getComposition(id)
    {
        return this.#compositions[id];
    }

    createInstance(name)
    {
        const InstanceClass = this.getMovieClipClass(name);
        return new InstanceClass();
    }

    getMovieClipClass(name)
    {
        const [composition, className] = name.split('.');
        return this.getLibrary(composition)[className];
    }

    getInstanceByClass(name)
    {
        return this._stage.getMovieClipsByClass(this.getMovieClipClass(name))[0];
    }

    createLipSyncer(key, object)
    {
        if (this.#lipSyncers[key])
        {
            this.#lipSyncers[key]._object = object;
            return;
        }
        object.gotoAndStop(0);
        const syncer = new LipSyncer({object, setter: 'gotoAndStop'});
        syncer.on('audioPlay', lipDataHandler);
        this.#lipSyncers[key] = syncer;
    }

    playLipSyncAudio(lipSyncerKey, audioId, options = {})
    {
        const lipSyncer = this.#lipSyncers[lipSyncerKey];
        options.lipSyncer = lipSyncer;
        options.channel = lipSyncerKey;
        return this.#audioController.play(audioId, options);
    }

    _callAfter(callback, frames)
    {
        this.#frameCallbacks.push({callback, frames});
    }

    _update()
    {

    }

    _updateFrameCallbacks()
    {
        let filter = false;
        this.#frameCallbacks.forEach((handler) =>
        {
            if (handler.frames === 0)
            {
                filter = true;
                handler.callback();
                return;
            }
            handler.frames--;
        });
        if (filter)
        {
            this.#frameCallbacks = this.#frameCallbacks.filter(handler => handler.frames > 0);
        }
    }

    update(evt)
    {
        this._updateFrameCallbacks();
        this._update(evt);
        this._stage.update(evt);
    }

    destroy()
    {
        eventBus
            .fire('kira.stop')
            .off('changeTouchSupport', this.#boundHandlers.changeTouchSupport);
        this.removeAllListeners();
        this._loaderCollection.destroy();
        this._loaderCollection = null;
        this.#compositions = null;
        this._loaders = null;
        this.#canvas = null;
        this.#lipSyncers = null;
        this.#frameCallbacks.length = 0;
        this._vueParent = null;
        this._stage.enableDOMEvents(false);
        if (this.#touchSupport)
        {
            createjs.Touch.disable(this._stage);
        }
        ticker.removeRenderer(this);
        this._stage = null;
    }
}
