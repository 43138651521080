import AudioController from '@kids/lib/class/AudioController';
import audioCore from '@kids/lib/service/audioCore';
import {prependAudioUrl} from './urlUtility';
import audios from '../static/audios.json';
import eventBus from './eventBus';

const locks = {
    global: 0,
    game1: 0,
    game2: 0,
    game3: 0,
    game4: 0,
    game5: 0
};

eventBus.on('kira.stop', () => audioCore.clearChannel('kira'));

export default
{
    loadNamespace(namespace)
    {
        locks[namespace]++;
        let controller = audioCore.getController(namespace);
        if (controller)
        {
            controller.reload();
            return controller;
        }

        controller = audioCore.createController(namespace, prependAudioUrl(namespace));
        controller.loadManifest(audios[namespace].ids);
        return controller;
    },
    unloadNamespace(namespace)
    {
        const controller = audioCore.getController(namespace);
        if (controller)
        {
            locks[namespace]--;
            if (locks[namespace]) { return; }
            controller.unload();
        }
    },
    getController(namespace)
    {
        return audioCore.getController(namespace);
    },
    clearChannel(channel)
    {
        audioCore.clearChannel(channel);
    },
    pauseAll()
    {
        AudioController.getAll().forEach(controller => controller.pauseAll());
    },
    resumeAll()
    {
        AudioController.getAll().forEach(controller => controller.resumeAll());
    }
};
