<template>
    <div class="world frame-size">
        <GameIcon
            v-for="(game, index) in gameConfig"
            :key="index"
            :ref="index"
            :config="game"
            @clicked="openDifficultySelection"
            @openInfoBox="openInfoBox"
        />

        <StageWrapper
            ref="kira"
            class="kira"
            :render-class="'Kira'"
            :hidden="kiraHidden"
            @click.native="introPlaying ? stopIntro() : playIntro()"
            @rendered="kiraRendered"
        />

        <default-button
            class="parchment-button imprint-button"
            @clicked="$router.push({name: 'imprint'})"
        >
            Impressum
        </default-button>

        <modal v-model="showModal" class="modal-difficulty">
            <div class="selection-box">
                <div class="selection selection-easy no-tap-highlight" @click="openGame()">
                    <span class="selection-box-text">leicht</span>
                </div>
                <div class="selection selection-hard no-tap-highlight" @click="openGame(true)">
                    <span class="selection-box-text">schwer</span>
                </div>
            </div>
        </modal>

        <EndScreen v-if="endScreen.gameId" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import gameConfig from '@/static/gameConfig.json';
import GameIcon from '../components/views/World/GameIcon';
import EndScreen from '../components/views/World/EndScreen';
import eventBus from '../service/eventBus';
import audioManager from '../service/audioManager';
import navigationGuards from '../mixins/navigationGuards';

const musicVolume = 0.1;
const musicSpeakVolume = 0.01;

export default {
    name: 'World',
    components:
    {
        GameIcon,
        EndScreen
    },
    mixins: [navigationGuards],
    data()
    {
        return {
            kiraReady: false,
            kiraHidden: false,
            showModal: false,
            activeContentLinks: null,
            introPlaying: false,
            gameConfig
        };
    },
    computed: {
        ...mapGetters([
            'endScreen'
        ]),
        musicCanPlay()
        {
            return this.kiraReady && this.$route.name === 'world';
        },
        initialIntroCanPlay()
        {
            return !this.$root.introPlayed && this.musicCanPlay;
        }
    },
    watch:
    {
        'endScreen.gameId': function(gameId)
        {
            if (gameId === null)
            {
                this.kiraHidden = false;
            }
        },
        musicCanPlay(canPlay)
        {
            if (canPlay)
            {
                this.$audioController.playAtmo('music', {volume: musicVolume});
                this.$audioController.playAtmo('atmo', {volume: 0.85});
                this._music = this.$audioController.getAtmo('music');
            }
        },
        initialIntroCanPlay(canPlay)
        {
            if (canPlay)
            {
                this.playIntro({delay: 0.6});
                this.$root.introPlayed = true;
            }
        }
    },
    created()
    {
        const {endScreen} = this;
        const {name: currentRouteName} = this.$router.currentRoute;

        if (endScreen.gameId)
        {
            this.kiraHidden = true;
            if (currentRouteName !== 'endscreen')
            {
                this.$router.push({name: 'endscreen'});
                return;
            }
        }

        if (!endScreen.gameId && currentRouteName === 'endscreen')
        {
            this.$router.push({name: 'world'});
        }
    },
    destroyed()
    {
        this.$audioController.stopAtmo('atmo');
        if (!this._music) { return; }
        this._music.fade(0, 0.3);
        this._music.on('fadeEnd', () =>
        {
            this.$audioController.stopAtmo('music');
            this._music = null;
        });
    },
    methods:
    {
        openDifficultySelection(gameId)
        {
            this.showModal = true;
            this._gameToOpen = gameId;
            if (this.activeContentLinks)
            {
                this.$refs[this.activeContentLinks][0].contentLinksActive = false;
                this.activeContentLinks = null;
            }
            eventBus.fire('kira.stop');
            audioManager.getController('global').play('open_modal');
        },
        openGame(difficult = false)
        {
            const {_gameToOpen: gameId} = this;
            if (!gameId) { return; }

            this.$store.commit(
                'setDifficult',
                {gameId, difficult}
            );
            this.$router.push({name: gameId});
            this._gameToOpen = null;
        },
        openInfoBox(gameId)
        {
            if (this.activeContentLinks && this.activeContentLinks !== gameId)
            {
                this.$refs[this.activeContentLinks][0].contentLinksActive = false;
            }

            this.activeContentLinks = gameId;
        },
        playIntro(options)
        {
            if (this.introPlaying || this.endScreen.gameId) { return; }
            this._music.fade(musicSpeakVolume, 0.1);
            this.$refs.kira.getRenderer().playLipSyncAudio('kira', 'global.intro', options)
                .once('end', () =>
                {
                    this._music.fade(musicVolume, 0.3);
                    this.introPlaying = false;
                })
                .once('stop', () =>
                {
                    this.introPlaying = false;
                    if (this._music)
                    {
                        this._music.fade(musicVolume, 0.3);
                    }
                })
                .once('play', () => { this.introPlaying = true; });
        },
        stopIntro()
        {
            eventBus.fire('kira.stop');
        },
        kiraRendered()
        {
            this.kiraReady = true;
        }
    }
};
</script>

<style lang="scss" src="./World.scss" />

