<template>
    <article class="fear-of-god">
        <h2>Angst vor Gott</h2>
        <image-box
            image="content/page/fw_info1_b3.png"
            title="Ein Arzt hilft Kranken"
        />
        <p>
            Große Angst bestimmte damals das Leben der Menschen.
        </p>
        <p>
            Nicht nur die Armut machte das Leben schwer. Das Mittelalter war auch eine Zeit,
            in der es viele Kriege und schlimme Krankheiten gab. Die meisten Menschen mussten
            früh sterben, egal, ob sie arm oder reich waren. Es gab kaum Ärzte und Medizin.
            Die Menschen hatten große Angst vor dem Tod. Und sie fürchteten sich vor dem,
            was nach dem Tod kam. Oft wurden auch besondere Schutzheilige um Hilfe gebeten.
            Die Heilige Anna etwa sollte vor Gewitter und frühem Tod beschützen.
            Wenn ein Kind Fieber hatte, betete man zur Heiligen Radegundis.
            Die Kirche behauptete damals, dass nur die guten Menschen in den Himmel kämen.
            Alle anderen würden von Gott bestraft und kämen in die Hölle.
            Viele Prediger machten den Menschen damit Angst. Sie behaupteten,
            man müsse viel beten und fasten, um von Gott gerettet zu werden.
            Oder sie verkauften den Menschen teure Ablassbriefe.
            Man sollte sich angeblich mit Geld von der Hölle freikaufen können.
            Die Menschen fürchteten sich vor Gottes Strafe. Sie wollten alles dafür tun,
            dass er gut zu ihnen war.
        </p>
        <p>
            Bildrechte: Miniatur aus der Toggenburg-Bibel (Schweiz) von 1411 © Wikipedia, gemeinfrei
        </p>
    </article>
</template>

<script>

export default {
    name: 'FearOfGod'
};
</script>
