<template>
    <article class="live-in-monastery">
        <h2>95 Thesen</h2>
        <image-box
            image="content/page/fw_info3_b1.png"
            title="Martin schlägt seine 95 Thesen an"
        />
        <p>
            Als Martin erkannte, dass Gott alle Menschen so liebt, wie sie sind,
            musste er dies allen erzählen.
        </p>
        <p>
            Martin ist sich sicher, dass die Bibel falsch verstanden wurde.
            Man braucht keine Angst vor Gott zu haben. Nein,
            Gott ist für einen da wie ein starker Freund und noch viel mehr.
            Wenn man sich Gottes Liebe nicht verdienen musste,
            dann waren auch die Ablässe und das strenge Klosterleben unnötig.
            Dann war auch das falsch, was die Ablassprediger sagten.
            Martin war überzeugt von seiner Kirche. Also wollte er,
            dass möglichst viele Menschen davon erfahren.
            Falsche Ansichten über Gott sollten verschwinden.
            Alle Menschen sollten ohne Angst leben können.
            Deswegen verfasste Martin im Jahr 1517 insgesamt 95 Sätze, sogenannte Thesen,
            und hängt diese an der Türe der Wittenberger Schlosskirche auf.
            Die Menschen sollten darüber miteinander ins Gespräch kommen.
            Die Kirche hatte aber Angst vor Martins Ideen und den Veränderungen,
            die sie bewirken konnten.
        </p>
        <p>
            Bildrechte: Lutherdenkmal in Worms, Foto: Dieter Schütz / pixelio.de
        </p>
    </article>
</template>

<script>

export default {
    name: 'Theses'
};
</script>
