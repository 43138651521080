import FileStorage from './FileStorage';

const storage = new FileStorage();

const restoreStateData = async(storeKey, commit) =>
{
    const data = await storage.get(storeKey);
    if (!data) { return; }
    for (const dataKey in data)
    {
        commit(dataKey, data[dataKey]);
    }
};

const storageManager = async(store) =>
{
    await restoreStateData(
        'lutherGameProgress',
        (gameId, progress) => store.commit('setProgress', {gameId, progress})
    );
    await restoreStateData(
        'lutherGameDifficult',
        (gameId, difficult) => store.commit('setDifficult', {gameId, difficult})
    );

    store.subscribe((mutation, state) =>
    {
        if (mutation.type === 'setProgress')
        {
            storage.set('lutherGameProgress', state.progress);
        }
        if (mutation.type === 'setDifficult')
        {
            storage.set('lutherGameDifficult', state.difficult);
        }
    });
};

export default storageManager;

