import {shuffle} from '@kids/lib/transform/array';
import audioCore from '@kids/lib/service/audioCore';
import Game from '../Game';
import Arm from './Arm';
import ThrowingArrow from './ThrowingArrow';
import FlyingBible from './FlyingBible';

const {Math} = globalThis;
const biblesToCatch = 20;
const newBibleInterval = 4;

export default class Game5 extends Game
{
    #showArmInterval = 10;
    #throwingArrow = null;
    #visibleArm = null;
    #arms = [];
    #flyingBibles = [];
    #newFlyingBible = null;

    #luther = null;
    #fallingBible = null;

    #biblesCatched = 0;
    #biblesMissed = 0;
    #biblesToStack = 10;
    #biblesStacked = 0;

    #forceLutherFeedbackTreshold = 6;
    #missingLutherFeedbackTreshold = 3;
    #missingLutherFeedbackCount = 2;

    constructor()
    {
        super('game5');
    }

    _init()
    {
        super._init();

        this._mutateStore('setGame5Counts', {catched: 0, toCatch: biblesToCatch});
        FlyingBible.setDotClass(this.getMovieClipClass('game5.FlyCurveDot'));
    }

    _createIntro()
    {
        super._createIntro();
        this._intro.addChild(
            this._kira.set({scaleX: 0.55, scaleY: 0.55, x: 170, y: -25})
        );
        this.audioController.playAtmo('atmo', {volume: 0.5});
        this.playLipSyncAudio('kira', 'intro', {delay: 0.8})
            .once(
                'end',
                () => this.setTimeout('tutorial', () => this.gotoTutorial(), 600)
            );
    }

    _stopIntro()
    {
        this.audioController.stopAtmo('atmo');
    }

    _createTutorial()
    {
        super._createTutorial();
        this._tutorial.gotoAndStop(this.touchSupport ? 'mobile' : 'desktop');
        this.audioController.playAtmo('printing_press', {volume: 0.25});
    }

    _playTutorial()
    {
        super._playTutorial();
        this.audioController.play(
            `tutorial_${this.touchSupport ? 'mobile' : 'pc'}`,
            {channel: 'kira'}
        ).once('end', () => this.gotoGame());
    }

    _createGame()
    {
        super._createGame();

        this.#throwingArrow = new ThrowingArrow(this);
        this.#throwingArrow.on('throw', evt => this._throwBible(evt.velocity, evt.rotationRad));

        this.#luther = this._game.luther_mc;
        this.#fallingBible = this._game.fallingBible_luther_mc;

        this.#luther.gotoAndStop('idle_without_bible');
        this.#fallingBible.gotoAndStop('lying');

        this.#arms.push(
            new Arm(this._game.arm_1_mc),
            new Arm(this._game.arm_2_mc),
            new Arm(this._game.arm_3_mc),
            new Arm(this._game.arm_4_mc),
            new Arm(this._game.arm_5_mc)
        );
        this.#arms.forEach(arm => arm.reset());

        if (this.difficult)
        {
            this.#showArmInterval = 6;
        }

        if (!this.audioController.getAtmo('printing_press'))
        {
            this.audioController.playAtmo('printing_press', {volume: 0.25});
        }
        this.audioController.playAtmo('music', {volume: 0.15});
    }

    _startGame()
    {
        super._startGame();
        this.#throwingArrow.show();
        this.#throwingArrow.enabled = true;
        this._pickUpBible();
        this._showArm();
    }

    _update()
    {
        if (this._game && this._started)
        {
            this.#throwingArrow.update();
            this._checkLutherState();
            this._updateBibles();
        }
    }

    _updateBibles()
    {
        const readyArms = this.#arms.filter(arm => arm.readyToCatch);
        this.#flyingBibles.forEach(bible => bible.update(readyArms));
    }

    _checkLutherState()
    {
        const label = this.#luther.currentLabel;
        if (label === 'bible_left_hand')
        {
            this._startBibleFlying();
        }
        else if (label === 'take_book_finished')
        {
            if (this.#newFlyingBible)
            {
                this.#luther.gotoAndPlay('throw');
            }
            else
            {
                this.#luther.gotoAndStop('idle');
            }
            this._createNewBible();
        }
        else if (label === 'book_taken' && this.#fallingBible.visible)
        {
            this.#fallingBible.visible = false;
        }
    }

    _throwBible(velocity, rotationRad)
    {
        const label = this.#luther.currentLabel;
        if (
            label === 'throw' || label === 'bible_left_hand' ||
            (label === 'book_taken' && this.#newFlyingBible)
        )
        {
            return;
        }
        if (
            label === 'look_at_flying_bible' || label === 'take_new_bible' ||
            label === 'idle_without_bible'
        )
        {
            this.#luther.gotoAndPlay(
                label === 'idle_without_bible' ? 'book_taken' : 'idle_without_bible'
            );
        }
        else
        {
            this.#luther.gotoAndPlay('throw');
        }

        const bibleObject = this.createInstance('game5.FlyingBible');
        this._game.addChild(this.#throwingArrow.centerObject(bibleObject));

        this.#newFlyingBible = new FlyingBible(bibleObject, velocity, rotationRad);
        this.#newFlyingBible.once('finish', evt => this._finishBibleFlying(evt));
    }

    _pickUpBible()
    {
        this.#luther.gotoAndPlay('idle_without_bible');
    }

    _createNewBible()
    {
        const fallingBible = this.#fallingBible;
        if (fallingBible.visible)
        {
            if (this.#biblesStacked < this.#biblesToStack)
            {
                const bibleStack = this._game.growingStaple_mc;
                const lastBibleOnStack = bibleStack.lastBibleOnGrowingStaple_mc;
                this.#biblesStacked++;
                const stackBible = this.createInstance('game5.StackBible');
                stackBible.x = (lastBibleOnStack?.x || 0) + (Math.floor(Math.random() * 5) - 2);
                stackBible.y = (lastBibleOnStack?.y || 0) + (Math.floor(Math.random() * 5) - 2) -
                    (this.#biblesStacked * 10);
                bibleStack.addChild(stackBible);
            }
        }
        else
        {
            fallingBible.gotoAndPlay('falling');
            fallingBible.visible = true;
        }

        this.setTimeout(
            'createNewBible',
            () => this._createNewBible(),
            newBibleInterval * 1000
        );
    }

    _showArm()
    {
        const arms = shuffle([...this.#arms]);
        if (this.#visibleArm)
        {
            this.#visibleArm.hide();
        }
        [this.#visibleArm] = arms.filter(arm => !arm.isHiding);
        if (this.#visibleArm)
        {
            this.#visibleArm.show();
        }
        this._playWantBibleFeedback();
        this.setTimeout(
            'showArm',
            () => this._showArm(true),
            this.#showArmInterval * 1000
        );
    }

    _startBibleFlying()
    {
        if (this.#newFlyingBible)
        {
            this.#flyingBibles.push(this.#newFlyingBible.start());
            this.audioController.play(this._getRandomAudioKey('swoosh', 2));
            this.#newFlyingBible = null;
        }
    }

    _finishBibleFlying(evt)
    {
        this.#flyingBibles.splice(this.#flyingBibles.indexOf(evt.bible), 1);
        if (evt.catched)
        {
            this.#biblesCatched++;
            this._mutateStore('setGame5Counts', {catched: this.#biblesCatched});
            this._playPositiveFeedback();

            if (this.#biblesCatched >= biblesToCatch)
            {
                this._finishGame();
            }
            else if (!evt.armToCatch.isHiding)
            {
                this._showArm(false);
            }
        }
        else
        {
            this.#biblesMissed++;
            this._playNegativeFeedback(evt.bible);
        }
    }

    _finishGame()
    {
        this._started = false;
        this.cancelTimeout();

        if (this.#visibleArm)
        {
            this.#visibleArm.hide();
            this.#visibleArm = null;
        }
        this.#luther.gotoAndStop('idle_without_bible');
        this.#throwingArrow.hide();
        this.#throwingArrow.enabled = false;
        audioCore.clearChannel('wantBible');
        this.audioController.play('game_end', {eventable: true, channel: 'luther'})
            .once('end', () => this.gotoOutro());
        this._evaluateProgess();
    }

    _stopGame()
    {
        this.cancelTimeout();
        this.#arms.length = 0;
        this.#flyingBibles.length = 0;
        audioCore.clearChannel('luther');
        if (this.#throwingArrow)
        {
            this.#throwingArrow.destroy();
            this.#throwingArrow = null;
        }
        const music = this.audioController.getAtmo('music');
        const press = this.audioController.getAtmo('printing_press');

        music.fade(0, 0.5).once('fadeEnd', () => music.stop());
        press.fade(0, 2).once('fadeEnd', () => press.stop());
    }

    _evaluateProgess()
    {
        const biblesMissed = this.#biblesMissed;
        let progress = 3;

        if (biblesMissed > 6)
        {
            progress = 1;
        }
        else if (biblesMissed > 3)
        {
            progress = 2;
        }

        this.setProgress(progress);
        this.setEndScreen([biblesMissed]);
    }

    _playWantBibleFeedback()
    {
        this.audioController.play(
            this._getRandomAudioKey('want_bible_', 8),
            {delay: 0.8, channel: 'wantBible'}
        );
    }

    _playPositiveFeedback()
    {
        const missingCount = ++this.#missingLutherFeedbackCount;
        const missingTreshold = this.#missingLutherFeedbackTreshold;
        const forceTreshold = this.#forceLutherFeedbackTreshold;
        const randomValue = Math.random();

        if (randomValue < 0.6)
        {
            const audioKey = this._getRandomAudioKey('catched_', 5);
            if (
                (Math.random() < 0.75 || missingCount < missingTreshold) &&
                missingCount < forceTreshold
            )
            {
                this.audioController.play(audioKey);
            }
            else
            {
                this.audioController.play(audioKey)
                    .once('end', () => this._playPositiveLutherFeedback());
            }
        }
        else if
        (
            (randomValue < 0.8 || missingCount >= forceTreshold) &&
            missingCount >= missingTreshold
        )
        {
            this._playPositiveLutherFeedback();
        }
    }

    _playPositiveLutherFeedback()
    {
        if (audioCore.isChannelPlaying('luther')) { return; }

        this.audioController.play(
            this._getRandomAudioKey('positive_', 4),
            {channel: 'luther'}
        );

        this.#missingLutherFeedbackCount = 0;
    }

    _playNegativeFeedback(bible)
    {
        const randomValue1 = Math.random();
        const {audioController} = this;

        if (randomValue1 < 0.6)
        {
            let bibleInFace = false;
            const randomValue2 = Math.random();

            if (bible.willLandInsideCrowd)
            {
                if (randomValue2 < 0.4)
                {
                    bibleInFace = true;
                    audioController.playQueue([
                        'hit_face', this._getRandomAudioKey('in_face_', 4)
                    ]);
                }
                else if (randomValue2 < 0.8)
                {
                    audioController.play('smash');
                }
            }

            if (!bibleInFace && Math.random() >= 0.7)
            {
                audioController.play(this._getRandomAudioKey('negative_', 6));
            }
        }
        else if (randomValue1 < 0.9)
        {
            audioController.play(this._getRandomAudioKey('negative_', 6));
        }
    }

    _createOutro()
    {
        super._createOutro();
        this._outro.addChild(
            this._kira.set({scaleX: 0.55, scaleY: 0.55, x: 175, y: -55})
        );
        this.audioController.playAtmo('atmo', {volume: 0.5});
        this.playLipSyncAudio('kira', 'outro', {delay: 1})
            .once('end', () => this.close());
    }
}
