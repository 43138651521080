<template>
    <figure role="group" class="image-group">
        <img
            class="image-icon" :src="require('@/assets/' + image)"
            :title="title"
        >
        <figcaption v-if="!noSubtitle" class="image-subtitle">
            {{ title }}
        </figcaption>
    </figure>
</template>

<script>

export default {
    name: 'ImageBox',
    props: {
        image: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        noSubtitle: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" src="./ImageBox.scss" />
