<template>
    <div class="frame-size rose-loader">
        <img
            class="rose layer1"
            :src="require('@/assets/reusable/rose-loader/blende_rose_layer1.png')"
        >
        <img
            class="rose layer2"
            :src="require('@/assets/reusable/rose-loader/blende_rose_layer2.png')"
        >
        <img
            class="rose layer3"
            :src="require('@/assets/reusable/rose-loader/blende_rose_layer3.png')"
        >
        <img
            class="rose layer4"
            :src="require('@/assets/reusable/rose-loader/blende_rose_layer4.png')"
        >
    </div>
</template>

<script>

export default {
    name: 'RoseLoader'
};
</script>

<style lang="scss" src="./RoseLoader.scss" />
