import localStore from 'store2';

const restoreStateData = (storeKey, commit) =>
{
    const data = localStore(storeKey);
    if (!data) { return; }
    for (const dataKey in data)
    {
        commit(dataKey, data[dataKey]);
    }
};

const storageManager = (store) =>
{
    restoreStateData(
        'lutherGameProgress',
        (gameId, progress) => store.commit('setProgress', {gameId, progress})
    );
    restoreStateData(
        'lutherGameDifficult',
        (gameId, difficult) => store.commit('setDifficult', {gameId, difficult})
    );

    store.subscribe((mutation, state) =>
    {
        if (mutation.type === 'setProgress')
        {
            localStore('lutherGameProgress', state.progress);
        }
        if (mutation.type === 'setDifficult')
        {
            localStore('lutherGameDifficult', state.difficult);
        }
    });
};

export default storageManager;
