<template>
    <Game id="game4" :hud-template="Hud" />
</template>

<script>
import Game from '@/components/reusable/Game';
import Hud from '@/components/views/Game4/Hud';
import navigationGuards from '../mixins/navigationGuards';

export default {
    name: 'Game4',
    components: {
        Game
    },
    mixins: [navigationGuards],
    data()
    {
        return {
            Hud
        };
    }
};
</script>
