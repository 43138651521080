<template>
    <Game id="game1" :hud-template="Hud">
        <template #error>
            Martin Luther wurde zu oft vom Blitz getroffen.<br>
            Was möchtest du tun?
        </template>
    </Game>
</template>

<script>
import Game from '@/components/reusable/Game';
import Hud from '@/components/views/Game1/Hud';
import navigationGuards from '../mixins/navigationGuards';

export default {
    name: 'Game1',
    components: {
        Game
    },
    mixins: [navigationGuards],
    data()
    {
        return {
            Hud
        };
    }
};
</script>
