<template>
    <div class="frame-size rose-transition">
        <img
            ref="rose"
            class="rose"
            :src="require('@/assets/reusable/rose-transition/blende_rose.png')"
        >
    </div>
</template>

<script>
import gsap from 'gsap';

export default {
    name: 'RoseTransition',
    mounted()
    {
        const {rose} = this.$refs;
        this._tween = gsap.timeline({paused: true, onComplete: this.onComplete})
            .set(rose, {scale: 1.8, opacity: 0, immediateRender: true})
            .set(rose, {opacity: 1})
            .call(this.onCovered)
            .to(
                rose,
                {duration: 0.5, scale: 0.9, ease: 'sine.out'}
            )
            .to(
                rose,
                {duration: 0.3, scale: 1, ease: 'sine.inOut'}
            )
            .call(this.beforeFadeAway)
            .to(
                rose,
                {duration: 0.5, opacity: 0, scale: 0.6, ease: 'sine.out'}
            );

        this.$on('show', this.show);
    },
    methods: {
        show(evt = {})
        {
            this._onCovered = evt.onCovered;
            this._onComplete = evt.onComplete;
            this._beforeFadeAway = evt.beforeFadeAway;
            gsap.ticker.lagSmoothing(50, 16);
            this._tween.play(0);
        },
        onCovered()
        {
            if (typeof this._onCovered === 'function')
            {
                this._onCovered();
            }
        },
        beforeFadeAway()
        {
            if (typeof this._beforeFadeAway === 'function')
            {
                this._beforeFadeAway();
            }
        },
        onComplete()
        {
            if (typeof this._onComplete === 'function')
            {
                this._onComplete();
            }
            // Default Values: https://greensock.com/docs/v3/GSAP/gsap.ticker
            gsap.ticker.lagSmoothing(500, 33);
        }
    }
};
</script>

<style lang="scss" src="./RoseTransition.scss" />
