<template>
    <div
        class="game frame-size"
        :class="[id]"
    >
        <transition name="fade">
            <default-button
                v-if="closeVisible"
                class="frame-button close-frame"
                @clicked="close"
            />
        </transition>
        <StageWrapper
            ref="gameWrapper"
            :render-class="renderClass"
            @rendered="loading = false"
        />
        <transition name="fade">
            <div
                v-if="skipVisible"
                class="skip-wrapper"
                @click="skip"
            >
                <default-button class="parchment-button skip-button">
                    <span class="icon skip" /> Überspringen
                </default-button>
            </div>
        </transition>
        <RoseTransition ref="roseTransition" />
        <transition name="fade">
            <RoseLoader v-if="loading" ref="roseLoader" />
        </transition>
        <error-modal
            v-model="errorModal"
            @tryAgain="tryAgain"
        >
            <slot name="error" />
        </error-modal>
    </div>
</template>

<script>
import RoseTransition from '../RoseTransition';
import RoseLoader from '../RoseLoader';

export default {
    name: 'Game',
    components: {
        RoseTransition,
        RoseLoader
    },
    props: {
        id: {
            type: String,
            required: true
        },
        hudTemplate: {
            type: Object,
            required: true
        }
    },
    data()
    {
        return {
            errorModal: false,
            skipVisible: false,
            closeVisible: true,
            loading: true
        };
    },
    computed: {
        renderClass()
        {
            return this.id.charAt(0).toUpperCase() + this.id.slice(1);
        },
        parchmentFooter()
        {
            return this.$parent.$parent.$refs.footer;
        }
    },
    mounted()
    {
        this.parchmentFooter.gameHud = this.hudTemplate;
    },
    methods:
    {
        showHud()
        {
            this.parchmentFooter.hudVisible = true;
        },
        hideHud()
        {
            this.parchmentFooter.hudVisible = false;
        },
        showRoseTransition(evt)
        {
            this.$refs.roseTransition.$emit('show', evt);
        },
        skip()
        {
            if (!this.skipVisible) { return; }
            this.skipVisible = false;
            this.$refs.gameWrapper.getRenderer().skip();
        },
        tryAgain()
        {
            this.errorModal = false;
            this.$refs.gameWrapper.getRenderer().restartGame();
        },
        close()
        {
            this.closeVisible = false;
            this.$nextTick(() => this.$router.push({name: 'world'}));
        }
    }
};
</script>

<style lang="scss" src="./Game.scss" />
