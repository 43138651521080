const {createjs} = globalThis;
const {Container, MovieClip, DisplayObject} = createjs;

createjs.MotionGuidePlugin.install();

// https://github.com/CreateJS/EaselJS/issues/1048
MovieClip.prototype.advance = function(time)
{
    const independent = MovieClip.INDEPENDENT;
    if (this.mode !== independent) { return; }

    let o = this;
    let fps = o.framerate;

    // eslint-disable-next-line no-cond-assign
    while ((o = o.parent) && fps === null)
    {
        if (o.mode === independent)
        {
            fps = o._framerate;
        }
    }
    this._framerate = fps;

    if (this.paused || this.totalFrames <= 1) { return; }

    const t = (fps !== null && fps !== -1 && time !== null) ? time / (1000 / fps) + this._t : 1;
    let frames = t || 0;
    this._t = t - frames;

    while (frames--) { this._updateTimeline(this._rawPosition + 1, false); }
};

MovieClip.prototype.stopAll = function()
{
    this.stop();
    const {children} = this;
    let i = children.length;

    while (i--)
    {
        const child = children[i];
        if (child instanceof MovieClip)
        {
            child.stopAll();
        }
    }
};

Container.prototype.setMovieClipNames = function()
{
    for (const name in this)
    {
        const attribute = this[name];
        if (name !== 'parent' &&
            (attribute instanceof Container || attribute instanceof MovieClip))
        {
            attribute.name = name;
            attribute.setMovieClipNames();
        }
    }
};

Container.prototype.destroyAllChildren = function()
{
    const {children} = this;
    let i = children.length;

    while (i--)
    {
        const child = children.pop();
        child.removeAllEventListeners();
        if (child instanceof Container)
        {
            child.destroyAllChildren();
        }
        if (child instanceof MovieClip)
        {
            child.timeline.paused = true;
        }
        child.parent = null;
    }
};

Container.prototype.getMovieClipsByName = function(name)
{
    const collection = [];
    const {children} = this;
    let i = children.length;

    while (i--)
    {
        const child = children[i];
        if (child instanceof Container || child instanceof MovieClip)
        {
            if (child.name === name)
            {
                collection.push(child);
            }
            collection.push(...child.getMovieClipsByName(name));
        }
    }
    return collection;
};

Container.prototype.getMovieClipsByClass = function(classRef)
{
    const collection = [];
    const {children} = this;
    let i = children.length;

    while (i--)
    {
        const child = children[i];
        if (child instanceof Container || child instanceof MovieClip)
        {
            if (child instanceof classRef)
            {
                collection.push(child);
            }
            collection.push(...child.getMovieClipsByClass(classRef));
        }
    }
    return collection;
};

DisplayObject.prototype.remove = function()
{
    if (this.parent)
    {
        this.parent.removeChild(this);
    }
};

export default createjs;
