import gsap from 'gsap';
import {mix, Eventable} from '@kids/lib/mixin';
import createjs from '..';

let Dot = null;

const {Math} = globalThis;
const rotationStrengthSetter = 40;
const gravity = 1183;
const flyingArea = {
    top: -1330,
    bottom: 170,
    right: 500
};

export default class FlyingBible extends mix().with(Eventable)
{
    static setDotClass(DotClass)
    {
        Dot = DotClass;
    }

    #movieClip = null;
    #curveDots = null;
    #velocity = 0;
    #rotation = 0;
    #xStart = 0;
    #yStart = 0;
    #frames = null;
    #currentFrame = 0;
    #finished = false;
    #willLandInsideCrowd = false;

    constructor(movieClip, velocity, rotation)
    {
        super();

        movieClip.visible = false;

        this.#movieClip = movieClip;
        this.#curveDots = new createjs.Container();
        movieClip.parent.addChild(this.#curveDots);
        this.#velocity = velocity;
        this.#rotation = -rotation;
        this.#xStart = movieClip.x;
        this.#yStart = movieClip.y;

        this._createDots();
        this._createFrames();
    }

    get willLandInsideCrowd()
    {
        return this.#willLandInsideCrowd;
    }

    _createFrames()
    {
        const curve = this._simulateCurve(1 / 25);
        const rotationStrength = (Math.random() * rotationStrengthSetter) -
            (rotationStrengthSetter / 2);
        let rotation = 0;
        curve.forEach((point, i) =>
        {
            if (i > 0)
            {
                rotation += rotationStrength;
            }
            point.rotation = rotation;
            this._createFrame(point, curve[i - 1]);
        });
        this.#frames = curve.filter(frame => frame.remove !== true);
    }

    _createFrame(frame, previousFrame)
    {
        const {parent} = this.#movieClip;
        const globalPoint = parent.localToGlobal(frame.x, frame.y);

        if (this.#willLandInsideCrowd)
        {
            if (previousFrame && previousFrame.remove)
            {
                frame.remove = true;
            }
            else
            {
                frame.remove = !this._checkTrigger(globalPoint, 'trigger2');
            }
        }
        else if (this._checkTrigger(globalPoint, 'trigger1'))
        {
            frame.moveInsideCrowd = true;
            this.#willLandInsideCrowd = true;
        }

        frame.globalPoint = globalPoint;
    }

    _checkTrigger(globalPoint, triggerName)
    {
        const trigger = this.#movieClip.parent[triggerName];
        trigger.alpha = 0.1;
        const point = trigger.globalToLocal(globalPoint.x, globalPoint.y);
        const hit = trigger.hitTest(point.x, point.y);
        trigger.alpha = 0;
        return hit;
    }

    _createDots()
    {
        this._simulateCurve(1 / 60).forEach(point => this._createDot(point));
    }

    _createDot(point)
    {
        const dot = new Dot();
        dot.x = point.x;
        dot.y = point.y;
        dot.alpha = 0;
        this.#curveDots.addChild(dot);
        return dot;
    }

    _simulateCurve(delta)
    {
        const curve = [{x: this.#xStart, y: this.#yStart}];

        let flyingTime = 0;
        let x = 0;
        let y = 0;

        do
        {
            flyingTime += delta;
            x = this.#xStart + (
                this.#velocity * flyingTime * Math.cos(this.#rotation)
            );
            y = this.#yStart - (
                (this.#velocity * flyingTime * Math.sin(this.#rotation)) -
                    (gravity * (flyingTime ** 2) * 0.5)
            );
            curve.push({x, y});
        }
        while (y > flyingArea.top && y < flyingArea.bottom && x < flyingArea.right);

        return curve;
    }

    start()
    {
        this.#movieClip.visible = true;
        return this;
    }

    finish(armToCatch)
    {
        this.#finished = true;
        if (!armToCatch)
        {
            gsap.to(this.#movieClip, {duration: 0.5, alpha: 0});
        }

        gsap.to(this.#curveDots, {duration: 2, alpha: 0, onComplete: () => this.destroy()});
        this.fire('finish', {catched: !!armToCatch, armToCatch, bible: this});
    }

    _showDots()
    {
        const {x} = this.#movieClip;
        this.#curveDots.children.forEach((dot) =>
        {
            if (dot.alpha > 0) { return; }
            if (dot.x <= x)
            {
                gsap.to(dot, {duration: 0.25, alpha: 1});
            }
        });
    }

    _moveInsideCrowd()
    {
        const {parent} = this.#movieClip;
        parent.setChildIndex(this.#movieClip, parent.getChildIndex(parent.menschen_1_mc));
    }

    update(readyArms)
    {
        if (!this.#finished)
        {
            const frame = this.#frames[this.#currentFrame];
            if (!frame)
            {
                this.finish();
                return;
            }
            this.#movieClip.set({x: frame.x, y: frame.y, rotation: frame.rotation});

            this._showDots();

            if (frame.moveInsideCrowd)
            {
                this._moveInsideCrowd();
            }

            const armToCatch = readyArms.find(arm => arm.canCatch(frame.x, frame.globalPoint));
            if (armToCatch)
            {
                armToCatch.addBible(this.#movieClip, frame.globalPoint);
                this.finish(armToCatch);
            }

            this.#currentFrame++;
        }
    }

    destroy()
    {
        this.#movieClip.remove();
        this.#curveDots.remove();
        this.#frames = null;
    }
}
