const {document} = globalThis;
const trackingUrl = '//www.vk-monitor.de/';
const _paq = globalThis._paq || [];

globalThis._paq = _paq;

if (process.env.NODE_ENV !== 'development')
{
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    _paq.push(['setTrackerUrl', `${trackingUrl}matomo.php`]);
    _paq.push(['setSiteId', '6']);

    const scriptNode = document.createElement('script');
    scriptNode.type = 'text/javascript';
    scriptNode.async = true;
    scriptNode.defer = true;
    scriptNode.src = `${trackingUrl}matomo.js`;

    const [firstScript] = document.getElementsByTagName('script');
    firstScript.parentNode.insertBefore(scriptNode, firstScript);
}
