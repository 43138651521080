const navigationGuards =
{
    beforeRouteEnter(to, from, next)
    {
        if (process.env.NODE_ENV === 'development')
        {
            next();
            return;
        }
        const toName = to.name;
        const fromName = from.name;

        if (fromName === null && toName !== 'start')
        {
            next({name: 'start'});
            return;
        }

        if (toName === 'start' && fromName !== null)
        {
            next(false);
            return;
        }

        next();
    }
};

export default navigationGuards;
