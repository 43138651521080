const {Promise, JSON} = globalThis;

export default class PersistableKeyValuePair
{
    #directory = null;
    #name = '';
    #value = null;
    #restoreSet = false;
    #restorePromise = null;
    #restoreResolve = null;
    #saving = false;
    #saveAgain = false;

    constructor(name)
    {
        this.#name = name;
        this.#restorePromise = new Promise((resolve) =>
        {
            this.#restoreResolve = resolve;
        });
    }

    get filename()
    {
        return `${this.#name}.json`;
    }

    setDirectory(directory)
    {
        this.#directory = directory;

        directory.getFile(
            this.filename,
            {create: true},
            (fileEntry) =>
            {
                fileEntry.file((file) =>
                {
                    const reader = new FileReader();

                    reader.onloadend = (evt) =>
                    {
                        if (evt.target.result)
                        {
                            try
                            {
                                this._resolveRestoreValue(JSON.parse(evt.target.result));
                            }
                            // eslint-disable-next-line no-empty
                            catch (e) {}
                        }
                        this._resolveRestoreValue();
                    };
                    reader.readAsText(file);
                });
            }
        );
    }

    _resolveRestoreValue(value = null)
    {
        this.#restoreResolve(value);
        this.#restoreSet = true;
    }

    get()
    {
        if (!this.#restoreSet)
        {
            return this.#restorePromise;
        }
        return Promise.resolve(this.#value);
    }

    set(value)
    {
        this.#value = value;
        this.#restorePromise.then(() => this._save());
    }

    _save()
    {
        if (this.#saving)
        {
            this.#saveAgain = true;
            return;
        }

        const value = this.#value;

        this.#saving = true;

        this.#directory.getFile(
            this.filename,
            {create: true},
            (fileEntry) =>
            {
                fileEntry.createWriter((fileWriter) =>
                {
                    fileWriter.onwriteend = () =>
                    {
                        this.#saving = false;
                        if (this.#saveAgain)
                        {
                            this.#saveAgain = false;
                            this._save();
                        }
                    };

                    fileWriter.onerror = () => this._saveErrorHandler();

                    fileWriter.write(new Blob([JSON.stringify(value)], {type: 'text/plain'}));
                });
            },
            () => this._saveErrorHandler()
        );
    }

    _saveErrorHandler()
    {
        this.#saving = false;
        this.#saveAgain = false;
    }
}
