<template>
    <article class="live-in-monastery">
        <h2>Handeln mit Gott</h2>
        <image-box
            image="content/page/fw_info2_b3.png"
            title="So stellten die Menschen sich damals die Hölle vor"
        />
        <p>
            Weil die Menschen Angst vor Gott hatten, versuchten sie mit ihm zu handeln.
        </p>
        <p>
            Im Mittelalter hatten die Menschen große Angst vor der Hölle.
            Manche Prediger der Kirche schürten diese Angst, um an das Geld der Menschen zu kommen.
            Sie verkauften sogenannte „Ablassbriefe“ und riefen: „Sobald das Geld im Kasten klingt,
            die Seele in den Himmel springt.“ Mit dem Geld sollten prächtige Kirchen gebaut werden.
            Viele Familien bestimmten ein Kind für das Klosterleben, damit es für die Familie beten
            konnte. Auch gute Taten sollten helfen, von Gott geliebt zu werden.
            Die Menschen versuchten alles, um wenigstens einen Teil ihrer Sünde vergeben
            zu bekommen. Martin hatte aber im Kloster nicht das Gefühl,
            vor Gott gerecht werden zu können. So sehr er sich auch anstrengte.
        </p>
        <p>
            Bildrechte: St. Patricks Fegefeuer aus dem Manuskript „Legenda Aurea“, 15. Jahrhundert
            © Wikipedia, gemeinfrei
        </p>
    </article>
</template>

<script>

export default {
    name: 'BehaveWithGod'
};
</script>
