import gsap from 'gsap';
import {getRandomInt} from '@kids/lib/task';
import {shuffle, clone} from '@kids/lib/transform/array';
import Bolt from './Bolt';
import Cloud from './Cloud';

const {Math} = globalThis;

const typeConfigs = {
    thunder: {
        speed: 1,
        randomY: true,
        startX: -820
    },
    foreground: {
        speed: 2,
        randomY: true,
        startX: -580
    },
    background: {
        speed: 0.25,
        randomY: false,
        startX: -600
    }
};

export default class CloudGroup
{
    #game = null;
    #movieClip = null;
    #speed = 0;
    #randomY = false;
    #startX = 0;
    #clouds = [];
    #sortedClouds = [];
    #thunderClouds = [];
    #maxThunderClouds = 2;
    #fadedOut = false;
    #skipNeighboursProbability = 0.01;

    constructor(game, movieClip, type)
    {
        this.#game = game;
        this.#movieClip = movieClip;

        const config = typeConfigs[type];
        this.#speed = config.speed;
        this.#randomY = config.randomY;
        this.#startX = config.startX;

        this.#clouds = this.#movieClip.children.map(mc => this._createCloud(mc));
        this._sortClouds();

        if (game.difficult)
        {
            this.#maxThunderClouds = 3;
            this.#skipNeighboursProbability = 0.08;
        }
    }

    _sortClouds()
    {
        this.#sortedClouds = clone(this.#clouds).sort((c1, c2) => c1.movieClip.x - c2.movieClip.x);
    }

    _createCloud(movieClip)
    {
        if (this.#randomY)
        {
            movieClip.y = getRandomInt(1, 20);
        }
        return new Cloud(movieClip, this.#speed, this.#game.difficult);
    }

    _createCloundMovieClip()
    {
        return this.#game.createInstance(`game1.wolke${getRandomInt(1, 3)}`)
            .set({x: this.#startX});
    }

    update()
    {
        this.#thunderClouds
            .filter(cloud => cloud.boltOver)
            .forEach(cloud => this._removeThunderCloud(cloud));
        this.#clouds.forEach(cloud => this._updateCloud(cloud));
    }

    _updateCloud(cloud)
    {
        cloud.update();
        if (!this.#fadedOut && cloud.removable)
        {
            const index = this.#clouds.indexOf(cloud);
            const newMovieClip = this._createCloundMovieClip();
            const newCloud = this._createCloud(newMovieClip);

            this.#movieClip.addChildAt(
                newMovieClip,
                getRandomInt(0, this.#movieClip.numChildren)
            );
            this.#movieClip.removeChild(cloud.movieClip);
            this.#clouds[index] = newCloud;
            this._removeThunderCloud(cloud);
            this._sortClouds();
        }
    }

    _removeThunderCloud(cloud)
    {
        const index = this.#thunderClouds.indexOf(cloud);
        if (index === -1) { return; }
        this.#thunderClouds.splice(index, 1);
    }

    _createBolt(cloud)
    {
        const boltMovieClip = this.#game.createInstance('game1.Game_1_Bolt');
        const cloudMovieClip = cloud.movieClip;

        boltMovieClip.set({
            x: cloudMovieClip.x, y: cloudMovieClip.y, scaleX: 0.5, scaleY: 0.5
        });
        this.#movieClip.addChildAt(boltMovieClip, this.#movieClip.getChildIndex(cloudMovieClip));
        this.#game.addBolt(new Bolt(boltMovieClip, cloud.getBoltVolume()));
    }

    _getNeighboursOf(cloud)
    {
        if (Math.random() < this.#skipNeighboursProbability) { return []; }

        const sortedClouds = this.#sortedClouds;
        const index = sortedClouds.indexOf(cloud);
        const neighbours = [sortedClouds[index + 1], sortedClouds[index - 1]];

        // größerer Abstand ist 50% wahrscheinlich
        if (Math.random() < 0.50)
        {
            neighbours.push(sortedClouds[index + 2], sortedClouds[index - 2]);
        }

        return neighbours.filter(c => !!c);
    }

    createThunderCloud()
    {
        if (this.#thunderClouds.length >= this.#maxThunderClouds)
        {
            return false;
        }

        const shuffledClouds = shuffle(clone(this.#clouds));
        const {length} = shuffledClouds;
        for (let index = 0; index < length; index++)
        {
            const cloud = shuffledClouds[index];
            if (
                cloud.ready && cloud.inThunderRange &&
                !this.#thunderClouds.includes(cloud) &&
                !this._getNeighboursOf(cloud).some(c => c.thundering)
            )
            {
                this._createThunderCloud(cloud);
                return true;
            }
        }

        return false;
    }

    forceThunderCloudAround(x)
    {
        const cloud = this.#clouds.find(
            c => x <= c.movieClip.x + 20 && x >= c.movieClip.x - 30
        );
        if (!cloud) { return false; }
        if (!this.#thunderClouds.includes(cloud))
        {
            this._createThunderCloud(cloud);
        }
        return true;
    }

    _createThunderCloud(cloud)
    {
        cloud.thunder();
        this.#thunderClouds.push(cloud);
        cloud.once('bolt', () => this._createBolt(cloud));
    }

    fadeOut()
    {
        this.#fadedOut = true;
        const timeline = gsap.timeline({onComplete: () => this.#movieClip.remove()});
        this.#clouds.forEach((cloud) =>
        {
            cloud.setReady();
            timeline.to(cloud.movieClip, {
                duration: getRandomInt(20, 50) / 10,
                alpha: 0,
                scaleX: 0.75,
                scaleY: 0.5
            }, 0);
        });
        return timeline;
    }
}
