<template>
    <div id="parchment-header" :class="{'no-ornament': title}">
        {{ title }}
    </div>
</template>

<script>
export default {
    name: 'Header',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
};
</script>
