<template>
    <div class="frame-size">
        <page :page="page" />
    </div>
</template>

<script>
import Page from '../components/views/Content/Page';
import navigationGuards from '../mixins/navigationGuards';

export default {
    name: 'Content',
    components: {
        Page
    },
    mixins: [navigationGuards],
    props: {
        page: {
            type: String,
            required: true
        }
    }
};
</script>
