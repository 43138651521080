import gsap from 'gsap';

const {Math} = globalThis;

const types = {};
const circleDegrees = 360;

export default class MagicParticle
{
    #movieClip = null;

    static setTypeClass(key, construtorClass)
    {
        types[key] = construtorClass;
    }

    constructor(type, anchorObject)
    {
        const movieClip = new types[type]();

        movieClip.x = anchorObject.x;
        movieClip.y = anchorObject.y;

        movieClip.scaleX = (Math.floor(Math.random() * 2) + 1);
        movieClip.scaleY = movieClip.scaleX;
        movieClip.rotation = Math.random() * circleDegrees;

        anchorObject.parent.addChild(movieClip);

        this.#movieClip = movieClip;
    }

    get movieClip()
    {
        return this.#movieClip;
    }

    animate()
    {
        const directionDegrees =
            Math.floor(Math.random() * circleDegrees) -
            (circleDegrees / 2) +
            (this.#movieClip.rotation + 90);
        const directionRadians = (directionDegrees / 180) * Math.PI;
        const speed = (Math.random() * 30) + 10;
        const duration = (Math.random() * 1.2) + 0.3;

        gsap.to(
            this.#movieClip,
            {
                duration,
                scaleX: 2,
                scaleY: 2,
                alpha: 0,
                x: this.#movieClip.x + (speed * Math.cos(directionRadians)),
                y: this.#movieClip.y + (speed * Math.sin(directionRadians)),
                onComplete: () => this.#movieClip.remove()
            }
        );
    }
}
