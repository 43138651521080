<template>
    <div class="game-hud game1">
        <div class="lightning" />
        <div
            v-for="(checked, index) in checkboxes"
            :key="index"
            class="checkbox"
            :class="{checked}"
        />
    </div>
</template>

<script>
export default {
    name: 'Game1Hud',
    computed: {
        checkboxes()
        {
            return this.$store.getters.getHud('game1');
        }
    }
};
</script>

<style lang="scss" src="./Hud.scss" />
