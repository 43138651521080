import gsap from 'gsap';

import audioManager from '@/service/audioManager';
import Luther from './Luther';
import Game from '../Game';
import CloudGroup from './CloudGroup';

const {Math} = globalThis;

const thunderCloudAppearancePercentage = 0.06;
const gameTime = 60000;
const hitsToLose = 5;
const thunderCloudTimeout = 1250;
const idleThunderTimeout = 7000;
const coolDownTimeout = 3000;
const campThunderTimeout = 3000;

export default class Game1 extends Game
{
    #luther = null;
    #cloudGroups = [];
    #bolts = [];
    #forceThunderCloud = true;
    #forceIdleThunderCloud = false;
    #forceCampThunderCloud = false;
    #lastLutherX = 0;
    #hitCount = 0;
    #coolingDown = false;
    #positiveFeedbackPlaying = false;
    #time = 0;
    #timeOver = false;
    #bgTween = null;
    #flashTween = null;
    #lastBoltsLength = 0;

    constructor()
    {
        super('game1');
    }

    _init()
    {
        this.audioController.playAtmo('atmo', {volume: 0.35});
        this.audioController.playAtmo('music', {volume: 0});
        super._init();
        this._mutateStore('checkLightningBoxes', 0);
    }

    _createIntro()
    {
        super._createIntro();
        this._intro.addChild(
            this._kira.set({scaleX: 0.3, scaleY: 0.3, x: 228, y: 52})
        );
    }

    _createTutorial()
    {
        super._createTutorial();
        this._tutorial.gotoAndStop(this.touchSupport ? 'mobile' : 'desktop');
        this.audioController.getAtmo('music').fade(0.1, 1);
    }

    _createGame()
    {
        super._createGame();
        const music = this.audioController.getAtmo('music');
        music.fade(0.2, 1);
        this.#luther = new Luther(this);
        this.#luther.on('stateChange', evt => this._lutherChangeHandler(evt));
        this.#lastLutherX = this.#luther.x;
        this.#cloudGroups[0] = new CloudGroup(
            this,
            this._game.cloudHolder,
            'thunder'
        );
        this.#cloudGroups[1] = new CloudGroup(
            this,
            this._game.staticCloudHolder,
            'foreground'
        );
        this.#cloudGroups[2] = new CloudGroup(
            this,
            this._game.staticBackgroundCloudHolder,
            'background'
        );
        this.#time = 0;
        this.#bgTween = gsap.timeline({paused: true})
            .to(
                this._game.himmel_tag,
                {duration: gameTime / 2000, alpha: 1, y: -275},
                gameTime / 2000
            );

        this.#flashTween = gsap.to(
            this._game.bg_FlashLight,
            {alpha: 0.35, duration: 0.1, paused: true}
        );

        this._addDisposableTween(this.#bgTween, this.#flashTween);
        this._coolDown();
    }

    _startGame()
    {
        super._startGame();
        this.#bgTween.play();
    }

    _lutherChangeHandler()
    {
        if (this.#luther.idle)
        {
            if (!this.getKeysOf('timeouts').idleThunder)
            {
                this._setIdleThunderTimeout();
            }
        }
        else if (this.#luther.running)
        {
            this.cancelTimeout('idleThunder');
        }
    }

    _setThunderCloudTimeout()
    {
        this.setTimeout(
            'thunderCloud',
            () => { this.#forceThunderCloud = true; },
            thunderCloudTimeout
        );
    }

    _setIdleThunderTimeout()
    {
        this.setTimeout(
            'idleThunder',
            () => { this.#forceIdleThunderCloud = true; },
            idleThunderTimeout
        );
    }

    _setCampThunderTimeout()
    {
        this.setTimeout(
            'campThunder',
            () => { this.#forceCampThunderCloud = true; },
            campThunderTimeout
        );
    }

    _coolDown()
    {
        this.#coolingDown = true;
        this.setTimeout(
            'coolDown',
            () =>
            {
                this.#coolingDown = false;
                this._setIdleThunderTimeout();
            },
            coolDownTimeout
        );
    }

    _update(evt)
    {
        if (this._game)
        {
            this.#cloudGroups.forEach(group => group.update());
            if (this._started)
            {
                this._timer(evt);
                this.#luther.update();
                this._testForCamping();
                this._updateThunderClouds();
                this._updateBolts();
            }
        }
    }

    _testForCamping()
    {
        if (this.#coolingDown) { return; }

        const {x} = this.#luther;
        if (this.#lastLutherX + 50 >= x && this.#lastLutherX - 50 <= x)
        {
            if (!this.getKeysOf('timeouts').campThunder)
            {
                this._setCampThunderTimeout();
            }
        }
        else
        {
            this.cancelTimeout('campThunder');
            this.#lastLutherX = x;
        }
    }

    _updateThunderClouds()
    {
        if (
            (this.#forceIdleThunderCloud || this.#forceCampThunderCloud) &&
            this.#cloudGroups[0].forceThunderCloudAround(this.#luther.x)
        )
        {
            this.#forceIdleThunderCloud = false;
            this.#forceThunderCloud = false;
            this.#forceCampThunderCloud = false;
            this._setThunderCloudTimeout();
            return;
        }

        if (this.#coolingDown) { return; }

        if (Math.random() < thunderCloudAppearancePercentage || this.#forceThunderCloud)
        {
            if (this.#cloudGroups[0].createThunderCloud())
            {
                this._setThunderCloudTimeout();
                this.#forceThunderCloud = false;
            }
        }
    }

    addBolt(bolt)
    {
        if (this._ended)
        {
            bolt.remove();
            return;
        }
        this.#bolts.push(bolt);
        this.audioController.play('thunder', {volume: bolt.volume});
    }

    _updateBolts()
    {
        this.#bolts = this.#bolts.filter(bolt => !bolt.removed);

        const luther = this.#luther;
        const bolts = this.#bolts;
        const {length} = bolts;

        if (length !== this.#lastBoltsLength)
        {
            if (length && !this.#lastBoltsLength)
            {
                this.#flashTween.play();
            }
            else if (!length && this.#lastBoltsLength)
            {
                this.#flashTween.reverse();
            }
            this.#lastBoltsLength = length;
        }

        if (length === 0) { return; }

        bolts.forEach(bolt => bolt.update());

        if (luther.hit) { return; }

        const lutherHitArea = luther.globalHitArea;

        if (bolts.some(bolt => bolt.hits(lutherHitArea)))
        {
            audioManager.clearChannel('luther');
            if (Math.random() < 0.40)
            {
                this._playNegativeFeedback();
            }
            luther.electrocute();
            this.cancelTimeout('campThunder');
            this._coolDown();
            this.#luther.once('hitOver', () => this._hitOverHandler());
        }
        else if (luther.idle && !this.#positiveFeedbackPlaying && Math.random() < 0.015)
        {
            this._playPositiveFeedback();
            this.#luther.pose();
        }
    }

    _playPositiveFeedback()
    {
        this.#positiveFeedbackPlaying = true;

        this.audioController
            .play(
                this._getRandomAudioKey('fb_pos_', 4),
                {channel: 'luther'}
            )
            .once('end', () => { this.#positiveFeedbackPlaying = false; });
    }

    _playNegativeFeedback()
    {
        this.#positiveFeedbackPlaying = false;

        this.audioController
            .play(
                this._getRandomAudioKey('fb_neg_', 3),
                {channel: 'luther'}
            );
    }

    _hitOverHandler()
    {
        this.#hitCount++;
        this._mutateStore('checkLightningBoxes', this.#hitCount);
        if (this.#hitCount >= hitsToLose)
        {
            this._lostHandler();
        }
        else if (this.#timeOver)
        {
            this._successHandler();
        }
    }

    _timer(evt)
    {
        if (this.#timeOver) { return; }

        this.#time += evt.delta;
        if (!this.#timeOver && this.#time >= gameTime)
        {
            this.#timeOver = true;
            if (this.#luther.hit) { return; }

            if (this.#hitCount < hitsToLose)
            {
                this._successHandler();
            }
            else
            {
                this._lostHandler();
            }
        }
    }

    _successHandler()
    {
        this._end();

        const atmo = this.audioController.getAtmo('atmo');
        atmo.fade(0, 2);

        const music = this.audioController.getAtmo('music');
        music.fade(0, 2);

        this.audioController
            .playAtmo('birds', {volume: 0})
            .getAtmo('birds').fade(0.3, 2);

        const timeline = gsap.timeline();
        timeline
            .to(
                [this._game.raindropsHolder, this._game.rainAnimation],
                {duration: 2, alpha: 0},
                1
            )
            .add(() =>
            {
                // Performance-Fix, damit Rose-Loader nicht so laggt.
                this._game.raindropsHolder.remove();
                this._game.rainAnimation.remove();
            })
            .add(() =>
            {
                this.#luther.pose(1);
                this.audioController.play('success', {channel: 'luther'});
            }, 1)
            .add(() => this.gotoOutro(), `+=${5 - timeline.duration()}`);

        this.#cloudGroups.forEach(group => timeline.add(group.fadeOut(), 0));
        this._addDisposableTween(timeline);

        if (this.#hitCount > 3)
        {
            this.setProgress(1);
        }
        else if (this.#hitCount > 0)
        {
            this.setProgress(2);
        }
        else
        {
            this.setProgress(3);
        }

        this.setEndScreen([this.#hitCount]);
    }

    _lostHandler()
    {
        this._end();
        const timeline = gsap.timeline();
        timeline
            .add(() =>
            {
                this.#luther.pose(1);
                this.audioController.play('lost', {channel: 'luther'});
            }, 0.3)
            .add(() =>
            {
                this._vueParent.errorModal = true;
            }, 3.5);
    }

    _end()
    {
        this._ended = true;
        this.#luther.destroy(true);
        this._started = false;
        this.cancelTimers();
        this.#bolts.forEach(bolt => bolt.remove());
        this._disposeTweens();
    }

    restartGame()
    {
        this._mutateStore('checkLightningBoxes', 0);
        this.#forceThunderCloud = true;
        this.#forceIdleThunderCloud = false;
        this.#forceCampThunderCloud = false;
        this.#lastLutherX = 0;
        this.#hitCount = 0;
        this.#coolingDown = false;
        this.#positiveFeedbackPlaying = false;
        this.#time = 0;
        this.#timeOver = false;
        this.#lastBoltsLength = 0;
        super.restartGame();
    }

    _stopGame()
    {
        if (this.#luther)
        {
            this.#luther.destroy();
            this.#luther = null;
        }
        this.#cloudGroups.length = 0;
        this.#bolts.length = 0;
    }

    _createOutro()
    {
        super._createOutro();
        this.audioController.stopAtmo('music');
        this.audioController.stopAtmo('atmo');
        this._outro.addChild(
            this._kira.set({scaleX: -0.3, scaleY: 0.3, x: -210, y: 45})
        );
    }

    _stopSection(name)
    {
        super._stopSection(name);
        audioManager.clearChannel('steps');
    }
}
