import CallbackChain from '@kids/lib/class/CallbackChain';
import {createArrayFromRange, getRandomInt} from '@kids/lib/task';
import {shuffle} from '@kids/lib/transform/array';
import gsap from 'gsap';
import Game from '../Game';
import Item from './Item';

export default class Game2 extends Game
{
    #items = [];
    #itemOrder = [];
    #itemOrderLength = 2;
    #turnsToWin = 0;
    #luther = null;
    #currentStep = 0;
    #correctCount = 0;
    #incorrectCount = 0;

    constructor()
    {
        super('game2');
    }

    _init()
    {
        super._init();
        this._mutateStore('setGame2Counts', {correct: 0, incorrect: 0});
    }

    _createIntro()
    {
        super._createIntro();

        this.audioController.playAtmo('atmo', {volume: 0.15});

        this.createLipSyncer(
            'luther',
            this._intro.getMovieClipsByClass(
                this.getMovieClipClass('game2.Martin_Head_Mouth_Sad')
            )[0]
        );

        this._intro.kiraContainer.addChildAt(
            this._kira.set({scaleX: -0.35, scaleY: 0.35, x: -160, y: -72}), 0
        );

        const introChain = new CallbackChain().add(
            () => this.playLipSyncAudio('kira', 'intro_1')
                .on('end', introChain.next),
            () => this.playLipSyncAudio('luther', 'intro_2')
                .on('end', introChain.next),
            () => this.playLipSyncAudio('kira', 'intro_3')
                .on('end', () => this.gotoTutorial())
        );
        introChain.next();
    }

    _createTutorial()
    {
        super._createTutorial();
        this._tutorial.gotoAndStop(this.touchSupport ? 'mobile' : 'desktop');
    }

    _createGame()
    {
        super._createGame();

        const game = this._game;

        this.#turnsToWin = this.difficult ? 5 : 3;
        this.#luther = game.luther;
        this.#luther.mouseChildren = false;
        this.#luther.mouseEnabled = false;
        this.#luther.addEventListener('click', () => this.#luther.gotoAndPlay('anger'));

        shuffle(createArrayFromRange(0, 8)).slice(0, 5).forEach((frame, index) =>
        {
            this.#items.push(new Item(game[`item_${index + 1}`], frame));
        });

        this.#items.forEach(item => item.on('click', evt => this._clickItem(evt)));
        this.on('changeLipSyncObject', evt => this._createLutherLipSyncer(evt.instanceName));
    }

    _createLutherLipSyncer(instanceName = 'head')
    {
        this.createLipSyncer('luther', this.#luther[instanceName].mouth);
    }

    _startGame()
    {
        super._startGame();
        this._game.bubbleAnim.gotoAndPlay('anim');
        this.once('bubbleComplete', this._fadeInItems);
        this.#luther.mouseEnabled = true;
    }

    _fadeInItems()
    {
        const delay = 0.35;
        const timeline = gsap.timeline();
        this.#items.forEach((item, index) =>
        {
            timeline.call(() => item.fadeIn(0.7), null, delay * index);
        });
        timeline.call(() => this._createItemOrder(), null, `+=${delay}`);
        this._addDisposableTween(timeline);
    }

    _enableItems(enabled = true)
    {
        this.#items.forEach((item) =>
        {
            item.enabled = enabled;
        });
    }

    _createItemOrder()
    {
        this._enableItems(false);

        const itemOrder = this.#itemOrder;
        const itemCount = this.#items.length;

        itemOrder.length = 0;

        while (itemOrder.length < this.#itemOrderLength)
        {
            const randomItemIndex = getRandomInt(0, itemCount - 1);

            // die ersten Items sollen alle unterschiedlich sein (erst ab Anzahl turnsToWin dürfen Items erneut auftreten)
            if (itemOrder.length < this.#turnsToWin)
            {
                if (!itemOrder.includes(randomItemIndex))
                {
                    itemOrder.push(randomItemIndex);
                }
                continue;
            }

            itemOrder.push(randomItemIndex);
        }

        this._showItemOrder();
    }

    _showItemOrder()
    {
        const hm = this.playLipSyncAudio('luther', 'hm');
        const timeline = gsap.timeline();
        const offset = hm.duration + 0.3;
        const delay = 1.3;

        this.#itemOrder.forEach((itemIndex, index) =>
        {
            const item = this.#items[itemIndex];
            timeline.call(() =>
            {
                this.playLipSyncAudio('luther', item.audioKey);
                item.highlight();
            }, null, offset + (delay * index));
        });

        timeline.call(() => this._enableItems(), null, '+=1');
        this._addDisposableTween(timeline);
        this.#currentStep = 0;
    }

    _clickItem(evt)
    {
        const itemOrder = this.#itemOrder;
        const {item} = evt;

        this._enableItems(false);
        this.playLipSyncAudio('luther', item.audioKey);

        if (item === this.#items[itemOrder[this.#currentStep]])
        {
            let onComplete;

            // komplette Abfolge fertig
            if (this.#currentStep === itemOrder.length - 1)
            {
                onComplete = () => this._playPositiveFeedback();
                this.#correctCount = itemOrder.length;
                this._mutateStore('setGame2Counts', {correct: this.#correctCount});
            }
            // richtiges Item gedrückt
            else
            {
                this.#currentStep++;
                this._enableItems();
            }

            item.highlight('correct', onComplete);
        }
        // falsches Item gedrückt
        else
        {
            this.#incorrectCount++;
            this._mutateStore('setGame2Counts', {incorrect: this.#incorrectCount});
            item.highlight('incorrect', () => this._playNegativeFeedback());
        }
    }

    _playPositiveFeedback()
    {
        this.#luther.mouseEnabled = false;
        this.#luther.gotoAndPlay('freude');

        this.#itemOrderLength++;
        const audio = this.playLipSyncAudio(
            'luther',
            this._getRandomAudioKey('fb_pos_', 4)
        );
        const timeline = gsap.timeline()
            .add(() => { this.#luther.mouseEnabled = true; }, audio.duration)
            .add(() => this._createItemOrder(), audio.duration + 0.6);

        this._addDisposableTween(timeline);
    }

    _playNegativeFeedback()
    {
        this.#luther.mouseEnabled = false;
        // wurde vorher die Mindestanzahl an Gegenständen gemerkt, endet das Spiel hier
        if (this.#itemOrder.length > this.#turnsToWin)
        {
            this._hideToughtBubble();
            this._evaluateProgress();

            this._skipVisible = true;

            this.#luther.gotoAndPlay('end');
            this.playLipSyncAudio('luther', 'end');
        }
        else
        {
            this.#luther.gotoAndPlay('anger');

            const audio = this.playLipSyncAudio(
                'luther',
                this._getRandomAudioKey('fb_neg_', 3)
            );
            const timeline = gsap.timeline()
                .add(() => { this.#luther.mouseEnabled = true; }, audio.duration)
                .add(() => this._showItemOrder(), audio.duration + 0.6);

            this._addDisposableTween(timeline);
        }
    }

    _hideToughtBubble()
    {
        const timeline = gsap.timeline();
        this.#items.forEach(item => timeline.add(item.fadeOut(), 0));
        timeline.to(this._game.bubbleAnim, {duration: 0.4, alpha: 0}, '-=0.3');
        this._addDisposableTween(timeline);
    }

    _evaluateProgress()
    {
        const correctCount = this.#correctCount;
        let progress = 3;

        if (!this.difficult)
        {
            if (correctCount < 4)
            {
                progress = 1;
            }
            else if (correctCount === 4)
            {
                progress = 2;
            }
        }
        else if (correctCount < 6)
        {
            progress = 1;
        }
        else if (correctCount === 6)
        {
            progress = 2;
        }

        this.setProgress(progress);
        this.setEndScreen([correctCount]);
    }

    _stopGame()
    {
        this.#items.forEach(item => item.destroy());
    }

    gotoOutro()
    {
        this._skipVisible = false;
        super.gotoOutro();
    }

    _createOutro()
    {
        super._createOutro();
        this._outro.kiraContainer.addChildAt(
            this._kira.set({scaleX: -0.35, scaleY: 0.35, x: -170, y: -72}), 0
        );
    }

    _playOutro()
    {
        this._skipVisible = true;
        this.setTimeout(
            'outro',
            () => this.playLipSyncAudio('kira', 'outro')
                .on('end', () => this.close()),
            1000
        );
    }
}
