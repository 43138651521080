<template>
    <div id="parchment">
        <div id="parchment-left" ref="left" />
        <div id="parchment-frame" ref="frame">
            <Header :title="headerTitle" />
            <main class="frame-size">
                <transition
                    :css="false"
                    mode="out-in"
                    @before-enter="beforeEnter"
                    @enter="enter"
                    @leave="leave"
                    @after-enter="afterEnter"
                    @leave-cancelled="cancel"
                    @enter-cancelled="cancel"
                >
                    <router-view />
                </transition>
            </main>
            <Footer ref="footer" />
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';
import gameConfig from '@/static/gameConfig.json';
import publicGameApi from '@/service/publicGameApi';
import Header from './Header.vue';
import Footer from './Footer.vue';
import audioManager from '../../../service/audioManager';

const animationLength = 0.7;

export default {
    name: 'Parchment',
    components: {
        Footer,
        Header
    },
    data()
    {
        return {
            paperTransition: false,
            headerTitle: ''
        };
    },
    computed: {
        gameTitle()
        {
            const {name} = this.$route;
            return name.startsWith('game') ? gameConfig[name].title : '';
        }
    },
    watch:
    {
        $route(to, from)
        {
            if (to.name.startsWith('game') || from.name.startsWith('game'))
            {
                this.paperTransition = true;
            }
            else
            {
                this.paperTransition = false;
            }
        }
    },
    created()
    {
        this.headerTitle = this.gameTitle;
        this._paperTween = null;
    },
    methods:
    {
        beforeEnter(el)
        {
            if (!this.paperTransition)
            {
                el.style.opacity = 0;
            }
        },
        enter(el, done)
        {
            if (!this.paperTransition)
            {
                gsap.to(el, {duration: 0.3, opacity: 1, onComplete: done});
                return;
            }

            const {$refs} = this;
            this.headerTitle = this.gameTitle;
            $refs.footer.hudVisible = false;
            publicGameApi.game = null;

            this._paperTween = gsap.timeline({onComplete: done})
                .to(
                    $refs.left,
                    {duration: animationLength, x: 0, ease: 'power2.out'}
                )
                .to(
                    $refs.left,
                    {duration: animationLength * 0.2, y: -16, ease: 'none'},
                    0
                )
                .to(
                    $refs.left,
                    {duration: animationLength * 0.8, y: 0, ease: 'sine.out'},
                    animationLength * 0.2
                )
                .to(
                    $refs.frame,
                    {duration: animationLength, width: '+=800', ease: 'power2.out'},
                    0
                )
                .set([$refs.frame, $refs.left], {clearProps: true});
        },
        leave(el, done)
        {
            if (!this.paperTransition)
            {
                gsap.to(el, {duration: 0.3, opacity: 0, onComplete: done});
                return;
            }

            const {$refs} = this;

            audioManager.getController('global').play('paper_open', {volume: 0.7});

            this._paperTween = gsap.timeline({onComplete: done})
                .set($refs.frame, {webkitClipPath: 'inset(0 0 0 0)', clipPath: 'inset(0 0 0 0)'})
                .to(
                    $refs.left,
                    {duration: animationLength, x: 850, ease: 'power2.out'}
                )
                .to(
                    $refs.left,
                    {duration: animationLength * 0.2, y: -16, ease: 'none'},
                    0
                )
                .to(
                    $refs.left,
                    {duration: animationLength * 0.8, y: -2, ease: 'sine.out'},
                    animationLength * 0.2
                )
                .to(
                    $refs.frame,
                    {duration: animationLength, width: '-=800', ease: 'power2.out'},
                    0
                );
        },
        cancel()
        {
            if (this._paperTween)
            {
                this._paperTween.progress(1, true);
                this._paperTween = null;
            }
        },
        afterEnter()
        {
            if (this._paperTween)
            {
                this._paperTween.kill();
                this._paperTween = null;
            }
        }
    }
};
</script>

<style lang="scss" src="./Parchment.scss" />
