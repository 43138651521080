<template>
    <div
        class="information-button"
        :class="{activated: value}"
        @click.stop="$emit('input', !value)"
    />
</template>

<script>
export default {
    name: 'InformationButton',
    props: {
        value: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style lang="scss" src="./InformationButton.scss" />

